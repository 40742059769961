import yn from "yn";
import { useEnv } from "hooks/useEnv";
import React, { FC, ReactElement } from "react";
import { Grid, Button, FormControl } from "utils/material-ui-core";
import { useIsTradeOrContract } from "hooks/useIsTradeOrContract";
import GiftRegistryButton from "component-related-product-card/GiftRegistryButton";
import useStyles from "utils/buttonStyles";
import { useCountry } from "hooks/useCountry";
import { usePageContent } from "customProviders/LocationProvider";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import { useKeycloak } from "utils/Keycloak/KeyCloak";

export interface EInStockProductCardActionsProps {
  isDisabled;
  instockItem: InstockItem;
  qty: number;
  productId: string;
  productDisplayName: string;
  salePriceLabel: string;
  handleAddToCartClick: () => void;
  extraFormFragments?: [ReactElement];
  [key: string]: any;
  handleAddToGiftRegistryClick?: () => void;
}

export const EInStockProductCardActions: FC<
  EInStockProductCardActionsProps
> = ({
  isDisabled,
  instockItem,
  salePriceLabel,
  handleAddToCartClick,
  extraFormFragments,
  monogramOrder,
  customInfo,
  atgSkuId,
  fullSkuId,
  ...rest
}) => {
  const env = useEnv();
  const isNotTradeOrContract = !useIsTradeOrContract();
  const { keycloak } = useKeycloak();
  const classes = useStyles();
  const country = useCountry();
  const { pageContent } = usePageContent();

  return (
    <Grid container justify="space-between" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddToCartClick}
            disabled={isDisabled}
            fullWidth
            id="inStockProductCardActions_addToCart-btn"
            data-testid="add-to-cart-dialog-opener"
            className={classes.containedPrimary}
          >
            {pageContent?.ADD_TO_CART}
          </Button>
        </FormControl>
      </Grid>
      {!!extraFormFragments &&
        extraFormFragments.map((form, index) => (
          <React.Fragment key={index}>{form}</React.Fragment>
        ))}
      {yn(env.FEATURE_ADD_TO_GIFT_REGISTRY) &&
        isNotTradeOrContract &&
        !checkCountryIsEu({ country }) && (
          <Grid item xs={12} sm={rest?.isCustomProduct ? 12 : 6} md={12}>
            {keycloak?.authenticated && (
              <FormControl fullWidth>
                <GiftRegistryButton
                  handleOnClick={rest?.handleAddToGiftRegistryClick}
                  disabled={isDisabled}
                  isCustomProduct={rest?.isCustomProduct}
                />
              </FormControl>
            )}
          </Grid>
        )}
    </Grid>
  );
};

EInStockProductCardActions.defaultProps = {};
export default EInStockProductCardActions;
