import RHImageV2 from "component-rh-image-v2";
import RHSpinner from "component-rh-spinner";
import { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { processEnvServer } from "hooks/useSsrHooks";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import memoize from "utils/memoize";
import Arrows from "./Arrows";
import DotButton from "./DotButton";

const ImageCarousel = props => {
  const {
    slides = [],
    imageContainerStyle = {},
    imageStyle,
    presetImage,
    loop = true,
    imageFlip = false,
    hideArrows = false, // default carousel don't hide arrows
    hideDots = false // default carousel don't hide dots
  } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: loop });
  const [slidesInView, setSlidesInView] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [autoplayIntervalId, setAutoplayIntervalId] = useState(null);
  const [slideFlippedOnce, setSlideFlippedOne] = useState(false); // State for flip control
  const [isFlippedOnceAlready, setIsFlippedOnceAlready] = useState(false);

  const scrollPrev = useCallback(
    e => {
      e.preventDefault();
      if (emblaApi) emblaApi.scrollPrev();
    },
    [emblaApi]
  );

  const scrollNext = useCallback(
    e => {
      e.preventDefault();
      if (emblaApi) emblaApi.scrollNext();
    },
    [emblaApi]
  );

  const updateSlidesInView = useCallback(emblaApi => {
    setSlidesInView(slidesInView => {
      if (slidesInView.length === emblaApi.slideNodes().length) {
        emblaApi.off("slidesInView", updateSlidesInView);
      }
      const inView = emblaApi
        .slidesInView()
        .filter((index: number) => !slidesInView.includes(index));
      return slidesInView.concat(inView);
    });
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    updateSlidesInView(emblaApi);
    emblaApi.on("slidesInView", updateSlidesInView);
    emblaApi.on("reInit", updateSlidesInView);
  }, [emblaApi, updateSlidesInView]);

  if (!slides.length) {
    return null;
  }

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const startAutoplay = useCallback(() => {
    // Don't start autoplay if flipped or API is not available
    if (!emblaApi || slideFlippedOnce) return;
    const intervalId: any = setInterval(() => {
      if (emblaApi.canScrollNext()) {
        emblaApi.scrollTo(1);
      } else {
        // Go to the first slide if looping is disabled
        emblaApi.scrollTo(0);
      }
    }, 1); // Adjust time interval as needed
    setAutoplayIntervalId(intervalId);
  }, [emblaApi, slideFlippedOnce]);

  const stopAutoplay = useCallback(() => {
    if (autoplayIntervalId !== null) {
      clearInterval(autoplayIntervalId);
      setAutoplayIntervalId(null);
      setSlideFlippedOne(false);
      setIsFlippedOnceAlready(true);
    }
  }, [autoplayIntervalId]);

  useEffect(() => {
    if (slideFlippedOnce) {
      stopAutoplay();
    }
  }, [slideFlippedOnce, stopAutoplay]);

  useEffect(() => {
    return () => stopAutoplay(); // Clean up the interval when the component unmounts
  }, [stopAutoplay]);

  return (
    <div
      className="embla overflow-hidden relative group/item group block "
      ref={emblaRef}
      style={{ touchAction: "pan-x" }}
    >
      <div className="embla__container flex">
        {slides.map((item, idx: number) => {
          return (
            <div
              key={idx}
              // Start autoplay only if not flipped
              onMouseEnter={() =>
                idx === 0 && imageFlip && !isFlippedOnceAlready && !hideDots
                  ? startAutoplay()
                  : () => {}
              }
              // Stop autoplay when mouse leaves the carousel
              onMouseLeave={() =>
                idx === 0 && imageFlip && !isFlippedOnceAlready && !hideDots
                  ? stopAutoplay()
                  : () => {}
              }
              className="embla__slide min-w-0 flex-[0_0_100%]"
            >
              {slidesInView.indexOf(idx) < 0 && !processEnvServer ? (
                <div
                  className="flex justify-center h-full relative align-center"
                  style={{
                    height: imageContainerStyle?.height || 250
                  }}
                >
                  <RHSpinner />
                </div>
              ) : (
                <RHImageV2
                  src={presetImage ? presetImage(item) : item}
                  alt={`grid-carousel-item-${idx}`}
                  className="w-auto h-full object-contain max-w-full"
                  loading="lazy"
                  imageContainerStyle={imageContainerStyle}
                  style={{
                    maxHeight: imageStyle?.maxHeight || "100%",
                    height: item ? imageStyle?.height || "auto" : 250
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      {/* Action handlers */}
      <Fragment>
        <div className="embla__controls">
          {/* Arrows */}
          {!!hideArrows && (
            <Fragment>
              <Arrows scrollPrev={scrollPrev} scrollNext={scrollNext} />
            </Fragment>
          )}
          {/* Dots */}
          {!!hideDots && (
            <Fragment>
              <div className="embla__dots w-full flex justify-center items-center">
                {scrollSnaps.length > 1 &&
                  scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => scrollTo(index)}
                    />
                  ))}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default memoize(ImageCarousel);
