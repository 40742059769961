import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import memoize from "utils/memoize";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "./EmblaCarousel";
import CarouselImg from "image-carousel-optimizer/CarouselImage";
import OptimizerDotButton from "components/ImageCarouselOptimizer/OptimizerDotButton";
import { isMobileOrTablet } from "hooks/useDeviceOrientation";

const ImageCarouselOptimizer = ({
  thumbnailImage,
  alternateImage,
  imageStyle,
  imageContainerStyle,
  isImageFlip = false
}) => {
  const [showCarousel, setShowCarousel] = useState(false);
  const ref = useRef<HTMLImageElement>(null);
  const imageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [visibleIndex, setVisibleIndex] = useState(0);
  // const [dotSelected, setDotSelected] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(1);
  const [reverseCount, setReverseCount] = useState(0);
  const [reverseIndex, setReverseIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);

  // const onLoad = () => {
  //   setShowSkeleton(false);
  // };

  useEffect(() => {
    const element = ref?.current;
    let startX = 0;
    let startY = 0;
    let distX = 0;
    let distY = 0;
    const threshold = 50;

    const handleTouchStart = e => {
      const touchObj = e.changedTouches[0];
      startX = touchObj.pageX;
      startY = touchObj.pageY;
    };

    const handleTouchMove = e => {
      e.preventDefault(); // Prevent the default scrolling behavior
    };

    const handleTouchEnd = e => {
      const touchObj = e.changedTouches[0];
      distX = touchObj.pageX - startX;
      distY = touchObj.pageY - startY;
      if (Math.abs(distX) >= threshold && Math.abs(distY) <= 100) {
        // Check if the swipe was mostly horizontal
        if (distX < 0) {
          setVisibleIndex(prevIndex => {
            const newIndex = Math.min(prevIndex + 1, alternateImage.length - 1);
            return newIndex;
          });
        } else {
          setVisibleIndex(prevIndex => {
            const newIndex = Math.max(prevIndex - 1, 0);
            return newIndex;
          });
        }
      }
    };

    const handleOnPointerOver = async () => {
      alternateImage?.length && setShowCarousel(true);
    };

    element?.addEventListener("pointerover", handleOnPointerOver);
    // Attach the touch event listeners
    element?.addEventListener("touchstart", handleTouchStart);
    element?.addEventListener("touchmove", handleTouchMove);
    element?.addEventListener("touchend", handleTouchEnd);

    return () => {
      element?.removeEventListener("pointerover", handleOnPointerOver);
      element?.removeEventListener("touchstart", handleTouchStart);
      element?.removeEventListener("touchmove", handleTouchMove);
      element?.removeEventListener("touchend", handleTouchEnd);
    };
  }, [alternateImage, currentIndex]);

  useEffect(() => {
    const observerOptions = {
      root: null, // Defaults to the viewport
      rootMargin: "0px",
      threshold: 0.5 // 50% of the image should be visible
    };

    const observerCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = imageRefs.current.indexOf(entry?.target);
          setCurrentIndex(prev => {
            setPreviousIndex(prev);
            return index + 1;
          });
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    imageRefs.current.forEach(img => {
      if (img) {
        observer.observe(img);
      }
    });

    // Cleanup observer on component unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [visibleIndex]);
  useEffect(() => {
    if (previousIndex > currentIndex - 1) {
      setReverseCount(reverseCount + 1);
    } else {
      setReverseCount(0);
      setReverseIndex(0);
    }
  }, [currentIndex]);
  useEffect(() => {
    if (reverseCount == 1) {
      setReverseIndex(previousIndex);
    }
  }, [reverseCount]);

  const alternateImagesList = useMemo(() => {
    return alternateImage?.length
      ? showCarousel
        ? alternateImage
        : [alternateImage?.at(0)]
      : [{ imageUrl: thumbnailImage }];
  }, [alternateImage, showCarousel, thumbnailImage]);

  const getVisibleDots = () => {
    const totalDots = alternateImage.length;
    if (totalDots <= 5) {
      return alternateImage.map((item, index) => index);
    }
    return Array.from({ length: alternateImage.length }, (_, i) => i);
  };
  return (
    <div
      style={{
        ...imageStyle,
        ...imageContainerStyle,
        width: imageContainerStyle?.width,
        height: imageContainerStyle?.height
      }}
      className="flex items-end justify-center relative"
    >
      <Carousel className="grid items-center w-full" ref={ref}>
        <CarouselContent
          style={{
            height: imageContainerStyle?.height,
            width: imageContainerStyle?.width
          }}
          isImageFlip={isImageFlip}
        >
          {alternateImagesList?.map((item, idx) => (
            <CarouselItem
              key={idx}
              className="flex items-end justify-center  fadeIn duration-500"
              ref={el => (imageRefs.current[idx] = el)}
            >
              <CarouselImg
                key={idx}
                src={item?.imageUrl}
                imageStyle={imageStyle}
                imageContainerStyle={imageContainerStyle}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="left-0 text-black bg-white/80 flex items-center justify-center md:visible fadeIn duration-500 md:block hidden" />
        <CarouselNext className="right-0 text-black bg-white/80 flex items-center justify-center md:visible fadeIn duration-500 md:block hidden" />
        {isMobileOrTablet && (
          <div className="flex justify-center align-center items-center md:hidden">
            <div
              className={`flex ${
                alternateImage.length <= 4 ? "justify-center" : "justify-start"
              } items-center overflow-hidden w-[50px] h-[8px] mt-[10px]`}
            >
              <div
                className={`flex transition-transform duration-300`}
                style={{
                  transform: `translateX(-${
                    previousIndex - 1 <= currentIndex - 1
                      ? currentIndex - 1 >= 4 &&
                        currentIndex - 1 < alternateImage.length - 1
                        ? (currentIndex - 4) * 10
                        : currentIndex - 1 >= alternateImage.length - 1
                        ? (currentIndex - 5) * 10
                        : 0
                      : currentIndex - 1 <= alternateImage.length - 1 &&
                        currentIndex - 1 != 0
                      ? reverseCount >= 3
                        ? (currentIndex - 2) * 10
                        : currentIndex - 1 >= alternateImage.length - 3
                        ? (reverseIndex - 5) * 10
                        : reverseIndex >= 3 && alternateImage.length > 5
                        ? (reverseIndex - 4) * 10
                        : 0
                      : currentIndex - 1 == 0
                      ? 0
                      : (currentIndex - 5) * 10
                  }px)`
                }}
              >
                {alternateImage.length > 1 &&
                  getVisibleDots().map(index => (
                    <OptimizerDotButton
                      key={index}
                      selected={index === currentIndex - 1}
                      selectedIndex={currentIndex - 1}
                      index={index}
                      totalDots={alternateImage.length}
                      reverseIndex={reverseIndex - 1}
                      isReverse={previousIndex > currentIndex}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </Carousel>
    </div>
  );
};
export default memoize(ImageCarouselOptimizer);
