import { Fade, Theme, useMediaQuery } from "@material-ui/core";
import { ClassNameMap } from "@mui/material/styles";
import RHCarousel from "component-rh-carousel";
import RHImage from "component-rh-image";
import RHLink from "component-rh-link";
import { isMobileOrTablet } from "hooks/useDeviceOrientation";
import { Hidden, processEnvServer } from "hooks/useSsrHooks";
import React, { useEffect } from "react";
import "react-magic-slider-dots/dist/magic-dots.css";
import memoize from "utils/memoize";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import { getArrowStyles } from "../helper";
import { RHRImageNotFound } from "components/RHRImageNotFound";
import { useCardImageStyles } from "../styles";
import MdScreenCardImage from "./MdScreenCardImage";
type BigScreenClassTypes = ClassNameMap<
  | "innerGridDesktopImage"
  | "rhrImageHeight"
  | "innerGridMobileImage"
  | "carousel"
>;

const { useCallback, useRef, useState } = React;

const BigScreenPGPImageCard = props => {
  const {
    showCarousel,
    normalizedImages,
    zoomedImages,
    presetImage,
    productDetails,
    bindPinch,
    setZoomedImages,
    noCarouselProps = {},
    productUrl
  } = props;

  const {
    errorOnImageURL,
    disabledShapeMainImage,
    loadingbehaviorMainImage,
    fullMainImageURL,
    fullAltImageURL,
    imageFlip,
    onErrorImageURL,
    imageUrlsWithError,
    swatchHandlerProps = {},
    imageUrl
  } = noCarouselProps;

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const [isHoveredOnce, setIsHoveredOnce] = useState(false);

  const { isSWatchClicked, setIsWatchClicked, isSwatchPresent } =
    swatchHandlerProps
      ? swatchHandlerProps
      : {
          isSWatchClicked: null,
          setIsWatchClicked: null,
          isSwatchPresent: null
        };
  const classes: BigScreenClassTypes = useCardImageStyles({
    reactSlickHeight: productDetails?.imageContainerStyle?.height || "auto"
  })();

  const parentRef = useRef<React.RefObject<HTMLElement> | null>(null);
  const currentRef = parentRef?.current as {
    flipTo?: (index: number) => void;
  };
  const isSSRToggled = isSSRToggledWithClientRender();
  const Wrapper = !isSSRToggled && mdUp ? Fade : React.Fragment;
  const wrapperProps =
    !isSSRToggled && mdUp ? { in: true, timeout: { enter: 1600 } } : {};

  const goToSecondSlide = useCallback(() => {
    if (!isHoveredOnce) {
      if (currentRef?.flipTo) {
        currentRef.flipTo(1);
      }
    }
  }, [currentRef, isHoveredOnce]);

  useEffect(() => {
    if (isSwatchPresent) {
      if (isSWatchClicked && currentRef?.flipTo) {
        setIsWatchClicked(false);
        currentRef.flipTo(0);
      }
    }
  }, [isSwatchPresent, isSWatchClicked, setIsWatchClicked, currentRef]);

  return (
    <Hidden smDown={!processEnvServer && smDown}>
      {showCarousel ? (
        <Wrapper {...wrapperProps}>
          <RHCarousel
            className={classes?.carousel}
            nextArrowStyles={getArrowStyles("right")}
            prevArrowStyles={getArrowStyles("left")}
            afterChange={() => setIsHoveredOnce(true)}
            infinite
            persistDots={isMobileOrTablet}
            visibleDots={4}
            autoplaySpeed={500}
            ref={parentRef}
            arrowSize="normal"
            arrowWidth={15}
            arrowsVisible="always"
          >
            {[...normalizedImages].map((src, index) => {
              const isZoomedImage = zoomedImages.has(src);
              const loadingbehaviorImage = isZoomedImage ? "eager" : "lazy";
              const disabledShapeImage = loadingbehaviorImage ? true : false;
              const fullImageURL = presetImage(src, isZoomedImage);
              const rawImageUrl = new URL(`http:${src}`).origin.toString();
              const rawImageUrlUrlWithParams = presetImage(
                rawImageUrl,
                "eager"
              );
              const doesImageHaveError = imageUrlsWithError.has(
                new URL(rawImageUrlUrlWithParams).origin.toString()
              );

              return (
                <div
                  key={index}
                  onMouseEnter={imageFlip ? goToSecondSlide : () => {}}
                >
                  <RHLink underline="none" to={productUrl}>
                    {errorOnImageURL && doesImageHaveError ? (
                      <RHRImageNotFound
                        imgStyle={{
                          width: "10rem",
                          height: "9rem",
                          transform: "scale(2.3)"
                        }}
                      />
                    ) : (
                      <RHImage
                        rootProps={{ style: { height: "100%" } }}
                        useCssAspectratio
                        disableInitialShapeEffect={disabledShapeImage}
                        loadingBehavior={"eager"}
                        src={fullImageURL}
                        {...bindPinch(src, zoomedImages, setZoomedImages)}
                        leInitialShapeEffect={disabledShapeMainImage}
                        altImage={fullAltImageURL}
                        imgStyle={{
                          ...productDetails?.imageStyle,
                          maxWidth: "100%",
                          height:
                            productDetails?.imageStyle?.maxHeight ?? "auto",
                          position: "static"
                        }}
                        onError={onErrorImageURL}
                        isShimmerStopped={true}
                      />
                    )}
                  </RHLink>
                </div>
              );
            })}
          </RHCarousel>
        </Wrapper>
      ) : (
        <RHLink underline="none" to={productUrl}>
          <MdScreenCardImage
            isSSRToggled={isSSRToggled}
            errorOnImageURL={errorOnImageURL}
            productDetails={productDetails}
            disabledShapeMainImage={disabledShapeMainImage}
            loadingbehaviorMainImage={loadingbehaviorMainImage}
            fullMainImageURL={fullMainImageURL}
            fullAltImageURL={fullAltImageURL}
            imageFlip={imageFlip}
            onErrorImageURL={onErrorImageURL}
            bindPinch={bindPinch}
            imageUrl={imageUrl}
            zoomedImages={zoomedImages}
            setZoomedImages={setZoomedImages}
          />
        </RHLink>
      )}
    </Hidden>
  );
};

export default memoize(BigScreenPGPImageCard);
