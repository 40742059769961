import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import Drawer from "component-drawer";
import InternationalPostalCodeDialog from "dialog-international-postal-code";
import PostalCodeDialog from "dialog-postal-code";
import { useEnv } from "hooks/useEnv";
import useRHStyles from "hooks/useRHStyles";
import React, { FC, useCallback, useEffect, useState } from "react";
import type { DialogProps, Theme } from "utils/material-ui-core";
import { DialogContent, useMediaQuery } from "utils/material-ui-core";
import memoize from "utils/memoize";
import yn from "yn";

interface InStockOnSaleDialogProps extends DialogProps {
  isPostalCodeAvailable?: boolean;
  onClose: () => void;
  handleInStockDrawer?: () => void;
  postalCodeNotRequired?: boolean;
}

export const InStockOnSaleDialog: FC<InStockOnSaleDialogProps> = ({
  open,
  onClose,
  isPostalCodeAvailable,
  handleInStockDrawer,
  children,
  postalCodeNotRequired,
  ...props
}) => {
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  const internationalFlag = yn(env.FEATURE_INTERNATIONAL);
  const rhStyles = useRHStyles({
    keys: ["inStockOnSaleDrawer"]
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPostalCodeDialog, setOpenPostalCodeDialog] = useState(false);

  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const handleOpenDrawer = useCallback(() => setOpenDrawer(true), []);
  const handleCloseDrawer = useCallback(() => setOpenDrawer(false), []);

  const handleOpenPostalCodeDialog = useCallback(
    () => setOpenPostalCodeDialog(true),
    []
  );
  const handleClosePostalCodeDialog = useCallback(
    (_, reason = "backdropClick") => {
      if (reason === "backdropClick") {
        onClose?.();
      }

      setOpenPostalCodeDialog(false);
    },
    [onClose]
  );

  useEffect(() => {
    if (open) {
      if (postalCodeNotRequired) {
        handleOpenDrawer?.();
      } else {
        if (isPostalCodeAvailable) {
          handleOpenDrawer?.();
        } else {
          handleOpenPostalCodeDialog?.();
        }
      }
    } else {
      handleCloseDrawer?.();
    }
  }, [
    open,
    handleOpenDrawer,
    handleOpenPostalCodeDialog,
    isPostalCodeAvailable,
    handleCloseDrawer,
    handleClosePostalCodeDialog
  ]);

  return (
    <>
      <DrawerComponent
        {...props}
        open={openDrawer}
        transitionDuration={mdUp ? 0 : undefined}
        anchor={mdUp ? "top" : "right"}
        classes={{ paper: mdUp ? rhStyles.inStockOnSaleDrawer : "" }}
      >
        {!mdUp && children?.[0].props.title !== undefined ? ( //checking to see if first child prop is a title component
          <div className={"!overflow-auto"}>
            {children?.[0]}
            <DialogContent>{children?.[1]}</DialogContent>
          </div>
        ) : (
          <DialogContent className={"!overflow-auto"}>{children}</DialogContent>
        )}
      </DrawerComponent>
      {openPostalCodeDialog ? (
        internationalFlag ? (
          <InternationalPostalCodeDialog
            open={openPostalCodeDialog}
            onConfirm={handleOpenDrawer}
            style={{ paddingBottom: smUp ? "0" : "60%" }}
            onClose={handleClosePostalCodeDialog}
            handleInStockDrawer={handleInStockDrawer}
          />
        ) : (
          <PostalCodeDialog
            open={openPostalCodeDialog}
            onConfirm={handleOpenDrawer}
            style={{ paddingBottom: smUp ? "0" : "60%" }}
            onClose={handleClosePostalCodeDialog}
            handleInStockDrawer={handleInStockDrawer}
          />
        )
      ) : null}
    </>
  );
};

export default memoize(InStockOnSaleDialog);
