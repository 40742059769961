import { Theme, createStyles, makeStyles } from "@material-ui/core";
import "react-magic-slider-dots/dist/magic-dots.css";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL
} from "utils/constants";

export const useCardImageStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      innerGridDesktopImage: {
        display: "block"
      },
      rhrImageHeight: {
        //height based on 35% of square height
        "& #component-rh-image": {
          ".cols-6 &": {
            minHeight: "57.4px"
          },
          ".cols-12 &": {
            minHeight: "120.75px"
          },

          [theme.breakpoints.up(BREAKPOINT_SM)]: {
            ".cols-6 &": {
              minHeight: "117.6px"
            },
            ".cols-4 &": {
              minHeight: "74.55px"
            }
          },
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            ".cols-6 &": {
              minHeight: "152.6px"
            },
            ".cols-4 &": {
              minHeight: "96.95px"
            }
          },
          [theme.breakpoints.up(BREAKPOINT_LG)]: {
            ".cols-6 &": {
              minHeight: "189px"
            },
            ".cols-4 &": {
              minHeight: "121.45px"
            }
          },

          [theme.breakpoints.up(BREAKPOINT_XL)]: {
            ".cols-6 &": {
              minHeight: "245px"
            },
            ".cols-4 &": {
              minHeight: "158.55px"
            }
          }
        }
      },

      innerGridMobileImage: {
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: "none"
        }
      },
      carousel: {
        marginBottom: -6,
        "& > .slick-list": {
          height: `${props.reactSlickHeight}`,
          "& > .slick-track": {
            position: "relative",
            top: "0",
            left: "0",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: "100%",
            "& > .slick-slide": {
              float: "left",
              height: "100%",
              minHeight: "1px",
              position: "relative",
              display: "flex !important",
              justifyContent: "center",
              alignItems: "flex-end"
            }
          }
        },
        "& .magic-dots.slick-dots": {
          bottom: -8,
          "& li": {
            margin: 0,
            width: "14px",
            height: "8px",
            "& button": {
              width: "14px",
              height: "8px",
              padding: 0,
              "&::before": {
                fontSize: "8px",
                lineHeight: "10px",
                width: "14px",
                height: "8px"
              }
            },
            "&.small": {
              "& button::before": {
                fontSize: "6px",
                lineHeight: "10px"
              }
            },
            "&.slick-active": {
              "& button::before": {
                color: theme.palette.common.black
              }
            }
          }
        },
        "& .arrow-icon": {
          color: theme.palette.common.black
        },
        "&:hover": {
          "& .arrow-container": {
            opacity: "1 !important",
            zIndex: 2
          }
        },
        "& .arrow-container": {
          opacity: "0 !important"
        }
      }
    })
  );

export const useFlipCardImageStyles = makeStyles(() => ({
  carouselContainer: {
    position: "relative",
    overflow: "hidden",
    margin: "0 auto"
  },
  carouselTrack: {
    display: "flex",
    transition: "transform 0.5s ease-in-out",
    height: "inherit"
  },
  carouselSlide: {
    width: "100%",
    flex: "0 0 100%",
    display: "flex",
    justifyContent: "center", // Center the image horizontally
    alignItems: "center", // Center the image vertically
    position: "relative"
  },
  slideImage: {
    maxWidth: "100%",
    maxHeight: "100%"
  },
  arrowContainer: {
    position: "absolute",
    top: "50%",
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transform: "translateY(-50%)",
    zIndex: 1
  },
  arrowButton: {
    color: "#fff",
    fontSize: "20px",
    border: "none",
    padding: "10px",
    cursor: "pointer",
    transition: "background 0.3s ease"
  }
}));

export const useProductCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperBox: {
      margin: "0 -16px",
      paddingLeft: "8px",
      paddingRight: "8px",

      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        margin: "0 -32px",
        paddingLeft: "16px",
        paddingRight: "16px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        margin: "0 -40px",
        paddingLeft: "20px",
        paddingRight: "20px"
      },

      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        margin: "0 -80px",
        paddingLeft: "60px",
        paddingRight: "60px"
      }
    },
    gridTile: {
      paddingLeft: "8px",
      paddingRight: "8px",
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        paddingLeft: "16px",
        paddingRight: "16px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        paddingLeft: "20px",
        paddingRight: "20px"
      },
      ".cols-12 &": {
        paddingTop: "32px",
        paddingBottom: "30px"
      },
      ".cols-6 &": {
        paddingTop: "16px",
        paddingBottom: "30px",
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          paddingTop: "32px"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          paddingTop: "40px"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          paddingTop: "50px"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          paddingTop: "60px",
          paddingBottom: "40px"
        }
      },
      ".cols-4 &": {
        paddingTop: "20px",
        paddingBottom: "30px",

        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          paddingTop: "30px"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          paddingTop: "32px"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          paddingTop: "40px"
        }
      }
    },
    productNamePriceBox: {
      ".cols-12 &": {
        marginTop: 20
      },
      ".cols-6 &": {
        marginTop: 20,
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          marginTop: 30
        }
      },
      ".cols-4 &": {
        marginTop: 20
      }
    },
    productNamePrice: {
      "& .priceSpan": {
        display: "block",
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: "inline"
        },
        ".cols-12 &": {
          display: "inline"
        }
      },

      "& .priceSeperator": {
        ".cols-12 &": {
          display: "inline"
        },
        display: "none",
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: "inline"
        }
      }
    },

    swatchesNumber: {
      flexGrow: 1
    },

    swatchesBox: {
      display: "flex",
      alignItems: "center",
      marginTop: 16
    },

    swatchColor: {
      flexShrink: 0,
      marginRight: 3,
      ".cols-12 &": {
        height: 16,
        width: 60
      },
      ".cols-6 &": {
        height: 12,
        width: 28,
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          height: 16,
          width: 60
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          width: 80,
          height: 20
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          width: 90,
          height: 20
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          width: 126,
          height: 24
        }
      },
      ".cols-4 &": {
        height: 12,
        width: 36,
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          width: 50,
          height: 16
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          width: 58,
          height: 16
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          width: 80,
          height: 20
        }
      }
    },
    ssrTitle: {
      color: " #000000 !important",
      fontSize: "2.25rem !important",
      fontFamily: "RHSans-UltraThin, Helvetica, Arial !important",
      lineHeight: "2.5833333333333335rem !important",
      letterSpacing: "-0.02em !important"
    },
    anchors: {
      marginTop: "39.56px !important",
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        marginTop: "39.75px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        marginTop: "64px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        marginTop: "80px !important"
      },
      "&:first-child": {
        marginTop: "22px !important",
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          marginTop: "14.5px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          marginTop: "40px !important"
        }
      }
    },

    reveal: {
      opacity: 0
    },

    fadeInUp: {
      animationDuration: "0s",
      animationTimingFunction: "easeIn",
      animationDelay: "0s",
      animationIterationCount: 1,
      animationDirection: "normal",
      animationFillMode: "none",
      animationPlayState: "running",
      animationName: "fadeInUp"
    }
  })
);
