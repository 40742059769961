import React, { useCallback } from "react";
import {
  makeStyles,
  createStyles,
  Typography,
  Grid,
  Button,
  Theme,
  useMediaQuery
} from "utils/material-ui-core";
import classNames from "classnames";
import useTypographyStyles from "hooks/useTypographyStyles";
import memoize from "utils/memoize";
import {
  WE_APOLOGIZE,
  PAGE_UNAVAILABLE,
  LET_US_HELP,
  BUT_WE_ARE,
  GO_TO_HOME
} from "resources/page-not-found.json";
import { useHistory, useLocation } from "react-router";
import { useLocalization } from "hooks/useLocalization";
import RHLink from "component-rh-link";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { getLocaleFromPath } from "utils/intlUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // ".embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled": { display: "none" },
    // ".embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled": { display: "none" },
    errorContainer: {
      width: "100vw",
      background: "#201f1f",

      padding: "172px 0 281px 0",
      [theme.breakpoints.down("md")]: {
        padding: "171px 0 281px 0"
      },

      [theme.breakpoints.down("md")]: {
        padding: "106px 0"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "194px 0"
      }
    },
    headerText: {
      fontFamily: "RH Serif",
      fontStyle: "normal",
      fontWeight: 100,
      fontSize: "51px",
      lineHeight: "51px",
      textAlign: "center",
      letterSpacing: "-0.04em",
      textTransform: "uppercase",
      color: "#F1F0ED",
      // "-webkit-text-stroke": "1px black",
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
    },
    button: {
      width: "311px",
      height: "48px",
      color: "#F1F0ED !important",
      textTransform: "capitalize"
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center"
      }
    }
  })
);

export const PageNotFoundError = () => {
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption"]
  });
  const prefix = useLocalization();
  const classes = useStyles();
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const { pageContent } = useFetchModel("/generic-not-found");

  const location = useLocation();
  const { locale } = getLocaleFromPath(location?.pathname) || {
    locale: "/us/en/"
  };

  const history = useHistory();

  const handleGoToHome = useCallback(() => {
    history.push(prefix);
  }, [history, prefix]);

  return (
    <>
      <Grid
        id={"component-page-not-found-error"}
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.errorContainer}
      >
        <Grid container xs={12} spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography
              className={classNames([classes.headerText])}
              align="center"
            >
              {pageContent?.WE_APOLOGIZE || WE_APOLOGIZE}
            </Typography>
            <Typography
              className={classNames([classes.headerText])}
              align="center"
            >
              {pageContent?.PAGE_UNAVAILABLE || PAGE_UNAVAILABLE}
            </Typography>
            <Typography
              className={classNames([classes.headerText])}
              align="center"
            >
              {pageContent?.BUT_WE_ARE || BUT_WE_ARE}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Grid item>
              <RHLink
                to={`/customer-experience/contact-us`}
                id="404-page-help-link-btn"
                className={classes.button}
                style={{
                  marginRight: mdUp ? "20px" : "",
                  border: "1px solid rgba(189, 189, 189, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <span
                  className={typographyStyles?.rhBaseCaption}
                  style={{ color: "#F1F0ED" }}
                >
                  {pageContent?.LET_US_HELP || LET_US_HELP}
                </span>
              </RHLink>
            </Grid>
            <Grid item>
              <Button
                id="404-go-to-homepage-link-btn"
                variant="outlined"
                color="secondary"
                className={classNames([
                  typographyStyles?.rhBaseCaption,
                  classes.button
                ])}
                style={{
                  marginTop: smDown ? "32px" : ""
                }}
                onClick={handleGoToHome}
              >
                {pageContent?.GO_TO_HOME || GO_TO_HOME}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default memoize(PageNotFoundError);
