import { formatCurrency } from "utils/currencyHelpers";
import { currencySymbolToCurrencyCodeMapper } from "@RHCommerceDev/resources/countries-config.json";

export const getPriceAmount = (priceType: string, data: any) => {
  const currencyCodeFromSymbol =
    currencySymbolToCurrencyCodeMapper[data?.currencySymbol];

  return data?.currency
    ? formatCurrency(data?.[priceType], data?.currency)
    : formatCurrency(data?.[priceType], currencyCodeFromSymbol);
};
