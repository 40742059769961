import React, { FC } from "react";
import { Grid, Typography } from "utils/material-ui-core";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHStandardSelect from "component-rh-standard-select";
import classNames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";

export interface QtyInputProps {
  onQtyChanged: (value: number) => void;
  qty: number;
  qtyAvailable: number;
  style?: any;
}

export const QtyInput: FC<QtyInputProps> = ({
  onQtyChanged,
  qty,
  qtyAvailable,
  style
}) => {
  const typographyStyles = useTypographyStyles({
    keys: ["skuList", "skuListColumn1"]
  });
  const { pageContent } = usePageContent();
  return (
    <Grid container spacing={1} style={{ alignItems: "center" }}>
      <Grid item xs={4} md={1} style={style}>
        <Typography
          className={classNames([
            typographyStyles.skuList,
            typographyStyles.skuListColumn1
          ])}
        >
          {pageContent.QTY}
        </Typography>
      </Grid>
      <Grid item xs={8} md={4}>
        <RHStandardSelect
          data-testid={"in-stock-qty-select"}
          value={qty}
          onChange={event => onQtyChanged(Number(event.target.value))}
          dropdowntype={true}
        >
          {Array.from({
            length: Number(qtyAvailable) + 1
          }).map((item, index) => (
            <option key={`qty-${index}`} value={index}>
              {index}
            </option>
          ))}
        </RHStandardSelect>
      </Grid>
    </Grid>
  );
};

export default QtyInput;
