import React, { FC, useRef, useEffect } from "react";
import {
  useTheme,
  GridListTile,
  GridListProps,
  useMediaQuery,
  Theme
} from "utils/material-ui-core";
import RHImage from "component-rh-image";
import PlayIcon from "icon-play";
import RHCarousel from "component-rh-carousel";
import { ArrowsVisible } from "component-rh-carousel/types";
import { useEnv } from "hooks/useEnv";

export interface ImageThumbnailListProps extends GridListProps {
  direction?: "row" | "column";
  images: ProductAlternateImage[];
  index: number | undefined;
  imageCarousels?: boolean;
  onChangeIndex?: (index: number) => void;
  parentBaseId?: string;
}

export const ImageThumbnailList: FC<ImageThumbnailListProps> = ({
  direction = "row",
  images,
  index,
  onChangeIndex,
  imageCarousels,
  parentBaseId,
  ...rest
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const env = useEnv();
  const baseId = `${parentBaseId}-thumbnailList`;

  const carouselProps = {
    slidesToShow:
      images?.length >=
      (!imageCarousels && mdUp ? 3 : !imageCarousels && !mdUp ? 3 : 5)
        ? !imageCarousels && mdUp
          ? 3
          : !mdUp
          ? 3
          : 5
        : images.length % 5,
    slidesToScroll: !imageCarousels && mdUp ? 3 : !mdUp ? 3 : 5,
    infinite: true,
    vertical: !imageCarousels && mdUp ? true : false,
    verticalSwiping: !imageCarousels && mdUp ? true : false,
    arrowsVisible: "always" as ArrowsVisible
  };
  useEffect(() => {
    setTimeout(() => {
      ref?.current?.scrollIntoView({
        inline: "center",
        behavior: "smooth"
      });
    });
  }, [ref]);

  return (
    <>
      <div
        id={"component-image-thumbnail-list"}
        key={"component-image-thumbnail-list"}
        data-testid={"component-image-thumbnail-list"}
        className={` ${
          !imageCarousels && mdUp
            ? "w-full"
            : images?.length === 4 && mdUp
            ? "w-[82%]"
            : images?.length === 3
            ? !imageCarousels && !mdUp
              ? "w-full"
              : "w-[60%]"
            : images?.length === 2
            ? !imageCarousels && !mdUp
              ? "w-[60%]"
              : "w-[40%]"
            : images?.length === 1
            ? "w-[30%]"
            : !mdUp
            ? "w-full"
            : "w-[98%]"
        }`}
      >
        <RHCarousel
          {...carouselProps}
          arrowsSize="normal"
          arrowZoomImage={!imageCarousels ? true : false}
          imageCarousels={imageCarousels}
        >
          {images?.map((image, i) => (
            <GridListTile
              key={`altImage-${i}`}
              onClick={() => {
                onChangeIndex?.(i);
              }}
            >
              <div>
                <RHImage
                  src={image.imageUrl}
                  imageClassName={
                    i === index
                      ? "border-solid border-[1px] border-black p-[2px]"
                      : ""
                  }
                  preset="mobileThumb"
                  imageWrapperStyles={{
                    margin:
                      !imageCarousels && mdUp
                        ? theme.spacing(0.5)
                        : theme.spacing(0.8, 0.8, 2),
                    position: "relative",
                    boxSizing: "border-box",
                    overflow: "auto",
                    overflowY: "hidden",
                    maxWidth: !imageCarousels ? "auto" : "350px",
                    outline: "none"
                  }}
                  imageCarousels={true}
                />
              </div>
              {
                <>
                  {!!image.video && (
                    <PlayIcon className="absolute left-0 right-0 top-0 bottom-0 m-auto text-white pointer-events-none !text-[40px]" />
                  )}
                </>
              }
            </GridListTile>
          ))}
        </RHCarousel>
      </div>
    </>
  );
};

ImageThumbnailList.defaultProps = {};

export default ImageThumbnailList;
