import classnames from "classnames";
import React from "react";
import { Typography } from "utils/material-ui-core";
import { getPriceAmount } from "../helpers";
import memoize from "utils/memoize";

const PriceLabelOnPDP = ({
  classes,
  data,
  rhrPdp,
  pageContent,
  salePriceLabel,
  isMemberPrice,
  isSalePrice,
  isTrade,
  isContract
}) => {
  return (
    <Typography
      className={classnames([classes.rhBaseCaption, classes.uppercaseText])}
    >
      {isMemberPrice && (
        <span className={classes.priceRangeBold}>
          / {`${getPriceAmount("memberPrice", data)} `} {pageContent?.member}
        </span>
      )}

      <span
        style={{
          textDecoration: data?.onSale ? "line-through" : "",
          color: rhrPdp ? "#575757" : "#808080"
        }}
      >
        {getPriceAmount("listPrice", data)}
        {pageContent?.regular}{" "}
      </span>

      {isSalePrice && (
        <span className={classes.priceRangeBold}>
          / {getPriceAmount("salePrice", data)}
          {salePriceLabel}{" "}
        </span>
      )}

      {isTrade && (
        <span className={classes.priceRangeBold}>
          / {getPriceAmount("tradePrice", data)}
          {pageContent?.trade}
        </span>
      )}
      {isContract && (
        <span className={classes.priceRangeBold}>
          / {getPriceAmount("contractPrice", data)} {pageContent?.contract}
        </span>
      )}
    </Typography>
  );
};

export default memoize(PriceLabelOnPDP);
