import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  createStyles,
  Theme
} from "utils/material-ui-core";
import QRCode from "react-qr-code";
import { usePageContent } from "customProviders/LocationProvider";
import { useLocation } from "react-router-dom";
import { RHRUniversalPriceRangeDisplay } from "component-rh-price-range-display";
import { Configured_PRICE } from "utils/constants";
import { convertSkuResponsePriceInfoToRange } from "component-price-range-display/utils/convertSkuResponsePriceInfoToRange";
import { convertFusionPriceToRange } from "component-price-range-display/utils/convertFusionPriceToRange";
import classNames from "classnames";
import he from "he";
import { useEnv } from "hooks/useEnv";
import { useAppId } from "hooks/useAppId";
import { useLocalization } from "hooks/useLocalization";
import useParams from "hooks/useParams";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    printableTemplate: {
      display: "none",
      "@media print": {
        size: "A4",
        margin: "2cm",
        display: "block",
        "& .embeddedServiceInvitation": {
          backgroundColor: "red",
          display: "none"
        }
      }
    },
    logo: {
      "@media print": {
        pageBreakInside: "avoid",
        height: "150px",
        width: "150px",
        float: "left"
      }
    },
    header: {
      "@media print": {
        marginBottom: "30px"
      }
    },
    title: {
      "@media print": {
        fontSize: "50px",
        textTransform: "uppercase",
        fontFamily: "RHSans",
        lineHeight: "1.43",
        marginTop: "30px"
      }
    },
    mb20: {
      "@media print": {
        marginBottom: "20px"
      }
    },
    text: {
      "@media print": {
        fontSize: "30px",
        fontFamily: "RHSans",
        lineHeight: "1.43"
      }
    },
    body: {
      "@media print": {
        fontFamily: "RHSans",
        lineHeight: "1.43",
        margin: "25mm"
      }
    },
    title2: {
      "@media print": {
        fontFamily: "RHSans",
        lineHeight: "1.43",
        fontSize: "35px",
        fontWeight: 400,
        textTransform: "uppercase",
        marginTop: "20px"
      }
    },
    optionsCss: {
      "@media print": {
        pageBreakInside: "avoid"
      }
    },
    QrText: {
      "@media print": {
        display: "flex",
        justifyContent: "flex-end",
        gap: "2rem",
        fontFamily: "RHSans",
        color: "#808080"
      }
    },
    needPageBreak: {
      "@media print": {
        pageBreakBefore: "always"
      }
    }
  })
);

const PrintablePDPTemplate = props => {
  const classes = useStyles();
  const {
    data,
    selectedHeroImageSwatchUrl,
    qty,
    fullSkuId,
    pli,
    priceConfiguration,
    swatchGroups,
    productName,
    selectedOptions
  } = props;
  const swatches = ["customSwatches", "stockedSwatches"];
  const { pageContent } = usePageContent();
  const location = useLocation();
  const prefix = useLocalization();
  const params = useParams({
    productId: "",
    sale: "",
    saleContextParam: "",
    userType: "",
    siteId: "",
    currencyCode: "",
    measureSystem: "",
    locale: "",
    pc: "",
    fullSkuId: ""
  });

  const [productLineData, setProductLineData] = useState([]);

  const [availableSwatches, setAvailableSwatches] = useState<
    { title: string; swatchImageLg: { url: string } }[]
  >([]);

  useEffect(() => {
    if (fullSkuId && fullSkuId.length > 0) {
      const swatchImage = [] as any;
      data?.swatchData?.swatchGroups?.map(item => {
        if (item?.customSwatches) {
          item?.customSwatches.map(item =>
            swatchImage.push({
              ["title"]: item?.title,
              ["swatchImageLg"]: item?.swatchImageLg
            })
          );
        }
        return;
      });
      setAvailableSwatches(swatchImage);
    }

    if (data) {
      const { productLineItem } = data;
      const productLineData = [] as any;
      const allowedTypes = ["fabric", "finish", "color"];

      productLineItem?.availableOptions?.map(item => {
        if (!allowedTypes.includes(item?.type?.toLocaleLowerCase())) {
          productLineData?.push(item);
        }
      });

      setProductLineData(productLineData);
    }
  }, []);

  const env = useEnv();
  const { isConcierge } = useAppId();

  const handleDetailDisplay = useCallback(() => {
    return (
      <Grid>
        <Typography className={classes.title}>
          {pageContent?.DETAILS}
        </Typography>
        <Typography
          className={classes.text}
          style={{
            textTransform: "initial",
            fontFamily: "RHSans",
            lineHeight: 1.43,
            fontWeight: 400,
            fontSize: "30px"
          }}
          dangerouslySetInnerHTML={{
            __html: data?.longDescription
          }}
        />
      </Grid>
    );
  }, [data]);

  const handleDimensionDisplay = useCallback(() => {
    return (
      <Grid>
        <Typography className={classes.title}>
          {pageContent?.DIMENSIONS}
        </Typography>
        {Array.isArray(data?.dimensions) &&
          data?.dimensions?.map((dimension, index) => (
            <Typography
              key={`dimension-${index}`}
              component="li"
              style={{
                textTransform: "initial",
                fontSize: "30px",
                fontFamily: "RHSans",
                lineHeight: 1.43,
                fontWeight: 400
              }}
              dangerouslySetInnerHTML={{
                __html: dimension
              }}
            />
          ))}
      </Grid>
    );
  }, [data]);

  const handleSwatchDisplay = useCallback(() => {
    return (
      <Grid>
        {swatchGroups?.map((swatchGroup, index) => {
          const productSwatches = swatches
            .reduce((acc, cur) => {
              const newElements = (swatchGroup[cur] || [])?.filter(s => {
                return !acc?.find(el => el?.swatchId === s?.swatchId);
              });
              return [...newElements, ...acc];
            }, [] as ProductSwatch[])
            ?.sort((a, b) => a?.sortPriority - b?.sortPriority);
          if (!productSwatches?.length) {
            return null;
          }

          return (
            <div
              className={classNames([
                selectedOptions?.length > 4 && index === 1
                  ? classes.needPageBreak
                  : selectedOptions?.length < 4 &&
                    swatchGroups?.length > 1 &&
                    index === 1
                  ? classes.needPageBreak
                  : {}
              ])}
            >
              <div className={classes.optionsCss}>
                <h3 className={classes.title2}>{swatchGroup?.groupMaterial}</h3>

                <div style={{ display: "flex" }}>
                  {productSwatches?.slice(0, 5)?.map((swatch, index) => {
                    return (
                      <Grid
                        item
                        xs={2}
                        style={{
                          marginRight: "2%",
                          width: "100%",
                          height: "290px",
                          opacity: `${index === 5 ? "0.3" : ""}`
                        }}
                      >
                        <img
                          src={swatch?.swatchImageLg?.url}
                          key={index}
                          style={{
                            textAlign: "justify",
                            height: "160px",
                            width: "100%"
                          }}
                        ></img>

                        {index !== 5 && (
                          <Typography
                            className={classes.text}
                            style={{
                              marginTop: "20px",
                              height: "200px",
                              width: "inherit"
                            }}
                          >
                            {swatch?.title}
                          </Typography>
                        )}
                      </Grid>
                    );
                  })}
                  {/* below grid is for images more then 6 */}
                  {productSwatches?.length > 6 && (
                    <Grid
                      item
                      xs={2}
                      style={{
                        position: "relative",
                        display: "inline-block"
                      }}
                    >
                      <img
                        src={productSwatches?.[5]?.swatchImageLg?.url}
                        alt={productSwatches?.[5]}
                        style={{ opacity: "0.3" }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          right: "0.5%",
                          bottom: "47%",
                          transform: "translate(-50%, -50%)",
                          padding: "10px",
                          fontSize: "30px",
                          display: "flex",
                          fontWeight: 300
                        }}
                      >
                        {`+ ${productSwatches?.length - 5}`}
                      </div>
                    </Grid>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Grid>
    );
  }, [swatchGroups]);

  const handleFabricSwatchDisplay = useCallback(() => {
    return (
      <Grid>
        <div style={{ display: "flex" }}>
          {availableSwatches?.slice(0, 5)?.map((swatch, index) => {
            return (
              <Grid
                item
                xs={2}
                style={{
                  marginRight: "2%",
                  width: "100%",
                  height: "360px",
                  opacity: `${index === 5 ? "0.3" : ""}`
                }}
              >
                <img
                  src={swatch?.swatchImageLg?.url}
                  key={index}
                  style={{
                    textAlign: "justify",
                    height: "160px",
                    width: "100%"
                  }}
                ></img>

                {index !== 5 && (
                  <Typography
                    className={classes.text}
                    style={{
                      marginTop: "20px",
                      height: "200px",
                      width: "inherit"
                    }}
                  >
                    {swatch?.title}
                  </Typography>
                )}
              </Grid>
            );
          })}
          {/* below grid is for images more then 6 */}
          {availableSwatches?.length > 6 && (
            <Grid
              item
              xs={2}
              style={{
                position: "relative",
                display: "inline-block"
              }}
            >
              <img
                src={availableSwatches?.[5]?.swatchImageLg?.url}
                alt={availableSwatches?.[5]?.title}
                style={{ opacity: "0.3" }}
              />
              <div
                style={{
                  position: "absolute",
                  right: "0.5%",
                  bottom: "47%",
                  transform: "translate(-50%, -50%)",
                  padding: "10px",
                  fontSize: "30px",
                  display: "flex",
                  fontWeight: 300
                }}
              >
                {`+ ${availableSwatches?.length - 5}`}
              </div>
            </Grid>
          )}
        </div>
      </Grid>
    );
  }, [availableSwatches, availableSwatches]);

  const qrLink = useMemo(() => {
    return `${
      isConcierge
        ? env?.REACT_APP_ESTORE_ATG_HOST_NAME
        : env?.REACT_APP_ATG_HOST_NAME
    }${prefix}/catalog/product/product.jsp?productId=${params.productId}${
      params.fullSkuId ? `&fullSkuId=${params.fullSkuId}` : ""
    }`;
  }, [params?.productId, params?.fullSkuId, prefix, isConcierge, env]);

  return (
    <div className={classes.printableTemplate} id="printableTemplate">
      {/* header starts here */}
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <img
            alt="RH"
            src="/img/logos/RH_Black.svg"
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.QrText}>
            <span style={{ fontSize: "40px" }}>
              {pageContent?.SCAN_TO_VIEW} <br /> {pageContent?.ON_RH}
            </span>

            <QRCode size={150} style={{ float: "right" }} value={qrLink} />
          </div>
        </Grid>
      </Grid>
      {/* header ends here */}

      {/* body starts here */}
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <Grid>
            <div
              style={{
                marginBottom: "70px"
              }}
            >
              <img
                src={selectedHeroImageSwatchUrl}
                style={{
                  top: "82px",
                  left: "32px",
                  aspectRatio: "0.8/1",
                  objectFit: "contain"
                }}
              />{" "}
            </div>
          </Grid>
          <Grid>
            <Typography
              style={{
                fontSize: "70px",
                textTransform: "uppercase",
                fontWeight: "400",
                fontFamily: "RHSans-UltraThin",
                lineHeight: 1.43
              }}
            >
              {he?.decode(productName)}
            </Typography>
          </Grid>
          <Grid>
            {pli.sku?.info?.skuPriceInfo ? (
              <RHRUniversalPriceRangeDisplay
                isSkuLevel={true}
                topLabel={Configured_PRICE}
                display="inline"
                variant="print"
                range={
                  priceConfiguration?.metaSkuPricing
                    ? convertFusionPriceToRange(
                        priceConfiguration?.metaSkuPricing,
                        priceConfiguration?.userType
                      )
                    : convertSkuResponsePriceInfoToRange?.(
                        null,
                        pli.sku?.info?.skuPriceInfo ?? {},
                        priceConfiguration?.userType
                      )
                }
                currencyLabel={pli.sku?.info?.skuPriceInfo?.currencyLabel || ""}
                currencySymbol={
                  pli.sku?.info?.skuPriceInfo?.currencySymbol || "$"
                }
              />
            ) : (
              <Typography style={{ fontSize: "25px", lineHeight: 1.43 }}>
                <span style={{ color: "#575757" }}>
                  {pageContent?.STARTING_AT}{" "}
                  {data?.priceRangeDisplay?.currencyLabel}
                  {data?.priceRangeDisplay?.currencySymbol}
                  {data?.priceRangeDisplay?.listPrices[0]}{" "}
                  {pageContent?.REGULAR} /{" "}
                </span>{" "}
                {data?.priceRangeDisplay?.currencyLabel}
                {data?.priceRangeDisplay?.currencySymbol}
                {
                  data?.priceRangeDisplay?.priceRange?.memberLowestListPrice
                }{" "}
                {pageContent?.MEMBER}
              </Typography>
            )}
          </Grid>
          <Grid style={{ marginTop: "5%" }}>
            <Typography className={classes.title}>
              {pageContent?.PRODUCT_REVIEW_HEADER}
            </Typography>
            {selectedOptions?.length
              ? selectedOptions?.map(availableOption => (
                  <Grid
                    direction="row"
                    style={{ display: "flex", marginTop: "2%" }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        className={classes.text}
                        style={{
                          fontFamily: "RHSans",
                          color: "#808080",
                          textTransform: "uppercase"
                        }}
                      >
                        {" "}
                        {availableOption?.type}
                      </Typography>
                    </Grid>

                    {availableOption?.type == "Headboard Height" ? (
                      <Grid item xs={8}>
                        <Typography className={classes.text}>
                          {" "}
                          {availableOption?.sortPriority}{" "}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={8}>
                        <Typography
                          className={classes.text}
                          style={{ textTransform: "uppercase" }}
                        >
                          {" "}
                          {availableOption?.value}{" "}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ))
              : null}
            <hr style={{ marginTop: "3%", paddingLeft: "10px !important" }} />
          </Grid>
          <Grid style={{ marginTop: "5%", display: "flex" }}>
            <Grid item xs={4}>
              <Typography
                className={classes.text}
                style={{
                  fontFamily: "RHSans",
                  color: "#808080"
                }}
              >
                {pageContent?.QTY}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.text}>{qty}</Typography>
            </Grid>
          </Grid>
          <Grid style={{ display: "flex" }}>
            <Grid item xs={4} style={{ display: "flex" }}>
              <Typography
                className={classes.text}
                style={{
                  fontFamily: "RHSans",
                  color: "#808080",
                  textTransform: "uppercase"
                }}
              >
                {pageContent?.PRICE}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.text}>
                <span style={{ color: "#808080" }}>
                  {pli?.sku?.info?.skuPriceInfo?.currencySymbol}
                  {pli?.sku?.info?.skuPriceInfo?.listPrice}{" "}
                  {pageContent?.REGULAR} /{" "}
                </span>
                {pli?.sku?.info?.skuPriceInfo?.currencySymbol}
                {pli?.sku?.info?.skuPriceInfo?.memberPrice}{" "}
                {pageContent?.MEMBER}
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ display: "flex" }}>
            <Grid item xs={4}>
              {pli?.sku?.inventory?.lineItemMessage && (
                <Typography
                  className={classes.text}
                  style={{
                    fontFamily: "RHSans",
                    color: "#808080",
                    textTransform: "uppercase"
                  }}
                >
                  {pageContent?.DELIVERY}
                </Typography>
              )}
            </Grid>
            <Grid item xs={8}>
              {pli?.sku?.inventory?.lineItemMessage && (
                <Typography
                  className={classes.text}
                  style={{ textTransform: "uppercase" }}
                >
                  {pli?.sku?.inventory?.lineItemMessage} to{" "}
                  {pli?.sku?.inventory?.postalCode}
                </Typography>
              )}
            </Grid>
          </Grid>
          {swatchGroups?.length || availableSwatches?.length ? (
            <Grid item>
              <Typography className={classes.title}>
                {/* below code needs to come from AEM */}
                {/* {pageContent?.OPTIONS}{" "} */}
                OPTIONS
              </Typography>
            </Grid>
          ) : null}
          {swatchGroups?.length ? (
            <Grid
              className={
                pli?.sku?.inventory?.lineItemMessage?.length > 74
                  ? classes.needPageBreak
                  : ""
              }
            >
              {handleSwatchDisplay()}
            </Grid>
          ) : null}
          <Grid
            className={
              selectedOptions?.length < 4
                ? ""
                : !pli?.sku?.inventory?.lineItemMessage?.length &&
                  selectedOptions?.length > 4
                ? classes.needPageBreak
                : ""
            }
          >
            {availableSwatches?.length ? (
              <Typography
                className={classes.title2}
                style={{ paddingBottom: "5px" }}
              >
                {pageContent?.FABRIC}
              </Typography>
            ) : null}
            {handleFabricSwatchDisplay()}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid>
            {productLineData?.map(availableOptions => (
              <div>
                <Typography className={classes.title2}>
                  {availableOptions?.type}
                </Typography>
                <Typography style={{ marginTop: "3%" }}>
                  {availableOptions?.options?.map(opt => (
                    <span className={classes.text}>{opt?.value} </span>
                  ))}
                </Typography>
                <hr style={{ marginTop: "3%" }} />
              </div>
            ))}
          </Grid>
          <Grid>{handleDimensionDisplay()}</Grid>
          <Grid
            className={classNames([
              data?.dimensions?.length > 48 ? classes.needPageBreak : {}
            ])}
          >
            {handleDetailDisplay()}
          </Grid>
          <Grid
            className={classNames([
              data?.dimensions?.length > 20 &&
              productLineData?.length > 1 &&
              data?.longDescription?.length > 500
                ? classes.needPageBreak
                : {}
            ])}
          >
            {Array.isArray(data.featureList) &&
              data?.featureList?.map((feature, index) => (
                <Grid key={`feature-${index}`}>
                  <Typography
                    component="li"
                    style={{
                      textTransform: "initial",
                      display: "list-item",
                      fontSize: "30px",
                      fontFamily: "RHSans",
                      lineHeight: 1.43,
                      fontWeight: 400
                    }}
                    dangerouslySetInnerHTML={{
                      __html: feature
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>

      {/* body ends here */}
    </div>
  );
};

export default React.memo(PrintablePDPTemplate);
