import React, { FC, useState, useRef, useEffect } from "react";
import { RootRef, RootRefProps } from "utils/material-ui-core";
import loadable from "@loadable/component";
const TextDialog = loadable(
  () => import(/* webpackChunkName: "TextDialog" */ "./TextDialog"),
  { fallback: <></> }
);
const PDPContentDialog = loadable(
  () => import(/* webpackChunkName: "PDPContentDialog" */ "./PDPContentDialog"),
  {
    fallback: <></>
  }
);

export interface ModalTriggerProps extends RootRefProps {}

export const ModalTrigger: FC<ModalTriggerProps> = ({ children, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [linkHref, setlinkHref] = useState<string | null>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const items = rootRef.current?.querySelectorAll?.(
      `a.modal-trigger[rel="modal"]`
    );

    const handleClick = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const href = (event.currentTarget as HTMLAnchorElement)?.getAttribute(
        "href"
      );

      if (href) {
        setOpen(true);
        setlinkHref(href);
      }
    };

    const linkItems = rootRef.current?.getElementsByClassName("modal-trigger");

    if (linkItems) {
      for (let i in linkItems) {
        if (linkItems[i].innerHTML?.includes("mattress recycling fee")) {
          linkItems[i].setAttribute(
            "href",
            "/graphql/?group=general-modals&id=mattress-recycling"
          );
          linkItems[i].addEventListener("click", handleClick);
        }
      }
    }

    items?.forEach(item => {
      item.addEventListener("click", handleClick);
    });

    return () => {
      items?.forEach(item => {
        item.removeEventListener("click", handleClick);
      });
    };
  }, [rootRef, children]);

  return (
    <>
      <RootRef {...rest} rootRef={rootRef}>
        <>{children}</>
      </RootRef>
      {!linkHref ? (
        ""
      ) : linkHref.indexOf("/graphql") === 0 ? (
        <PDPContentDialog
          href={linkHref}
          open={open}
          onClose={() => setOpen(false)}
        />
      ) : (
        <TextDialog
          href={linkHref}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

ModalTrigger.defaultProps = {};
