import { useEnv } from "hooks/useEnv";
import { useQuantityBulk } from "@RHCommerceDev/hooks/useQuantity/useQuantityBulk";
import { useMemo } from "react";
import yn from "yn";

/**
 * updates each instock item qtyAvailable for sku-aggregator/availability/status  maxQuantity.
 * if FEATURE_USE_QUANTITY_IN_STOCK is false this becomes a pass through
 * @param instockItems InstockItem[]
 * @returns InstockItem[]
 */
export function useInStockItemsWithAddedQuantity(
  instockItems: InstockItem[] = []
): InstockItem[] {
  const env = useEnv();
  const skus = useMemo<SkuInput[]>(() => {
    return instockItems.map(({ fullSkuId }) => ({
      sku: fullSkuId
    }));
  }, [instockItems]);
  const { skuStatus } = useQuantityBulk({
    skus,
    skip: !yn(env.FEATURE_USE_QUANTITY_IN_STOCK),
    maxQuantity: 40
  });
  const items = useMemo(() => {
    if (yn(env.FEATURE_USE_QUANTITY_IN_STOCK)) {
      return instockItems.map(
        (item): InstockItem => ({
          ...item,
          qtyAvailable: skuStatus?.find(s => s.sku === item.fullSkuId)
            ?.maxQuantity as number
        })
      );
    } else {
      return instockItems;
    }
  }, [env.FEATURE_USE_QUANTITY_IN_STOCK, instockItems, skuStatus]);
  return items;
}
