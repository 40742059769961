import React, { CSSProperties, FC } from "react";
import { Hidden } from "utils/material-ui-core";
import yn from "yn";
import { getGlobal } from "utils-globals/getGlobal";
import { useLocation } from "react-router-dom";
import { cssStringToObj } from "utils/cssStringToObj";

const env = getGlobal("_env");

import PromoHTML from "component-promo/SharedPromos/PromoHTML";
import Wistia from "component-promo/SharedPromos/PromoVideo/Wistia/index";
import PromoImage from "component-promo/SharedPromos/PromoImage";
import PromoImageMap from "component-promo/SharedPromos/PromoImageMap";
import PromoImageLifeStyle from "component-promo/SharedPromos/PromoImageLifeStyle";
import PromoProductGrid from "component-promo/SharedPromos/PromoProductGrid";
import PromoSlideShow from "component-promo/SharedPromos/PromoSlideShow";
import MobilePromoLayer from "component-promo/MobileLayers";
import DesktopPromoLayer from "component-promo/DesktopLayer";
// const PromoImage = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/SharedPromos/PromoImage" */ "component-promo/SharedPromos/PromoImage"
//     ),
//   { fallback: <></> }
// );
// const PromoHTML = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/SharedPromos/PromoHTML" */ "component-promo/SharedPromos/PromoHTML"
//     ),
//   { fallback: <></> }
// );
// const Wistia = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/SharedPromos/PromoVideo/Wistia/index" */ "component-promo/SharedPromos/PromoVideo/Wistia/index"
//     ),
//   { fallback: <></> }
// );
// const PromoImageMap = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/SharedPromos/PromoImageMap" */ "component-promo/SharedPromos/PromoImageMap"
//     ),
//   { fallback: <></> }
// );
// const PromoImageLifeStyle = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/SharedPromos/PromoImageLifeStyle" */ "component-promo/SharedPromos/PromoImageLifeStyle"
//     ),
//   { fallback: <></> }
// );
// const PromoProductGrid = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/SharedPromos/PromoProductGrid" */ "component-promo/SharedPromos/PromoProductGrid"
//     ),
//   { fallback: <></> }
// );
// const PromoSlideShow = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/SharedPromos/PromoSlideShow" */ "component-promo/SharedPromos/PromoSlideShow"
//     ),
//   { fallback: <></> }
// );
// const MobilePromoLayer = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/MobileLayers" */ "component-promo/MobileLayers"
//     ),
//   { fallback: <></> }
// );
// const DesktopPromoLayer = loadable(
//   () =>
//     import(
//       /* webpackChunkName: "component-promo/DesktopLayer" */ "component-promo/DesktopLayer"
//     ),
//   { fallback: <></> }
// );
export interface PromoProps {
  data: PromoDisplay;
  loadingHeight?: string | number;
  onEnd?: () => void;
  style?: React.CSSProperties;
  imageLifeStyleProps?: {
    showAssets?: boolean;
  };
  [key: string]: any;
  ariaLabelPrefix?: string;
}

export interface PromoComponentMap {
  [K: string]: FC<PromoProps>;
}

export interface SectionProps {
  style: CSSProperties;
}

export const Promo: FC<PromoProps> = props => {
  const { pathname: path } = useLocation();
  const isMyAccount = path.includes("my-account");
  const isContext = path.includes("content");
  const isGiftRegistry = path.includes("gift-registry");
  const isAboveNavPromo = props?.data?.name?.includes("Above-Nav-Promo");

  if (
    (isAboveNavPromo && (isMyAccount || isGiftRegistry || isContext)) ||
    (isAboveNavPromo && yn(env.FEATURE_NAV_PROMO))
  ) {
    return null;
  }

  const PromoComponent = (
    {
      "/sitewide/includes/promo-display/text.jsp": props.data.dynamicText
        ?.wistia
        ? Wistia
        : PromoHTML,
      css: PromoHTML, // this type doesn't use a path to a jsp, just the type tag
      "/sitewide/includes/promo-display/slide-show.jsp": PromoSlideShow,
      "/sitewide/includes/promo-display/image.jsp": PromoImage,
      "/sitewide/includes/promo-display/image-map.jsp": PromoImageMap,
      "/sitewide/includes/promo-display/image-lifestyle.jsp":
        PromoImageLifeStyle,
      "product-grid": PromoProductGrid
    } as PromoComponentMap
  )[props.data.template];

  if (!PromoComponent) {
    return null;
  }

  const text = props?.data?.dynamicText;

  return (
    <section
      style={{
        ...cssStringToObj(text?.style ?? "")
      }}
      className={"relative overflow-hidden"}
      data-promo={props.data.id}
    >
      <PromoComponent {...props} />
      {(props.data.layers || []).map((layer, index) => (
        <React.Fragment key={index}>
          <Hidden smDown>
            <DesktopPromoLayer id={props.data.id} layer={layer} index={index} />
          </Hidden>
          <Hidden mdUp>
            <MobilePromoLayer layer={layer} />
          </Hidden>
        </React.Fragment>
      ))}
    </section>
  );
};

export default Promo;
