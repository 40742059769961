import React, { FC, useState, MouseEvent } from "react";
import { Collapse } from "utils/material-ui-core";
import { RHLinkListAnchorProps } from "../RHLinkListAnchor";
import RHAddIconV2 from "@RHCommerceDev/icon-add-v2";
import RHCollapseIconV2 from "@RHCommerceDev/icon-collapse-v2";
import RHLinkListItem from "../RHLinkListItem";
import memoize from "utils/memoize";
import useRHstyles from "hooks/useRHStyles";
import classNames from "classnames";

export interface RHLinkListCollapseProps extends RHLinkListAnchorProps {
  open?: boolean;
  smallLabel?: boolean;
  borderType?: {
    top?: boolean;
    bottom?: boolean;
  };
  openCollapse?: React.ReactNode;
  closeCollapse?: React.ReactNode;
  largeCollapseIcon?: boolean;
  titleVariant?: string;
  accessibilityRole?: string;
}

export const RHLinkListCollapse: FC<RHLinkListCollapseProps> = ({
  onClick,
  children,
  title,
  style,
  smallLabel,
  openCollapse,
  closeCollapse,
  largeCollapseIcon,
  titleVariant,
  accessibilityRole,
  ...rest
}) => {
  const classes = useRHstyles({ keys: ["trippleBottomSpacer"] });
  const [open, setOpen] = useState<boolean | undefined>(rest.open);
  const handleOnClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(evt);
    setOpen(!open);
  };

  return (
    <RHLinkListItem
      borderType={rest.borderType}
      data-testid="swatch-dialog-opener"
      labelOnClick={handleOnClick}
      style={style}
      title={title}
      smallLabel={smallLabel}
      Icon={
        open ? (
          openCollapse ? (
            openCollapse
          ) : (
            <RHCollapseIconV2 />
          )
        ) : closeCollapse ? (
          closeCollapse
        ) : (
          <RHAddIconV2 />
        )
      }
      largeCollapseIcon={largeCollapseIcon}
      titleVariant={titleVariant}
      accessibilityProps={{
        role: accessibilityRole || "",
        "aria-expanded": open
      }}
    >
      <Collapse in={open}>
        <section
          data-testid="open-modal"
          className={classNames([classes.trippleBottomSpacer])}
        >
          {children}
        </section>
      </Collapse>
    </RHLinkListItem>
  );
};

RHLinkListCollapse.defaultProps = {
  open: false
};

export default memoize(RHLinkListCollapse);
