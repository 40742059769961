import { Fade } from "@material-ui/core";
import RHImage from "component-rh-image";
import { RHRImageNotFound } from "components/RHRImageNotFound";
import React from "react";
import "react-magic-slider-dots/dist/magic-dots.css";
import memoize from "utils/memoize";

const MdScreenCardImage = ({
  errorOnImageURL,
  productDetails,
  disabledShapeMainImage,
  loadingbehaviorMainImage,
  fullMainImageURL,
  onErrorImageURL,
  fullAltImageURL,
  imageFlip,
  imageUrl,
  zoomedImages,
  setZoomedImages,
  bindPinch,
  isSSRToggled
}) => {
  const imageContent = (
    <div data-cmp="cardImages">
      {errorOnImageURL ? (
        <RHRImageNotFound
          imgStyle={{
            width: "10rem",
            height: "9rem",
            transform: "scale(2.3)"
          }}
        />
      ) : (
        <RHImage
          rootProps={{ style: { height: "100%" } }}
          leInitialShapeEffect={disabledShapeMainImage}
          loadingBehavior={loadingbehaviorMainImage}
          src={fullMainImageURL}
          altImage={imageFlip ? fullAltImageURL : undefined}
          imgStyle={{
            ...productDetails?.imageStyle,
            maxWidth: "100%",
            height: "auto",
            position: "static"
          }}
          useCssAspectratio
          onError={onErrorImageURL}
          isShimmerStopped={true}
          {...bindPinch(imageUrl, zoomedImages, setZoomedImages)}
        />
      )}
    </div>
  );

  if (!isSSRToggled) {
    return (
      <Fade in={true} timeout={{ enter: 1600 }}>
        {imageContent}
      </Fade>
    );
  }
  return imageContent;
};

export default memoize(MdScreenCardImage);
