import React, { HTMLAttributes, MouseEvent } from "react";
import { Theme, Typography, useMediaQuery } from "utils/material-ui-core";
import { IN_STOCK_READY_TO_SHIP } from "utils/constants";
import useTypographyStyles from "hooks/useTypographyStyles";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { usePageContent } from "customProviders/LocationProvider";
import { useParams2 } from "hooks/useParams";
import { memoryStorage } from "utils/analytics/storage";
import { handleKeyboardPressWithEvent } from "utils/accessibility";
import classNames from "classnames";

const env = useEnv();

export interface RHLinkListLabelProps {
  onClick?: (evt: MouseEvent<HTMLAnchorElement>) => void;
  Icon?: React.ReactNode;
  smallLabel?: boolean;
  [key: string]: any;
  accessibilityProps?: HTMLAttributes<HTMLElement>;
}
export interface RHLinkListItemProps {
  borderType?: {
    top?: boolean;
    bottom?: boolean;
  };
  labelOnClick?: (evt: MouseEvent<HTMLAnchorElement>) => void;
  Icon?: React.ReactNode;
  largeCollapseIcon?: boolean;
  smallLabel?: boolean;
  labelChildren?: React.ReactNode;
  [key: string]: any;
  titleVariant?: string;
  accessibilityProps?: HTMLAttributes<HTMLElement>;
}

export const RHLinkListLabel: React.FC<RHLinkListLabelProps> = ({
  labelOnClick,
  title,
  Icon,
  smallLabel,
  largeCollapseIcon = false,
  titleVariant,
  accessibilityProps,
  children
}) => {
  const { pageContent } = usePageContent();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1"]
  });
  let devTestId = "";

  const { params } = useParams2<{ [key: string]: string }>(
    { version: "" },
    { toLowerCase: true }
  );

  const isNewPDPLayout: boolean =
    params.version === "v2" || memoryStorage.getItem("newPdpLayout");

  if (typeof title === "string" && title?.includes("SELECT FROM")) {
    devTestId = title;
  }
  return (
    <div
      className={classNames(
        `innerLabel ${!!labelOnClick && "cursor-pointer"}`,
        {
          ["py-[24px]"]: yn(env.FEATURE_PAY_BY_BANK),
          ["py-[19px]"]: !yn(env.FEATURE_PAY_BY_BANK)
        }
      )}
      onClick={labelOnClick}
      style={{
        padding: title === IN_STOCK_READY_TO_SHIP ? "10px 0" : ""
      }}
      tabIndex={labelOnClick ? 0 : -1}
      onKeyDown={labelOnClick && handleKeyboardPressWithEvent(labelOnClick)}
      {...accessibilityProps}
    >
      <div
        className={`[&>h5]:leading-[1]  flex items-center ${
          smallLabel ? "justify-start" : "justify-between"
        } pr-2 relative`}
        data-testid={devTestId}
        style={{
          justifyContent: title === IN_STOCK_READY_TO_SHIP ? "normal" : ""
        }}
      >
        <Typography
          className={titleVariant || typographyStyles.rhBaseBody1}
          style={{
            ...(isNewPDPLayout && {
              fontFamily: "RHSans-Light"
            }),
            fontWeight: title === IN_STOCK_READY_TO_SHIP ? 400 : "300",
            textTransform: "uppercase",
            color: "inherit"
          }}
          component="div"
        >
          {[IN_STOCK_READY_TO_SHIP, "In-Stock Options"].includes(title)
            ? "VIEW"
            : ""}{" "}
          {title}
        </Typography>
        {[IN_STOCK_READY_TO_SHIP, "In-Stock Options"].includes(title) ? (
          <div
            className={
              "[&>svg]:text-[11px] [&>svg]:w-[11px] [&>svg]:h-[11px] [&>svg]:inset-auto"
            }
          >
            {Icon}
          </div>
        ) : (
          <div
            className={
              largeCollapseIcon
                ? `[&>svg]:!text-[16px] [&>svg]:!w-4 [&>svg]:!h-4 absolute ${
                    smallLabel ? "relative" : "absolute"
                  } ${smallLabel ? "ml-2" : "ml-0"} right-0 leading-[0]`
                : `${
                    smallLabel
                      ? "[&>svg]:!text-[11px] [&>svg]:!w-[11px] [&>svg]:!h-[11px]"
                      : "[&>svg]:!text-[13px] [&>svg]:!w-[13px] [&>svg]:!h-[13px]"
                  } absolute ${smallLabel ? "relative" : "absolute"} ${
                    smallLabel ? "ml-2" : "ml-0"
                  } right-0 leading-[0]`
            }
          >
            {Icon}
          </div>
        )}
      </div>
      {children && <div className={"pt-[8px]"}>{children}</div>}
    </div>
  );
};

const RHLinkListItem: React.FC<RHLinkListItemProps> = ({
  onClick,
  borderType,
  labelOnClick,
  title,
  Icon,
  labelChildren,
  style,
  smallLabel,
  largeCollapseIcon,
  titleVariant,
  accessibilityProps,
  children
}) => {
  let dataTestId = "";

  if (typeof title === "string" && title?.includes("Availability,")) {
    dataTestId = title;
  }

  const childrenElement = (
    <>
      <RHLinkListLabel
        smallLabel={smallLabel}
        labelOnClick={labelOnClick}
        title={title}
        Icon={Icon}
        largeCollapseIcon={largeCollapseIcon}
        titleVariant={titleVariant}
        accessibilityProps={accessibilityProps}
      >
        {labelChildren}
      </RHLinkListLabel>
      {children}
    </>
  );

  return (
    <div
      onClick={onClick}
      data-testid={dataTestId}
      className={`  min-h-[48px] ${
        borderType?.top &&
        `!border-t-[0.5px] border-[0]  border-solid border-[#D4D2CF]`
      } ${
        borderType?.bottom &&
        `border-[0] !border-b-[0.5px] border-solid border-[#D4D2CF]`
      } ${!!onClick && "cursor-pointer"}`}
      style={{
        ...style,
        width: "100%",
        border: [IN_STOCK_READY_TO_SHIP, "In-Stock Options"].includes(title)
          ? "none"
          : ""
      }}
      tabIndex={labelOnClick ? -1 : 0}
      onKeyDown={onClick && handleKeyboardPressWithEvent(onClick)}
    >
      {childrenElement}
    </div>
  );
};

export default RHLinkListItem;
