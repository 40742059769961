import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import analyticsLoader from "analytics/loader";
import classNames from "classnames";
import { CustomProductMapper } from "component-custom-product";
import { InStockProductCardActions } from "component-in-stock-product-card-actions";
import OptionsDetailsList from "component-options-details-list";
import { getPriceUserType } from "component-product-grid";
import RHImage from "component-rh-image";
import { usePageContent } from "customProviders/LocationProvider";
import AddToCartDialog, { AddToCartDialogProps } from "dialog-add-to-cart";
import AddToGiftRegistryDialog from "dialog-add-to-gift-registry";
import { useRhUserAtomValue } from "hooks/atoms";
import { useEnv } from "hooks/useEnv";
import useState from "hooks/useState";
import useTypographyStyles from "hooks/useTypographyStyles";
import { MonogramOrderProps } from "page-monogram-dialog";
import React, { FC, useCallback } from "react";
import { IMAGE_ASPECT_RATIO } from "utils/constants";
import { Grid, Theme, Typography, useMediaQuery } from "utils/material-ui-core";
import yn from "yn";
import QtyInput from "./QtyInput";
export interface InStockProductCardProps {
  data: InstockItem;
  monogramOrder?: MonogramOrderProps;
  productId: string;
  productDisplayName: string;
  salePriceLabel: string;
  skuPrice?: any;
  isAuthenticated?: boolean;
  customInfo?: CustomProductMapper | SkuCustomizationType;
  maxOrderQty?: number;
  isRHRValue?: boolean;
  metaProduct?: boolean;
}

const InStockProductCard: FC<InStockProductCardProps> = ({
  data,
  productId,
  productDisplayName,
  salePriceLabel,
  monogramOrder,
  skuPrice,
  customInfo,
  isAuthenticated,
  maxOrderQty,
  isRHRValue,
  metaProduct
}) => {
  const env = useEnv();
  const { userType } = useRhUserAtomValue();
  const { pageContent } = usePageContent();
  const typographyStyles = useTypographyStyles({
    keys: ["skuList", "skuListColumn1", "rhBaseCaption"]
  });

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const smOnly = useMediaQuery<Theme>(theme => theme.breakpoints.only("sm"));
  const [qty, setQty] = useState<number>(1);
  const [width, setWidth] = useState<number>(0);

  const [cartDialog, setCartDialog] = useState<AddToCartDialogProps | null>(
    null
  );

  const [multiSkuCompnents, setMultiSkuComponents] = useState<
    MultiSkuComponentDetails[] | null
  >(null);

  const [confirmed, setConfirmed] = useState(false);
  const [giftRegistryDialog, setGiftRegistryDialog] = useState(false);

  const style = {
    minWidth: width > 40 ? width : "40px",
    width: width > 0 ? width : "unset"
    //marginRight: width > 0 ? 30 : "unset"
  };

  /* add to gift registry */
  const handleAddToGiftRegistryClick = useCallback(() => {
    setGiftRegistryDialog(true);
  }, []);

  /* add to cart */
  const handleAddToCartClick = useCallback(() => {
    if (metaProduct) {
      const payload = data?.components?.map(component => {
        const productName = data?.bundleComponents?.find(
          prod => prod?.productId === component.productId
        );
        return {
          productId: component?.productId!,
          fullSkuId: component?.fullSkuId!,
          atgSkuId: component?.fullSkuId!,
          productDisplayName: productName,
          quantity: component?.quantity
        };
      });
      if (payload) {
        setMultiSkuComponents(payload);
      }
    } else {
      setCartDialog({
        productId: productId,
        fullSkuId: data.fullSkuId,
        atgSkuId: data.skuId,
        productDisplayName: productDisplayName,
        qty
      });
    }
  }, [productId, data, productDisplayName, qty]);

  const isRHRImage = yn(env?.FEATURE_RHR_IMAGE) && isRHRValue;

  return (
    <div
      id={"component-in-stock-product-card"}
      style={{
        overflow: "hidden"
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <RHImage
            src={data?.imageUrl}
            width="100%"
            alt={data.fullSkuId}
            preset={smOnly ? "pdp-hero-lg" : "pdp-hero-xs"}
            aspectRatio={
              IMAGE_ASPECT_RATIO[smDown ? "smallViewThumbnail" : "thumbnail"]
            }
            objectFit="contain"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OptionsDetailsList
                itemId={data.fullSkuId}
                options={data.optionList}
                onWidthChange={setWidth}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid
                container
                spacing={1}
                id="sku-price-list"
                alignItems="baseline"
              >
                {data?.skuPriceInfo?.listPrice !== 0 && (
                  <>
                    <Grid
                      item
                      xs={4}
                      sm={1}
                      md={1}
                      style={!smDown ? style : {}}
                    >
                      <Typography
                        className={classNames([
                          typographyStyles.skuList,
                          typographyStyles.skuListColumn1
                        ])}
                      >
                        {pageContent?.PRICE}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <RHRPriceDisplay
                        listPrice={data?.skuPriceInfo?.listPrice!}
                        memberPrice={
                          getPriceUserType(userType!, data?.skuPriceInfo)!
                        }
                        topLabel={""}
                        onSale={data?.skuPriceInfo?.onSale!}
                        skulowestMemberPrice={
                          getPriceUserType(userType!, data?.skuPriceInfo)!
                        }
                        showSaleMessage={false}
                        userType={userType!}
                        pageContent={pageContent}
                        wrapPrices={false}
                        variant={"small"}
                        centerAlignFlag={Boolean(false)}
                        hideCss={true}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <QtyInput
                data-testid={"qty-dropdown-in-stock-product-card"}
                onQtyChanged={setQty}
                qty={qty}
                qtyAvailable={
                  data.inventoryOnHand ||
                  (data.qtyAvailable <= 40
                    ? data.qtyAvailable || 1
                    : maxOrderQty || 1)
                }
                style={!smDown ? style : undefined}
              />
            </Grid>
            {data.inventoryOnHand ? (
              <Grid item xs={12}>
                <Typography>
                  AVAILABILITY & DELIVERY: {data.inventoryOnHand} LEFT
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {data.inventoryMessage && (
                <Typography
                  className={typographyStyles.rhBaseCaption}
                  component="p"
                  style={{
                    textTransform: "initial"
                  }}
                >
                  {data.inventoryMessage}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <InStockProductCardActions
                instockItem={data}
                productId={productId}
                productDisplayName={productDisplayName}
                salePriceLabel={salePriceLabel}
                handleAddToCartClick={handleAddToCartClick}
                isDisabled={!qty}
                qty={qty}
                handleAddToGiftRegistryClick={handleAddToGiftRegistryClick}
                multiSkuCompnents={multiSkuCompnents}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {(!!cartDialog || multiSkuCompnents?.length) && (
        <AddToCartDialog
          open={!!cartDialog || multiSkuCompnents?.length}
          onClose={() => {
            setCartDialog(null);
            setConfirmed(false);
            setMultiSkuComponents(null);
          }}
          {...(cartDialog as any)}
          multiSkuComponents={multiSkuCompnents}
          fullSkuId={data?.fullSkuId ?? ""}
          productId={productId}
          qty={qty}
          salePriceLabel={salePriceLabel}
          productDisplayName={productDisplayName}
          onCompleted={cartDetails => {
            const pricing = data?.skuPriceInfo;
            analyticsLoader(a =>
              a.emitAnalyticsEvent(
                document.querySelector<HTMLInputElement>("#spa-root > *")!,
                a.EVENTS.GA4_ADD_TO_CART.INT_TYPE,
                {
                  cartdetails: {
                    cartId: cartDetails?.id,
                    cartCreationDate: cartDetails?.createdAt,
                    cartUpdationDate: cartDetails?.lastModifiedAt
                  },
                  item: {
                    name: productDisplayName,
                    id: productId,
                    quantity: qty,
                    sku: data.skuId,
                    pricing: (pricing?.listPrice || 0) * qty,
                    inStockProductCard: true,
                    currencyCode: cartDetails?.cartPrice?.currencyCode,
                    color: data?.optionList?.find(
                      opt => opt?.optionType === "Color"
                    )?.label
                  },
                  store_number: "Website",
                  item_list_name: "InStockProductCatd"
                }
              )
            );
          }}
          setConfirmed={setConfirmed}
          confirmed={confirmed}
        />
      )}
      {giftRegistryDialog && (
        <AddToGiftRegistryDialog
          open={giftRegistryDialog}
          onClose={() => setGiftRegistryDialog(false)}
          productId={productId}
          fullSkuId={data?.fullSkuId ?? ""}
          atgSkuId={data?.skuId ?? ""}
          qty={qty}
          monogramOrder={monogramOrder}
          customInfo={customInfo}
        />
      )}
    </div>
  );
};
InStockProductCard.defaultProps = {};

export default InStockProductCard;
