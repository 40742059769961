import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import memoize from "utils/memoize";
import useStyles from "./useStyles";
import Inventory from "component-sku/Inventory";
import { RHUK_HOST } from "utils/constants";
import useSite from "hooks/useSite";
import { usePageContent } from "customProviders/LocationProvider";
import RHLink from "component-rh-link";

export interface ProductNamePriceProps {
  productData: Product;
  productPriceUi: JSX.Element;
  onPostalCode: () => void;
  lineItem: LineItemType;
  isNewPDPLayout?: boolean;
  parentBaseId?: string;
}

export const ProductNamePrice: FC<ProductNamePriceProps> = memoize(props => {
  const {
    productData,
    productPriceUi,
    onPostalCode,
    lineItem,
    isNewPDPLayout,
    parentBaseId
  } = props;
  const baseId = `${parentBaseId}-productNamePrice`;
  const classes = useStyles({});
  const siteId = useSite();

  const inventoryEta =
    siteId === RHUK_HOST
      ? lineItem?.sku?.fulfillmentEta
      : lineItem?.sku?.fulfillmentEta || lineItem?.sku?.inventory;
  const shouldShow = !!productData?.isActive;
  const { pageContent } = usePageContent();

  return (
    <Grid
      item
      xs={12}
      className={classes.productNameContainer}
      data-testid="product-name-container"
    >
      <Grid item xs={12} data-testid="product-title-grid">
        <Typography id="product_title" data-testid="product_title">
          <span
            dangerouslySetInnerHTML={{
              __html: productData?.displayName
            }}
          />
        </Typography>
      </Grid>
      {shouldShow ? (
        <>
          <Grid
            item
            xs={12}
            data-testid="product-price-container"
            className={classes.fullWidth}
          >
            <Typography
              className={classes.productPrice}
              data-testid="product-price"
            >
              {/* add the configured price component here */}
              {productPriceUi}
            </Typography>
          </Grid>
          <Grid item xs={12} id="inventory-dates" className={classes.fullWidth}>
            <Inventory
              inStock={productData?.instock?.hasInStock}
              skuInventoryMessage={
                lineItem?.sku?.fulfillmentEta?.lineItemMessage
              }
              inventory={inventoryEta!}
              delivery={lineItem?.sku?.delivery}
              onPostalCode={onPostalCode}
              showSPOmsg={true}
              spo={lineItem?.sku?.restrictions?.spo!}
              isNewPDPLayout={isNewPDPLayout}
            />
          </Grid>
        </>
      ) : (
        <Grid
          container
          item
          xs={12}
          style={{ background: "rgb(241,241,241)" }}
          data-testid="product-not-available"
        >
          <div>
            <Typography>
              {pageContent?.PRODUCT_NOT_AVAILABLE}
              {/* //product no longer available */}
            </Typography>

            <RHLink style={{ display: "inline" }} href="/">
              {pageContent?.PLEASE_TEXT} {pageContent?.CONTINUE_BROWSING}
              {/* continue browsing */}
            </RHLink>
          </div>
        </Grid>
      )}
    </Grid>
  );
});
