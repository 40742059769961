import React, { FC } from "react";
import {
  Theme,
  makeStyles,
  createStyles,
  Typography
} from "utils/material-ui-core";
import useTypographyStyles from "hooks/useTypographyStyles";
import classnames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 50,
      backgroundColor: "#F1F1F1",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(-6),
        zIndex: "1101",
        position: "relative"
      }
    },
    text: {
      margin: 0
    }
  })
);

interface BelowNavPromoProps {}

const BelowNavPromo: FC<BelowNavPromoProps> = ({}) => {
  const classes = useStyles(),
    typographyClasses = useTypographyStyles({
      keys: ["rhBaseH4"]
    });
  const { pageContent } = usePageContent();

  return (
    <div
      className={classNames([
        classes.container,
        "container grid items-center justify-center"
      ])}
    >
      <Typography
        className={classnames([typographyClasses.rhBaseH4, classes.text])}
      >
        {pageContent?.FREE_SHIPPING_ON_ALL_TEXTILES}
      </Typography>
    </div>
  );
};

export default BelowNavPromo;
