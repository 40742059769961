import React from "react";
import memoize from "utils/memoize";

type DotButtonPropType = {
  selected: boolean;
  onClick: () => void;
};

const DotButton: React.FC<DotButtonPropType> = ({ selected, onClick }) => {
  return (
    <button
      className={`embla__dot${
        selected ? " embla__dot--selected" : ""
      } !border-none bg-transparent !bg-none !p-[1.5px]`}
      type="button"
      onClick={onClick}
    >
      <svg
        width="7"
        height="7"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="6"
          cy="6"
          r="5"
          fill={selected ? "#201F1F" : "#ADACAA"} // Active is dark, inactive is light
          stroke="#898886"
          strokeWidth="0.5"
        />
      </svg>
    </button>
  );
};

export default memoize(DotButton);
