import React, { FC, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { useCookies } from "hooks/useCookies";
import Cookies from "universal-cookie";
import { useIsoCookies } from "hooks/useIsoCookies";
import { IconButton, Collapse } from "utils/material-ui-core";
import RHCloseIcon from "icon-close";
import { queryAboveNavPromo } from "graphql-client/queries/above-nav-promo";
import { parseUserContextCookie } from "utils/parseUserContextCookie";
import memoize from "utils/memoize";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import useSite from "hooks/useSite";
import { useCountry } from "hooks/useCountry";
import useIsHomePage from "hooks/useIsHomePage";
import { processEnvServer } from "hooks/useSsrHooks";

const PROMO_COOKIE_NAME = "PROMO";

export interface AboveNavPromoProps {}

export const AboveNavPromo: FC<AboveNavPromoProps> = memoize(() => {
  const [cookies] = useCookies([PROMO_COOKIE_NAME]);
  const [open, setOpen] = useState(true);
  const siteId = useSite();
  const cookiesForCurrencyCode = new Cookies();
  const cookie = cookiesForCurrencyCode.get("userContext");
  const usrContextCookie = parseUserContextCookie(cookie);
  const country = useCountry();
  const isHomePage = useIsHomePage();
  const deviceType =
    useIsoCookies(["PF_EXP"])?.PF_EXP?.toLowerCase() || "desktop";
  const { data: { aboveNavPromo } = {} as Query } = useQuery<Query>(
    queryAboveNavPromo,
    {
      variables: {
        siteId,
        deviceType: deviceType,
        currencyCode: usrContextCookie?.currencyCode,
        countryCode: country
      }
    }
  );
  const { [PROMO_COOKIE_NAME]: PROMO = [] } = cookies;
  const closeBanner = useCallback(() => {
    if (processEnvServer) {
      return 0;
    }
    const aboveNavBannerElem = document.querySelector(
      "#aboveNavBanner"
    ) as HTMLElement | null;
    aboveNavBannerElem?.setAttribute("style", "display: none;");
    const mainElem = document.querySelector("#main") as HTMLElement | null;
    const appNavigationBar = document.querySelector(
      "#app-navigation-bar"
    ) as HTMLElement | null;
    if (mainElem) {
      mainElem.style.paddingTop = "0";
    }
    if (isHomePage && appNavigationBar) {
      appNavigationBar.style.top = "0";
    }
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    closeBanner?.();
  }, [closeBanner]);

  const { setCookieWrapper } = useCookiesWithPermission();

  const handleCleanUp = useCallback(() => {
    setCookieWrapper(PROMO_COOKIE_NAME, PROMO);
  }, [setCookieWrapper, PROMO]);

  if (!aboveNavPromo || !aboveNavPromo?.text) {
    closeBanner?.();
    return null;
  }

  return (
    <Collapse
      in={open}
      data-banner="above-nav"
      onExited={handleCleanUp}
      style={{
        zIndex: 2,
        position: "relative"
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: aboveNavPromo?.text || "" }} />
      <IconButton
        data-above-nav-close="close-nav-button"
        onClick={handleClose}
        style={{
          color: "white",
          fontSize: 16,
          position: "absolute",
          right: 7,
          top: 5
        }}
      >
        <RHCloseIcon />
      </IconButton>
    </Collapse>
  );
});

AboveNavPromo.defaultProps = {};

export default AboveNavPromo;
