import React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  useMediaQuery,
  Theme
} from "utils/material-ui-core";
import RHRCarouselLeftArrow from "icon-carousel-left-arrow-rhr";
import memoize from "utils/memoize";

const useStyles = props =>
  makeStyles(theme => {
    const { arrowsSize, arrowsVisible, arrowsColor } = props;

    return createStyles({
      carouselArrowGrid: {
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "auto",
        padding: theme.spacing(2),
        zIndex: 10,
        cursor: "pointer",
        opacity: arrowsVisible === "never" ? 0 : 0.8,
        transition: "opacity 0.5s ease",
        "&:hover": {
          opacity: arrowsVisible === "never" ? 0 : 1
        }
      },
      carouselArrow: {
        color: arrowsColor ?? theme.palette.common.white,
        fontSize: 68,

        [theme.breakpoints.up("lg")]: {
          fontSize: 68
        }
      }
    });
  });

const sanitizeArrowClassName = (
  className: string,
  allowDisabledClass = false
) => {
  if (typeof className !== "string") {
    return "";
  }
  const regex = allowDisabledClass
    ? /\bslick(?:-((?!disabled))\w+\b)+/g
    : /\bslick(?:-\w+\b)+/g;

  return className.replace(regex, "");
};

export const RHRPreviousArrow = props => {
  const {
    onClick,
    carouselArrowGridStyles,
    carouselArrowStyles,
    className = "",
    allowDisabledClass = false,
    imageCarousels
  } = props;
  const classes = useStyles(props)();
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

  return (
    <Grid
      id={"component-rhr-previous-arrow"}
      container
      className={`${classes.carouselArrowGrid} ${sanitizeArrowClassName(
        className,
        allowDisabledClass
      )}`}
      style={{
        ...carouselArrowGridStyles
      }}
      alignItems="center"
      justify="center"
      onClick={onClick}
    >
      <RHRCarouselLeftArrow
        className={`${classes.carouselArrow} arrow-icon`}
        style={{ ...carouselArrowStyles }}
        size={xsDown ? "sm" : "md"}
      />
    </Grid>
  );
};
export default memoize(RHRPreviousArrow);
