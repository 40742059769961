import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import memoize from "utils/memoize";

import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_LG,
  BREAKPOINT_XL,
  BREAKPOINT_IPAD
} from "utils/constants";
import RHImage from "component-rh-image";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notAvaibleContentHeight: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      ".cols-6 &": {
        minHeight: "100%"
      },
      ".cols-12 &": {
        minHeight: "100%"
      },

      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        ".cols-6 &": {
          minHeight: "100%"
        },
        ".cols-4 &": {
          minHeight: "100%"
        }
      },
      [theme.breakpoints.up(BREAKPOINT_IPAD)]: {
        ".cols-6 &": {
          minHeight: "100%"
        },
        ".cols-4 &": {
          minHeight: "100%"
        }
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        ".cols-6 &": {
          minHeight: "100%"
        },
        ".cols-4 &": {
          minHeight: "100%"
        }
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        ".cols-6 &": {
          minHeight: "100%"
        },
        ".cols-4 &": {
          minHeight: "100%"
        }
      },

      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        ".cols-6 &": {
          minHeight: "100%"
        },
        ".cols-4 &": {
          minHeight: "100%"
        }
      }
    }
  })
);

type RHRImageNotFoundProps = {
  imgStyle?: React.CSSProperties;
};

export const RHRImageNotFound = ({ imgStyle }: RHRImageNotFoundProps) => {
  const classes = useStyles();

  return (
    <div
      id={"component-rhr-image-not-found"}
      className={classes.notAvaibleContentHeight}
    >
      <RHImage
        src="https://media.restorationhardware.com/is/image/rhis?src=noimage"
        alt="image-available-soon"
        imgStyle={{
          width: "10rem",
          height: "8.5rem",
          transform: "scale(2.3)",
          ...imgStyle
        }}
      />
    </div>
  );
};

export default memoize(RHRImageNotFound);
