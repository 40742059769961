import analyticsLoader from "analytics/loader";
import classNames from "classnames";
import RHImage from "component-rh-image";
import { RHImageContext } from "component-rh-image/RHImageContext";
import RHLink from "component-rh-link";
import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import { usePageContent } from "customProviders/LocationProvider";
import { SaleContextFilter } from "graphql-client/queries/app";
import he from "he";
import { useRhUserAtomValue } from "hooks/atoms";
import useAppData from "hooks/useAppData";
import { useEnv } from "hooks/useEnv";
import { useParams2 } from "hooks/useParams";
import { processEnvServer } from "hooks/useSsrHooks";
import useTypographyStyles from "hooks/useTypographyStyles";
import React, {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo
} from "react";
import { useInView } from "react-intersection-observer";
import { memoryStorage } from "utils/analytics/storage";
import {
  BREAKPOINT_MD,
  IMAGE_ASPECT_RATIO,
  PAGE_BG_GREY
} from "utils/constants";
import {
  Card,
  CardContent,
  CardMedia,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery
} from "utils/material-ui-core";
import memoize from "utils/memoize";
import template from "lodash/template";
import useGetPDPRedirectPath from "hooks/useGetPDPRedirectPath";

const useStyles = makeStyles(theme =>
  createStyles({
    productNamePrice: {
      "& .priceSpan": {
        display: "block",
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: "inline"
        },
        ".cols-12 &": {
          display: "inline"
        }
      },

      "& .priceSeperator": {
        ".cols-12 &": {
          display: "inline"
        },
        display: "none",
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: "inline"
        }
      }
    }
  })
);
export interface ProductCardProps {
  data: Product | CategoryProduct;
  showPriceRange?: boolean;
  saleContextFilter?: SaleContextFilter;
  upsell?: string;
  objectFit?: string;
  cardContentRef?: (ref: HTMLDivElement) => void;
  color?: string;
  colorBg?: string;
  isImageEager?: boolean;
  onViewSelectItemsOnSaleClick?: () => void;
  drivedStyles?: CSSProperties;
  cardIndex?: number;
  isObserver?: boolean;
  setActiveDot?: Dispatch<SetStateAction<number>>;
  maxContainerHeight?: number;
  maxUpsellHeight?: number[];
  setMaxUpSetHeight?: Dispatch<SetStateAction<number[]>>;
  totalUpSell?: number;
  RHImageStyles?: any;
  cardMediaStyles?: any;
}

export const ProductCard: FC<ProductCardProps> = memoize(
  // TODO: analytics remove "upsell" param here
  ({
    data,
    showPriceRange,
    saleContextFilter,
    upsell,
    cardContentRef,
    color,
    objectFit,
    colorBg = PAGE_BG_GREY,
    isImageEager,
    onViewSelectItemsOnSaleClick,
    drivedStyles = {},
    cardIndex = 0,
    isObserver = false,
    setActiveDot = () => {},
    maxContainerHeight = 0,
    maxUpsellHeight = [],
    setMaxUpSetHeight = () => {},
    totalUpSell = 0,
    RHImageStyles = {},
    cardMediaStyles = {}
  }) => {
    const env = useEnv();
    const classes = useStyles();
    const typographyClasses = useTypographyStyles({
      keys: ["rhBaseH7", "rhBaseCaption", "rhBaseBody1"]
    });
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

    const { params } = useParams2<{ [key: string]: string }>(
      { version: "" },
      { toLowerCase: true }
    );
    const { userType } = useRhUserAtomValue();

    const { ref: observerRef, inView } = useInView();

    const isLatestPDPLayout =
      memoryStorage.getItem("newPdpLayout") || params.version === "v2";

    useEffect(() => {
      if (isObserver) {
        if (inView) {
          setActiveDot(cardIndex);
        }
      }
    }, [inView]);

    const { pageContent } = usePageContent();

    const priceRangeDisplay = useMemo(() => {
      const isMultiSkuPopulated =
        !!data.priceRangeMultiSkuDisplay &&
        !data.priceRangeMultiSkuDisplay.isUnavailable &&
        !data?.metaProduct;

      return isMultiSkuPopulated
        ? {
            ...data.priceRangeDisplay,
            listPrices: data?.priceRangeMultiSkuDisplay?.listPrices,
            memberPrices: data?.priceRangeMultiSkuDisplay?.memberPrices,
            skulowestMemberPrice:
              data?.priceRangeMultiSkuDisplay?.skulowestMemberPrice,
            nextGenDrivenOnSale:
              data?.priceRangeMultiSkuDisplay?.nextGenDrivenOnSale
          }
        : data.priceRangeDisplay;
    }, [data.priceRangeDisplay, data.priceRangeMultiSkuDisplay]);

    const shouldShowSaleText =
      (Number(data?.saleInfo?.percentSaleSkus) ?? 0) > 0;

    const selectItemsOnSale =
      Number(data?.saleInfo?.percentSaleSkus) > 0 &&
      Number(data?.saleInfo?.percentSaleSkus) < 100;

    const salePercentageRangeExists =
      data?.saleInfo?.memberSavings?.memberSavingsMax !==
      data?.saleInfo?.memberSavings?.memberSavingsMin;

    const SAVE_ON_SELECT_ITEMS = template(pageContent?.SAVE_ON_SELECT_ITEMS)({
      minPercent: data?.saleInfo?.memberSavings?.memberSavingsMin,
      maxPercent: data?.saleInfo?.memberSavings?.memberSavingsMax
    });

    const SAVE_UP_TO_SELECT_ITEMS = template(
      pageContent?.SAVE_MIN_PERCENT_ON_SELECT_ITEMS
    )({
      minPercent: data?.saleInfo?.memberSavings?.memberSavingsMin
    });

    const saleMessageForPriceComponent =
      shouldShowSaleText && selectItemsOnSale
        ? salePercentageRangeExists
          ? SAVE_ON_SELECT_ITEMS
          : SAVE_UP_TO_SELECT_ITEMS
        : "";

    if (data.id === "BLANK") {
      return null;
    }
    /**
     * Image index set to 1 for flip image because
     * first image is already displayed.
     */
    const altImage =
      data.imageFlip && data?.altImages
        ? data?.altImages[1]?.imageUrl
        : undefined;

    const imageStyle = useMemo(() => {
      let style;
      try {
        style = data?.rhr ? JSON.parse(data?.pgCropRules) : {};
      } catch (error) {
        style = {};
      }
      if (isLatestPDPLayout) {
        return { ...style, maxHeight: "100%" } as CSSProperties;
      }
      return style as CSSProperties;
    }, [data?.pgCropRules, env?.FEATURE_RHR_IMAGE, data?.rhr]);

    const triggerAnalyticsEvent = () => {
      if (!processEnvServer) {
        const itemList = [
          {
            item_name: data?.displayName,
            item_id: data?.id,
            item_category: null,
            item_variant: null,
            quantity: null,
            price: null,
            item_list_name: window?.location?.href?.includes("/search/")
              ? "search"
              : window?.location?.href?.includes("products.jsp")
              ? "pg"
              : null
          }
        ];

        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.SELECT_ITEM.INT_TYPE,
            {
              itemList,
              item_list_name: "PG"
            }
          )
        );
      }
    };

    const redirectPath = useGetPDPRedirectPath(data);

    return (
      <RHImageContext.Provider value={{ loading: "eager" }}>
        <div
          data-testid={`rh-test-container`}
          key={`reveal-${data.id}`}
          className={classNames([
            "opacity-0",
            "animate-fadeInUp",
            isLatestPDPLayout
              ? `w-full sm:w-[296px] md:w-[253px] lg:w-[323px] xl:w-[429px]`
              : "",
            "!animate-custom-animation !opacity-100"
          ])}
        >
          <Card
            elevation={0}
            square
            style={{
              backgroundColor: colorBg,
              ...(maxContainerHeight ? { height: maxContainerHeight } : {}),
              position: "relative"
            }}
          >
            <RHLink
              data-testid={`productCardLink-${data.id}`}
              to={redirectPath}
              tabIndex={-1}
              underline="none"
              onClick={triggerAnalyticsEvent}
            >
              {data?.isShopByRoom ? (
                <CardMedia
                  className="[&.MuiGrid-root.MuiGrid-container]:justify-center"
                  style={{ ...data?.imageContainerStyle, ...cardMediaStyles }}
                >
                  <RHImage
                    src={data?.imageUrl}
                    altImage={altImage}
                    alt={data.displayName}
                    objectFit="contain"
                    preset={mdUp ? "pg-card-md" : "pg-card-xs"}
                    imageSkeletonBackgroundColor="#f9f7f4"
                    imageWrapperStyles={{ backgroundColor: "#f9f7f4" }}
                    disableInitialShapeEffect={isImageEager}
                    style={{
                      ...(data?.imageStyle || {}),
                      maxWidth: "100%",
                      ...RHImageStyles
                    }}
                  ></RHImage>
                </CardMedia>
              ) : (
                <CardMedia className="[&.MuiGrid-root.MuiGrid-container]:justify-center flex flex-col justify-end">
                  <RHImage
                    src={data?.imageUrl}
                    altImage={altImage}
                    alt={data.displayName}
                    objectFit={objectFit}
                    preset={mdUp ? "pg-card-md" : "pg-card-xs"}
                    aspectRatio={IMAGE_ASPECT_RATIO["productCard"]}
                    imageSkeletonBackgroundColor="#f9f7f4"
                    imageWrapperStyles={{ backgroundColor: "#f9f7f4" }}
                    disableInitialShapeEffect={isImageEager}
                    style={{
                      ...imageStyle,
                      width: "none",
                      height: "none",
                      maxWidth: "100%"
                    }}
                    imgStyle={{ width: "100%", height: "max-content" }}
                    imageStyles={{ alignItems: "end" }}
                    imageRef={true}
                    setMaxUpSetHeight={setMaxUpSetHeight}
                    upsellContainerStyles={
                      maxUpsellHeight.length >= totalUpSell && isLatestPDPLayout
                        ? { height: `${Math.max(...maxUpsellHeight)}px` }
                        : {}
                    }
                    imageClassName={
                      isLatestPDPLayout
                        ? "w-full sm:w-[296px] md:w-[253px] lg:w-[323px] xl:w-[429px]"
                        : ""
                    }
                  ></RHImage>
                </CardMedia>
              )}
            </RHLink>
            <div className="flex justify-center">
              <span ref={observerRef}></span>
            </div>
            <RHLink
              data-testid={`productCardLink-${data.id}`}
              to={redirectPath}
              tabIndex={-1}
              underline="none"
              bypassForceReload={
                data?.priceRangeDisplay?.priceMessage &&
                (data?.priceRangeDisplay?.salePrices?.[0] ||
                  data?.priceRangeDisplay?.priceMessagePrice)
                  ? true
                  : false
              }
              onClick={triggerAnalyticsEvent}
            >
              <CardContent className="!p-0" ref={cardContentRef}>
                <div
                  className={`${isLatestPDPLayout ? "mt-8" : "mt-5"} ${
                    data?.rhr ? "text-center" : "text-left"
                  }`}
                >
                  <Typography
                    data-testid={`display-test-id`}
                    className={`${
                      isLatestPDPLayout
                        ? "self-stretch flex-grow-0 text-[13px] font-primary-light font-extralight !leading-none text-black text-center tracking-[0.26px]"
                        : "font-thin text-black tracking-normal !leading-[15.6px]"
                    } !uppercase pr-6 mb-1 ${
                      isLatestPDPLayout || data?.rhr
                        ? "text-center"
                        : "text-left"
                    } ${classes.productNamePrice} ${
                      typographyClasses.rhBaseBody1
                    }`}
                  >
                    <span
                      style={{
                        color: color,
                        background: colorBg
                      }}
                      className="text-[13px] font-primary-rhroman"
                      dangerouslySetInnerHTML={{
                        __html: `${
                          data.newProduct ? `${pageContent.NEW} ` : ""
                        }`
                      }}
                    ></span>
                    <span
                      style={{
                        color: color,
                        background: colorBg
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `${he.decode(data.displayName)}`
                      }}
                    ></span>
                  </Typography>

                  {showPriceRange && !!data?.priceRangeDisplay && (
                    <RHRPriceDisplay
                      listPrice={priceRangeDisplay?.listPrices?.[0]!}
                      memberPrice={priceRangeDisplay?.memberPrices?.[0]!}
                      topLabel={
                        data.priceRangeDisplay?.overridePriceLabel ||
                        pageContent?.["STARTING_AT"]
                      }
                      onSale={priceRangeDisplay?.nextGenDrivenOnSale!}
                      skulowestMemberPrice={
                        priceRangeDisplay?.skulowestMemberPrice!
                      }
                      showSaleMessage={
                        Number(data?.saleInfo?.percentSaleSkus) === 0 ||
                        Number(data?.saleInfo?.percentSaleSkus) === 100
                          ? false
                          : true
                      }
                      userType={userType!}
                      pageContent={pageContent}
                      computedSalePageContent={saleMessageForPriceComponent}
                      variant={"small"}
                      centerAlignFlag={Boolean(data?.rhr)}
                      showMembershipProductPrice={
                        data?.uxAttributes?.membershipProduct?.toLowerCase() ===
                        "true"
                      }
                      showGiftCardPrice={
                        data?.uxAttributes?.giftCert?.toLowerCase() === "true"
                      }
                      isCustomProduct={data?.customProduct!}
                      isSaleFilterEnabled={false}
                      saleUrl={redirectPath}
                    />
                  )}
                </div>
                {/* size and fabrics cta here */}
              </CardContent>
            </RHLink>
          </Card>
        </div>
      </RHImageContext.Provider>
    );
  }
);

ProductCard.defaultProps = {
  showPriceRange: true
};

export default memoize((props: Omit<ProductCardProps, "saleContextFilter">) => {
  const { app } = useAppData();

  return <ProductCard {...props} saleContextFilter={app.saleContextFilter} />;
});
