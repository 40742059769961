import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

import { useLazyQuery } from "@apollo/client";
import { Theme, Typography, useMediaQuery } from "@material-ui/core";
import analyticsLoader from "analytics/loader";
import classNames from "classnames";
import RHImageV2 from "component-rh-image-v2";
import RHSpinner from "component-rh-spinner";
import CardImages from "component-rhr-product-card/CardImages";
import { getPresetMap } from "component-rhr-product-card/helper";
import RHImageV3 from "@RHCommerceDev/component-rh-image-v3";
import RHLink from "components/RHLink";
import { queryProductImage } from "graphql-client/queries/product-image";
import { useAppId } from "hooks-use-app-id";
import { useEnv } from "hooks/useEnv";
import { useIsoCookies } from "hooks/useIsoCookies";
import useLocale from "hooks/useLocale/useLocale";
import { useLocalization } from "hooks/useLocalization";
import useSite from "hooks/useSite";
import {
  processEnvServer as isServer,
  processEnvServer
} from "hooks/useSsrHooks";
import useTypographyStyles from "hooks/useTypographyStyles";
import { COLOR_PREVIEW_AVAILABLE_SOON } from "resources/page-level-resources-schemas/products.json";
import memoize from "utils/memoize";
import stringToObject from "utils/stringToObject";
import yn from "yn";
import { getUrl } from "..";
import ImageCarousel from "../ImageCarousel";
import ImageCarouselV2 from "../ImageCarouselV2";
import { PD } from "../ProductDetails";
import ImageCarouselOptimizer from "components/ImageCarouselOptimizer";

interface ProductCard {
  isStockedFilterActive: boolean;
  isRefinementFilterActive: boolean;
  gridColumns: any;
  totalNumRecs: number;
  host?: string;
  item?: SearchResultRecord;
  isSale?: boolean;
  isSaleFilterEnabled?: boolean;
  filterQueries?: string[];
  pageContent?: any;
  onProductClick: Function;
  productTitle?: string;
  inStockFlow?: boolean;
}
export const ProductCard: FC<ProductCard> = memo(
  ({
    item,
    gridColumns,
    isStockedFilterActive,
    isRefinementFilterActive,
    totalNumRecs,
    host = "",
    isSale,
    isSaleFilterEnabled,
    filterQueries,
    pageContent,
    productTitle,
    onProductClick,
    inStockFlow
  }) => {
    const env = useEnv();
    const siteId = useSite();
    const locale = useLocale();
    const prefix = useLocalization();
    const { isConcierge } = useAppId();
    const isPGColorizationEnabled = yn(env.FEATURE_PG_COLORIZATION);
    const isPGDisableRHImage = yn(env.FEATURE_PG_DISABLE_RH_IMAGE);
    const isColorizable = item?.product?.colorizable;
    const showCarousel: boolean = yn(env?.FEATURE_PG_CAROUSEL) || false;
    const customLazyCarousel: boolean =
      yn(env?.FEATURE_PG_LAZY_CUSTOM_CAROUSEL) || false;
    const [selectedSwatchIdx, setSelectedSwatchIdx] = useState(-1);
    const [selectedSwatch, setSelectedSwatch] = useState<string | null>(null);
    const [isSWatchClicked, setIsWatchClicked] = useState(false);
    const [IsClicked, setIsClicked] = useState(false);
    const [isSwatchFinish, setIsSwatchFinish] = useState(false);
    const nextgenCookie = useIsoCookies(["nextgenpg"], true)?.nextgenpg;

    const [isSwatchSelected, setIsSwatchSelected] = useState(false);
    const isRHR = item?.product?.rhr ?? false;
    const isRHRImage = yn(env?.FEATURE_RHR_IMAGE) && isRHR;
    const isConciergeRHRImage =
      isConcierge && yn(env?.FEATURE_RHR_IMAGE) && isRHR;
    const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
    const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));
    const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

    const typographyStyles = useTypographyStyles({
      keys: [
        "rhBaseBody1",
        "rhBaseH2",
        "rhBaseCaption",
        "rhBaseBody2",
        "rhBaseBody3",
        "rhBaseCaption1",
        "rhBaseCaption2"
      ]
    });

    const to = useMemo(() => {
      return (
        prefix +
        getUrl(
          item,
          host,
          isStockedFilterActive,
          isRefinementFilterActive,
          totalNumRecs,
          Boolean(isSale || isSaleFilterEnabled),
          isConcierge,
          filterQueries,
          selectedSwatch,
          nextgenCookie === "true",
          inStockFlow
        )?.to
      );
    }, [
      item,
      host,
      isStockedFilterActive,
      isRefinementFilterActive,
      totalNumRecs,
      Boolean(isSale || isSaleFilterEnabled),
      isConcierge,
      filterQueries,
      selectedSwatch
    ]);
    let saleUrl = "";
    const generatedSaleUrl = useMemo(() => {
      return (
        prefix +
        getUrl(
          item,
          host,
          isStockedFilterActive,
          isRefinementFilterActive,
          totalNumRecs,
          true,
          isConcierge,
          filterQueries,
          selectedSwatch,
          nextgenCookie === "true",
          inStockFlow
        )?.to
      );
    }, [
      item,
      host,
      isStockedFilterActive,
      isRefinementFilterActive,
      totalNumRecs,
      Boolean(isSale || isSaleFilterEnabled),
      isConcierge,
      filterQueries,
      selectedSwatch
    ]);
    if (
      item?.product?.percentSaleSkus !== 0 &&
      item?.product?.percentSaleSkus !== 100 &&
      !Boolean(isSale || isSaleFilterEnabled)
    ) {
      saleUrl = generatedSaleUrl;
    }

    const cols = useMemo(() => {
      return 12 / gridColumns;
    }, [gridColumns]);

    const productDetails: any = item?.product;
    // const swatchRows = item?.product?.swatchInfo?.swatchesToDisplay?.length;
    // let value = swatchRows !== undefined && swatchRows > 6 ? 6 : swatchRows;
    // const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    // const Wrapper = mdUp ? Fade : React.Fragment;
    const [
      getProductSwatchImage,
      {
        data: { productImage: productSwatchImage } = {} as Query,
        loading: productSwatchLoading
      }
    ] = useLazyQuery<Query>(queryProductImage, {
      onCompleted: data => {
        setIsClicked(true);
      },
      onError: data => {}
    });

    // const wrapperProps = useMemo(
    //   () => (mdUp ? { in: true, timeout: { enter: 1600, exit: 1600 } } : {}),
    //   [mdUp]
    // );
    const isLoading = productSwatchLoading && !isPGColorizationEnabled;
    const onSwatchClickHandler = useCallback(
      (e, index, swatch, disableProductCall?: boolean) => {
        if (isPGColorizationEnabled) {
          if (!isPGDisableRHImage) {
            setIsWatchClicked(true);
          }
          setSelectedSwatchIdx(index);
          setSelectedSwatch(swatch?.swatchId);
          if (isColorizable) {
            if (!disableProductCall) {
              setIsClicked(true);
              getProductSwatchImage({
                variables: {
                  productId: item?.product?.repositoryId,
                  swatchIds: swatch?.swatchId ? [swatch.swatchId] : [],
                  siteId,
                  locale
                }
              });
            }
          }
        }
        if (e) {
          e?.stopPropagation();
          e?.preventDefault();
        }
      },
      [
        getProductSwatchImage,
        isColorizable,
        isPGColorizationEnabled,
        item?.product?.repositoryId,
        locale,
        siteId
      ]
    );
    useEffect(() => {
      const swatchToDisplayId = item?.product?.displaySwatch;
      const swatchesToDisplay = item?.product?.swatchInfo?.swatchesToDisplay;
      if (
        swatchToDisplayId &&
        Array.isArray(swatchesToDisplay) &&
        swatchesToDisplay.length > 0
      ) {
        const swatchToDisplay = swatchesToDisplay.find(
          swatch => swatch.swatchId === swatchToDisplayId
        );
        if (swatchToDisplay) {
          onSwatchClickHandler(
            null,
            swatchesToDisplay.indexOf(swatchToDisplay),
            swatchToDisplay,
            true
          );
        }
      }
    }, []);

    const PRESET_MAP = useMemo(() => {
      return getPresetMap(isConciergeRHRImage, isRHRImage);
    }, [isConciergeRHRImage, isRHRImage]);

    const presetImage = useCallback(
      (rawURL: string | undefined, zoom?) => {
        try {
          if (!rawURL) return "";
          let preset = "";
          if (zoom) {
            preset = rawURL?.toUpperCase()?.includes("_RHR")
              ? PRESET_MAP.rhrThreeK
              : PRESET_MAP.threeK;
          } else if (xlUp) {
            preset = PRESET_MAP.xlUp[`col-${cols}`];
          } else if (lgUp) {
            preset = PRESET_MAP.lgUp[`col-${cols}`];
          } else if (mdUp) {
            preset = PRESET_MAP.mdUp[`col-${cols}`];
          } else if (smUp) {
            preset = PRESET_MAP.smUp[`col-${cols}`];
          } else {
            preset = PRESET_MAP.xsUp[`col-${cols}`];
          }

          const url = new URL(`https:${rawURL}`);
          if (url?.search) {
            return `${rawURL}&${preset}`;
          } else {
            return `${rawURL}?${preset}`;
          }
        } catch (error) {
          return rawURL;
        }
      },
      [
        xlUp,
        lgUp,
        mdUp,
        smUp,
        PRESET_MAP.rhrThreeK,
        PRESET_MAP.threeK,
        PRESET_MAP.xlUp,
        PRESET_MAP.lgUp,
        PRESET_MAP.mdUp,
        PRESET_MAP.smUp,
        PRESET_MAP.xsUp,
        cols
      ]
    );

    const triggerAnalyticsEvent = () => {
      if (!isServer) {
        const itemList = [
          {
            item_name: item?.product?.displayName,
            item_id: item?.product?.repositoryId,
            item_category: productTitle,
            item_variant: null,
            quantity: null,
            price: null,
            item_list_name: window.location.href.includes("/search/")
              ? "search"
              : window.location.href.includes("products.jsp")
              ? "pg"
              : null
          }
        ];

        analyticsLoader(a =>
          a.emitAnalyticsEvent(
            document.querySelector("#spa-root > *")! as HTMLElement,
            a.EVENTS.SELECT_ITEM.INT_TYPE,
            {
              itemList,
              item_list_name: true
            }
          )
        );
      }
    };

    const imagesArr = useMemo(() => {
      const product = item?.product;
      let images: string[] = [];
      if (product) {
        if (showCarousel && !product?.alternateImages?.length) {
          images.push(product?.imageUrl! || product?.altImageUrl!);
        } else if (showCarousel && product?.alternateImages?.length) {
          images = product?.alternateImages.map(data => data.imageUrl) || [];
        } else {
          images.push(product?.imageUrl! || product?.altImageUrl!);
        }
      }

      if (productSwatchImage?.imageUrl) {
        const { imageUrl } = productSwatchImage;
        const newArray = [...images];
        newArray[0] = imageUrl;
        images = newArray;
        setIsClicked(false);
      }
      return images;
    }, [item?.product, showCarousel, productSwatchImage?.imageUrl]);

    const optimizerImagesList = useMemo(() => {
      let imageList = item?.product?.alternateImages || [];

      if (productSwatchImage?.imageUrl) {
        const { imageUrl } = productSwatchImage;

        imageList = [{ imageUrl }, ...imageList.slice(1)];

        return imageList;
      }

      return imageList;
    }, [item?.product?.alternateImages, productSwatchImage]);

    const imageAlternativeName = useMemo(() => {
      let name = "";
      if (Boolean(item?.product?.skuOptiondata)) {
        const optionData: any = item?.product?.skuOptiondata
          ? stringToObject(item?.product?.skuOptiondata)
          : {};

        if (optionData.hasOwnProperty("Finish")) {
          setIsSwatchFinish(true);
        } else {
          setIsSwatchFinish(false);
        }

        if (
          optionData.hasOwnProperty("Finish") &&
          optionData.hasOwnProperty("Color")
        ) {
          name = `${item?.product?.displayName} with ${optionData?.Color} fabric and ${optionData?.Finish} finish`;
        } else if (optionData.hasOwnProperty("Finish")) {
          name = `${item?.product?.displayName} in ${optionData?.Finish} finish`;
        } else if (optionData.hasOwnProperty("Color")) {
          name = `${item?.product?.displayName} in ${optionData?.Color}`;
        } else {
          name = item?.product?.displayName || "";
        }
      } else {
        name = item?.product?.displayName || "";
      }

      return name;
    }, [item, stringToObject]);

    const swatchAriaLabel = swatchName =>
      `${swatchName} ${isSwatchFinish ? "finish" : ""}`;

    return (
      <div>
        <RHLink
          to={to}
          onClick={() => {
            triggerAnalyticsEvent();
            onProductClick && onProductClick();
          }}
          className={classNames("!no-underline", {
            "relative flex w-full h-auto flex-col": !processEnvServer
          })}
          aria-label={`${imageAlternativeName}`}
          tabIndex={0}
          role={"link"}
        >
          {yn(env?.FEATURE_EMBLA_CAROUSEL) ? (
            <ImageCarouselOptimizer
              thumbnailImage={item?.product?.imageUrl}
              isImageFlip={Boolean(item?.product?.imageFlip)}
              alternateImage={optimizerImagesList}
              imageStyle={productDetails?.imageStyle || {}}
              imageContainerStyle={{
                justifyContent: "center",
                width: "100%", // container needs 100% width
                ...(productDetails?.imageContainerStyle
                  ? {
                      aspectRatio:
                        productDetails?.imageContainerStyle?.aspectRatio,
                      height: imagesArr[0]
                        ? productDetails?.imageContainerStyle?.height || "auto"
                        : 250
                    }
                  : {})
              }}
            />
          ) : (
            <>
              {isLoading && !processEnvServer ? (
                <div
                  className="flex justify-center h-full relative align-center"
                  style={{
                    height: productDetails?.imageContainerStyle?.height || 250
                  }}
                >
                  <RHSpinner />
                </div>
              ) : !!imagesArr?.length ? (
                isPGDisableRHImage ? (
                  showCarousel && !processEnvServer ? (
                    <>
                      {customLazyCarousel ? (
                        <ImageCarouselV2
                          slides={imagesArr}
                          // imagesHeight={productDetails?.imageContainerStyle?.height}
                          presetImage={presetImage}
                          // hide arrows when device is tablet or mobile
                          hideArrows={imagesArr?.length > 1}
                          // hide dots when device is not tablet or mobile
                          imageFlip={Boolean(item?.product.imageFlip)}
                          isClicked={IsClicked}
                          setIsClicked={setIsClicked}
                          imageStyle={productDetails?.imageStyle || {}}
                          imageContainerStyle={{
                            justifyContent: "center",
                            width: "100%", // container needs 100% width
                            ...(productDetails?.imageContainerStyle
                              ? {
                                  aspectRatio:
                                    productDetails?.imageContainerStyle
                                      ?.aspectRatio ?? 1,
                                  height: imagesArr[0]
                                    ? productDetails?.imageContainerStyle
                                        ?.height || "auto"
                                    : 250
                                }
                              : {})
                          }}
                        />
                      ) : (
                        <ImageCarousel
                          slides={imagesArr}
                          // imagesHeight={productDetails?.imageContainerStyle?.height}
                          presetImage={presetImage}
                          // hide arrows when device is tablet or mobile
                          hideArrows={imagesArr?.length > 1}
                          // hide dots when device is not tablet or mobile
                          imageFlip={Boolean(item?.product.imageFlip)}
                          imageStyle={productDetails?.imageStyle || {}}
                          imageContainerStyle={{
                            justifyContent: "center",
                            width: "100%", // container needs 100% width
                            ...(productDetails?.imageContainerStyle
                              ? {
                                  aspectRatio:
                                    productDetails?.imageContainerStyle
                                      ?.aspectRatio ?? 1,
                                  height: imagesArr[0]
                                    ? productDetails?.imageContainerStyle
                                        ?.height || "auto"
                                    : 250
                                }
                              : {})
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {customLazyCarousel && !processEnvServer ? (
                        <RHImageV3
                          src={imagesArr[0] ? presetImage(imagesArr[0]) : ""}
                          alt=""
                          className="h-full object-fill"
                          loading="lazy"
                          imageContainerStyle={{
                            justifyContent: "center",
                            width: "100%", // container needs 100% width
                            ...(productDetails?.imageContainerStyle
                              ? {
                                  aspectRatio:
                                    productDetails?.imageContainerStyle
                                      ?.aspectRatio ?? 1,
                                  height: imagesArr[0]
                                    ? productDetails?.imageContainerStyle
                                        ?.height || "auto"
                                    : 250
                                }
                              : {})
                          }}
                          style={{
                            width: "auto",
                            maxWidth: "100%",
                            maxHeight:
                              productDetails?.imageStyle?.maxHeight || "100%",
                            height: imagesArr[0]
                              ? productDetails?.imageStyle?.height || "auto"
                              : 250
                          }}
                        />
                      ) : (
                        <RHImageV2
                          src={imagesArr[0] ? presetImage(imagesArr[0]) : ""}
                          alt=""
                          className="h-full object-fill"
                          loading="lazy"
                          imageContainerStyle={{
                            justifyContent: "center",
                            width: "100%", // container needs 100% width
                            ...(productDetails?.imageContainerStyle
                              ? {
                                  aspectRatio:
                                    productDetails?.imageContainerStyle
                                      ?.aspectRatio ?? 1,
                                  height: imagesArr[0]
                                    ? productDetails?.imageContainerStyle
                                        ?.height || "auto"
                                    : 250
                                }
                              : {})
                          }}
                          style={{
                            width: "auto",
                            maxWidth: "100%",
                            maxHeight:
                              productDetails?.imageStyle?.maxHeight || "100%",
                            height: imagesArr[0]
                              ? productDetails?.imageStyle?.height || "auto"
                              : 250
                          }}
                        />
                      )}
                    </>
                  )
                ) : (
                  <CardImages
                    images={imagesArr}
                    gridColumns={gridColumns}
                    imageFlip={Boolean(item?.product.imageFlip)}
                    isRHR={item?.product?.rhr ?? false}
                    productDetails={item?.product}
                    productUrl={to}
                    swatchHandlerProps={{
                      isSWatchClicked,
                      setIsWatchClicked,
                      productSwatchLoading,
                      isSwatchPresent: !!(
                        isColorizable && isPGColorizationEnabled
                      )
                    }}
                  />
                )
              ) : null}
              {isPGColorizationEnabled &&
              !isColorizable &&
              selectedSwatchIdx > -1 &&
              isSwatchSelected &&
              item?.product?.swatchInfo?.swatchesToDisplay?.length ? (
                <div
                  style={{ background: "rgba(0, 0, 0, 0.4)" }}
                  className="w-full h-16 bg-black-40 flex items-center justify-center absolute top-0"
                >
                  <Typography
                    className={classNames(
                      typographyStyles.rhBaseBody1,
                      "!text-white"
                    )}
                  >
                    {pageContent?.COLOR_PREVIEW_AVAILABLE_SOON ||
                      COLOR_PREVIEW_AVAILABLE_SOON}
                  </Typography>
                </div>
              ) : null}
            </>
          )}
          <p
            className={classNames(
              `text-[10px] sm:text-[13px] leading-[13.2px] sm:leading-5 my-0 text-black font-primary-thin`,
              {
                "pt-1.5 sm:pt-2.5 xl:pt-1.5":
                  !!item?.product?.swatchInfo?.swatchesToDisplay?.length,
                "pt-3.5 md:pt-4 lg:pt-5":
                  !item?.product?.swatchInfo?.swatchesToDisplay?.length,
                "text-center": !!item?.product?.rhr,
                "text-left": !item?.product?.rhr
              }
            )}
            dangerouslySetInnerHTML={{
              __html: `${item?.product?.galleryDescription} `
            }}
          />
        </RHLink>

        <PD
          product={item?.product!}
          isStockedFilterActive={isStockedFilterActive}
          isRefinementFilterActive={isRefinementFilterActive}
          productSku={item?.sku!}
          isSale={isSale}
          host={host}
          totalNumRecs={totalNumRecs}
          isSaleFilterEnabled={isSaleFilterEnabled}
          gridColumns={gridColumns}
          saleUrl={saleUrl}
          inStockFlow={inStockFlow}
        />
        {item?.product?.swatchInfo?.swatchesToDisplay?.length ? (
          <>
            <div
              className={classNames(
                `grid gap-x-0.5 grid-flow-col auto-cols-max mt-[20px] md:mt-4 lg:mt-5 !gap-[3px]`,
                {
                  "place-content-center": item?.product?.rhr,
                  "place-content-start": !item?.product?.rhr
                }
              )}
            >
              {item?.product?.swatchInfo?.swatchesToDisplay
                ?.slice(0, 6)
                ?.map((swatch, index) => (
                  // Heights are created as arbitrary values for now, Need to move to global config or change body style rem
                  <div
                    className={`w-full aspect-[2/1] sm:aspect-[2.5/1] h-3 lg:h-4 xl:h-5`} // SR-1013
                    key={`swatch-${swatch?.swatchId || index}`}
                  >
                    <a
                      href="#"
                      tabIndex={0}
                      role="link"
                      aria-label={`${swatchAriaLabel(swatch?.displayName)}`}
                      onClick={e => {
                        e.preventDefault();
                        setIsSwatchSelected(true);
                        onSwatchClickHandler(e, index, swatch);
                      }}
                    >
                      <RHImageV2
                        role="none"
                        src={swatch.imageUrl}
                        alt={`${swatchAriaLabel(swatch?.displayName)}`}
                        className="h-full object-cover"
                      />
                    </a>
                    {(selectedSwatch === swatch?.swatchId ||
                      isSwatchSelected) &&
                    selectedSwatchIdx === index &&
                    isPGColorizationEnabled ? (
                      <div
                        className="!h-[0.03rem] !border-black !mt-[3px] !py-0 !px-[1px]"
                        style={{
                          border: "0.5px solid"
                        }}
                      />
                    ) : null}
                  </div>
                ))}
            </div>
          </>
        ) : null}
      </div>
    );
  }
);

export const PC = memoize(ProductCard);
