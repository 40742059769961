import React, { FC } from "react";
import { Theme, createStyles, makeStyles } from "utils/material-ui-core";
import cn from "classnames";

type Props = {
  className?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      position: "relative",
      display: "block",
      "@media print": {
        pageBreakInside: "avoid"
      }
    }
  })
);

export const Item: FC<Props> = ({ children, className }) => {
  const classes = useStyles();

  return <div className={cn([classes.content, className])}>{children}</div>;
};
