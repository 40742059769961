import { makeStyles, createStyles, Theme } from "@material-ui/core";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS
} from "utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: "100% !important"
    },
    productPrice: {
      color: "#201F1F",
      textAlign: "start",
      WebkitTextAlign: "center",
      textLeadingTrim: "both",
      textEdge: "cap",
      fontFamily: "RHSans-Thin !important",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "14.3px !important",
      letterSpacing: "0.26px",
      "& > #rh-price-range-display": {
        paddingRight: "0px !important"
      },
      "& #first-price-label-swatch": {
        color: "#201F1F",
        textAlign: "center",
        display: "block",
        fontFamily: "RHSans-ExtraLight !important",
        fontSize: "13px !important",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "110% !important",
        letterSpacing: "0.26px"
      },
      "& #swatchMemberPrice > *": {
        color: "#201F1F",
        textAlign: "center",
        textAlignLast: "center",
        fontFamily: "RHSans-Light !important",
        fontSize: "16px !important",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "100% !important"
      },
      "& #regularPriceContainer > *": {
        color: "#201F1F",
        textAlign: "center",
        textAlignLast: "center",
        fontFamily: "RHSans-ExtraLight !important",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "200 !important",
        lineHeight: "110% !important",
        letterSpacing: "0.26px"
      }
    },
    productNameContainer: (props: any) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
      marginBottom: "40px",

      [theme.breakpoints.up(BREAKPOINT_XS)]: {
        minWidth: "347px !important",
        maxWidth: "347px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        minWidth: "704px !important",
        maxWidth: "704px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        minWidth: "319px !important",
        maxWidth: "319px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        minWidth: "347px !important",
        maxWidth: "347px !important"
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        minWidth: "371px !important",
        maxWidth: "371px !important"
      },
      [theme.breakpoints.down(BREAKPOINT_MD)]: {
        paddingTop: "27px",
        marginBottom: "24px"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        paddingTop: "11px",
        minWidth: "286px",
        width: "286px",
        marginBottom: "24px"
      },
      "& #inventory_delivery_message": {
        color: "#201F1F",
        textAlign: "start",
        fontFamily: "RHSans-ExtraLight",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "110%",
        letterSpacing: "0.26px"
      },
      "& #product_title": {
        color: "#000",
        textAlign: "start",
        fontFamily: "RHSans-UltraThin, Helvetica, Arial !important",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 100,
        lineHeight: "28.8px",
        letterSpacing: "0.64px",
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          fontSize: "28px",
          lineHeight: "25.2px",
          letterSpacing: "0.56px"
        }
      }
    }),
    productDelivery: {
      color: "#393C41",
      textAlign: "center",
      fontFamily: "RH Sans",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 200,
      lineHeight: "14.3px",
      letterSpacing: "0.26px"
    }
  })
);

export default useStyles;
