import { Grid, useTheme } from "utils/material-ui-core";
import React from "react";
import { NavLink } from "react-router-dom";

export interface RHMenuLinkProps {
  url: string;
  content: string | React.ReactNode;
  customClasses?: string;
  activeClassName?: string;
  hide?: boolean;
  target?: "_self" | "_blank" | "_parent" | "_top";
}

export interface RHMenuLinkListProps<
  LinkProps extends RHMenuLinkProps = RHMenuLinkProps
> {
  direction: "row" | "column";
  links: LinkProps[];
  spacing?: number;
}

const RHMenuLinkList = ({
  direction,
  links,
  spacing = 1
}: RHMenuLinkListProps) => {
  const theme = useTheme();
  const linkSpacing = theme.spacing(spacing);

  return (
    <Grid container direction={direction}>
      {links
        ?.filter(e => !e.hide)
        .map(({ url, content, customClasses, activeClassName, target }) => (
          <NavLink
            key={`${url}:${content}`}
            style={{ marginBottom: linkSpacing }}
            className={customClasses}
            activeClassName={activeClassName}
            to={url}
            target={target}
          >
            {content}
          </NavLink>
        ))}
    </Grid>
  );
};

export default RHMenuLinkList;
