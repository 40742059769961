import classNames from "classnames";
import RHDialogTitle from "component-rh-dialog-title";
import { usePageContent } from "customProviders/LocationProvider";
import { useEnv } from "hooks/useEnv";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHArrowIcon from "icons/RHArrowIcon";
import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { FONT_BARON_SANS_LIGHT, IN_STOCK_READY_TO_SHIP } from "utils/constants";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery
} from "utils/material-ui-core";
import memoize from "utils/memoize";
import InStockOnSaleDialog from "./InStockOnSaleDialog";

const env = useEnv();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: FONT_BARON_SANS_LIGHT,
      fontSize: theme.typography.pxToRem(14),
      letterSpacing: "0.025em",
      lineHeight: theme.typography.pxToRem(20),
      textTransform: "uppercase",
      color: "#000000",
      "&:hover": {
        color: "black",
        textDecoration: "none"
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "13px"
      }
    },
    arrow: {
      fontSize: 13,
      width: "13px !important",
      height: "13px !important"
    },
    content: {
      overflow: "auto"
    },
    actionLink: {
      color: "#404040 !important",
      textTransform: "uppercase",
      textDecoration: "underline",
      textUnderlineOffset: "1px",
      cursor: "pointer"
    },
    inStockCTA: {
      marginTop: "36px !important",
      marginBottom: "16px",
      textTransform: "uppercase",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    stockTextWrap: {
      display: "inline-flex"
    }
  })
);

export interface InStockOnSaleLinkProps {
  id?: string;
  text: string;
  drawerTitle: string;
  postalCode?: string;
  postalCodeNotRequired?: boolean;
  textModifier?: string;
  isRHRProduct?: boolean;
  handleInStockDrawer?: () => void;
  styles?: CSSProperties;
  enableArrow?: boolean;
  isActiveInstockModal?: boolean;
  mainProduct?: string;
  productId?: string;
}

export const InStockOnSaleLink: FC<InStockOnSaleLinkProps> = ({
  id,
  text,
  drawerTitle,
  postalCode,
  postalCodeNotRequired,
  children,
  isRHRProduct = false,
  handleInStockDrawer,
  styles = {},
  enableArrow = false,
  isActiveInstockModal = false,
  mainProduct,
  productId
}) => {
  const { pageContent } = usePageContent();
  const classes = useStyles();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption", "uppercaseText", "priceRangeSale"]
  });
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const isPostalCodeAvailable = useMemo(() => {
    return (
      !!postalCode &&
      postalCode !== "null" &&
      postalCode !== "undefined" &&
      postalCode !== "false"
    );
  }, [postalCode]);

  const handleOpenInStockOnSaleDrawer = useCallback(
    () => setDrawerOpen(true),
    []
  );

  const handleCloseInStockOnSaleDrawer = useCallback(
    () => setDrawerOpen(false),
    []
  );

  useEffect(() => {
    if (
      !isActiveInstockModal ||
      !enableArrow ||
      !mainProduct ||
      !productId ||
      mainProduct !== productId
    ) {
      return;
    }
    setDrawerOpen(true);
  }, [isActiveInstockModal, enableArrow, mainProduct, productId]);

  return (
    <>
      {isRHRProduct ? (
        <Typography
          id={id || "component-in-stock-on-sale-link"}
          className={classNames([
            classes.actionLink,
            typographyStyles?.rhBaseCaption,
            typographyStyles?.uppercaseText
          ])}
          onClick={handleOpenInStockOnSaleDrawer}
        >
          {pageContent?.VIEW} {text} {pageContent?.ITEMS}
        </Typography>
      ) : (
        <span
          id={id || "component-in-stock-on-sale-link"}
          className={classNames(classes.root, {
            [typographyStyles?.priceRangeSale]:
              text?.toLowerCase() === "sale items"
          })}
          onClick={handleOpenInStockOnSaleDrawer}
          style={{
            color: text === IN_STOCK_READY_TO_SHIP ? "#000000" : "",
            marginTop: text === IN_STOCK_READY_TO_SHIP ? "8px" : "0px",
            fontSize: text === IN_STOCK_READY_TO_SHIP ? "13px" : "",
            letterSpacing: text === IN_STOCK_READY_TO_SHIP ? "0.025em" : "",
            cursor: "pointer",
            ...(enableArrow
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: 13
                }
              : {}),
            ...styles
          }}
        >
          <div className={classes.stockTextWrap}>
            {pageContent?.VIEW} {text}{" "}
            {IN_STOCK_READY_TO_SHIP ? "" : `${pageContent?.ITEMS}`}
          </div>
          {enableArrow && <RHArrowIcon className={classes.arrow} />}
        </span>
      )}
      {drawerOpen ? (
        <InStockOnSaleDialog
          open={drawerOpen}
          onClose={handleCloseInStockOnSaleDrawer}
          isPostalCodeAvailable={isPostalCodeAvailable}
          handleInStockDrawer={handleInStockDrawer}
          postalCodeNotRequired={postalCodeNotRequired}
        >
          <RHDialogTitle
            title={drawerTitle}
            onClose={handleCloseInStockOnSaleDrawer}
            style={{ paddingLeft: smDown ? "40px" : "0px" }}
          />
          {children}
        </InStockOnSaleDialog>
      ) : null}
    </>
  );
};

export default memoize(InStockOnSaleLink);
