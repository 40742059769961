export const keyArr = [
  "priceRange",
  "priceRangeSale",
  "priceRangeSale2",
  "priceRangeMember",
  "priceRangeMember2",
  "priceRangeBold",
  "priceRangeBold2",
  "rhrPriceRangeBold",
  "rhBaseBody1",
  "rhBaseCaption",
  "uppercaseText",
  "priceRangeRegular",
  "skuListColumn2"
];

export const ContractType = "CONTRACT";
export const TradeType = "TRADE";
export const contractTradeTypes = [ContractType, TradeType];
