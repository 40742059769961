import classnames from "classnames";
import useTypographyStyles from "hooks/useTypographyStyles";
import React from "react";
import { Typography } from "utils/material-ui-core";
import memoize from "utils/memoize";

const RHRPriceSeparator = ({ data }) => {
  const typographyClasses = useTypographyStyles({
    keys: ["priceRangeSale", "priceRangeMember"]
  });

  return (
    <Typography
      className={classnames(
        data?.onSale
          ? typographyClasses.priceRangeSale
          : typographyClasses.priceRangeMember
      )}
      display="inline"
    >
      {" / "}
    </Typography>
  );
};

export default memoize(RHRPriceSeparator);
