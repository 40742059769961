import { processEnvServer } from "hooks/useSsrHooks";
import type { ComponentProps } from "react";
import React, {
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { useHistory } from "react-router";
import memoize from "utils/memoize";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import LazyLoad from "react-lazyload";
import RHSpinner from "components/RHSpinner";

const IMAGE_FALLBACK_URL =
  "https://media.restorationhardware.com/is/image/rhis?src=noimage";
const RHImageV3 = (
  props: ComponentProps<"img"> & {
    imageContainerStyle?: any;
    skeletonClass?: string;
    skeletonColor?: string;
    lazyLoadRequired?: boolean;
  }
) => {
  const {
    imageContainerStyle = {},
    skeletonClass,
    skeletonColor,
    lazyLoadRequired = false,
    ...imgProps
  } = props;
  const [imgSrc, setImgSrc] = useState(imgProps.src || IMAGE_FALLBACK_URL);
  const [doneLoading, setDoneLoading] = useState(false);
  const [loadedSrc, setLoadedSrc] = useState("");

  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const history = useHistory();
  const isSSRLoad =
    (isSSRToggledWithCSR && !document.referrer && history.action !== "PUSH") ||
    processEnvServer;

  useEffect(() => {
    if (imgProps.src && imgProps.src !== imgSrc) {
      setImgSrc(imgProps.src);
    }
  }, [imgProps.src, imgSrc]);

  const handleImageError = () => {
    setImgSrc(IMAGE_FALLBACK_URL);
  };

  useLayoutEffect(() => {
    if (loadedSrc !== imgSrc) {
      setDoneLoading(false);
    } else {
      setDoneLoading(true);
    }
  }, [imgSrc, loadedSrc]);

  const handleLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    setDoneLoading(false);
    setLoadedSrc(() => imgSrc);
    imgProps.onLoad?.(event);
    setDoneLoading(true);
  };

  return (
    <>
      {lazyLoadRequired ? (
        <div
          style={{
            height: imageContainerStyle ? imageContainerStyle.maxHeight : "250"
          }}
          className={`relative flex flex-initial items-end justify-around px-1  w-full`}
        >
          <LazyLoad
            height={imageContainerStyle ? imageContainerStyle.maxHeight : "250"}
            offset={400}
            once
            data-testid="rh-image-v3-container"
          >
            {!isSSRLoad && !doneLoading && (
              <div
                style={{
                  height: imageContainerStyle
                    ? imageContainerStyle.maxHeight
                    : "250"
                }}
              >
                <RHSpinner />
              </div>
            )}
            <img
              {...imgProps}
              onLoad={handleLoad}
              src={imgSrc}
              onError={handleImageError}
              data-testid={`rh-image-v3-${props?.alt}`}
              id={`rh-image-v3-${props?.alt}`}
              key={`rh-image-v3-${props?.alt}`}
              style={{
                width: "100%",
                height: imageContainerStyle
                  ? imageContainerStyle.maxHeight
                  : "250"
              }}
              loading="lazy"
            />
          </LazyLoad>
        </div>
      ) : (
        <img
          {...imgProps}
          onLoad={handleLoad}
          src={imgSrc}
          onError={handleImageError}
        />
      )}
    </>
  );
};

export default memoize(RHImageV3);
