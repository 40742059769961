import React, { Fragment } from "react";
import memoize from "utils/memoize";

const Arrows = ({
  scrollPrev,
  scrollNext,
  prevClassName = "",
  nextClassName = "",
  index = 1,
  slides = 0
}) => {
  return (
    <Fragment>
      {index !== 1 && (
        <button
          className={`embla__prev m-0 p-0 inline-flex absolute left-0 top-[46%] group-hover/item:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-transparent border-0 cursor-pointer ${prevClassName} !h-[50%] items-start`}
          onClick={scrollPrev}
          aria-label="previous"
        >
          <svg
            className="arrow-icon !w-[15px] !text-[15px] !fill-none"
            focusable="false"
            viewBox="0 0 32 56"
            aria-hidden="true"
            data-analytics-id="previous"
          >
            <rect width="32" height="56" fill="white" fill-opacity="0.5"></rect>
            <path
              d="M22 40L10 28L22 16"
              stroke="#000"
              stroke-width="1.5"
            ></path>
          </svg>
        </button>
      )}
      {index !== slides && (
        <button
          className={`embla__next m-0 p-0 inline-flex absolute right-0 top-[46%] group-hover/item:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-transparent border-0 cursor-pointer ${nextClassName} !h-[50%] items-start`}
          onClick={scrollNext}
          aria-label="next"
        >
          <svg
            className="!arrow-icon !w-[15px] !text-[15px] !fill-none"
            focusable="false"
            viewBox="0 0 32 56"
            aria-hidden="true"
            data-analytics-id="next"
            style={{
              transform: "rotate(180deg)"
            }}
          >
            <rect width="32" height="56" fill="white" fill-opacity="0.5"></rect>
            <path
              d="M22 40L10 28L22 16"
              stroke="#000"
              stroke-width="1.5"
            ></path>
          </svg>
        </button>
      )}
    </Fragment>
  );
};

export default memoize(Arrows);
