import { PriceRange } from "..";
import {
  countryNameMapper,
  currencyCodeToCurrencySymbolMapper
} from "resources/countries-config.json";

export interface FusionPrices {
  listPrice?: Maybe<number>;
  memberPrice?: Maybe<number>;
  tradePrice?: Maybe<number>;
  contractPrice?: Maybe<number>;
  salePrice?: Maybe<number>;
}

export const convertFusionPriceToRange = (
  fusionPrices: FusionPrices,
  userType: Maybe<string> = ""
): PriceRange => {
  return {
    regular: {
      label: "Regular",
      amount: fusionPrices?.listPrice ?? 0
    },
    ...(fusionPrices?.salePrice
      ? {
          sale: {
            label: "Sale",
            amount: fusionPrices?.salePrice
          }
        }
      : null),
    ...(userType?.toUpperCase() !== "TRADE" &&
    userType?.toUpperCase() !== "CONTRACT"
      ? {
          member: {
            label: "Member",
            amount: fusionPrices?.memberPrice ?? 0,
            showMemberPrice: true
          }
        }
      : null),
    ...(userType?.toUpperCase() === "TRADE"
      ? {
          trade: {
            label: "Trade",
            amount: fusionPrices?.tradePrice ?? 0,
            strikethrough: false
          }
        }
      : null),
    ...(userType?.toUpperCase() === "CONTRACT"
      ? {
          contract: {
            label: "Contract",
            amount: fusionPrices?.contractPrice ?? 0,
            strikethrough: false
          }
        }
      : null)
  };
};

export const multiSkuPriceMapper = (
  fusionPrices: FusionPrices,
  country: string
) => {
  const currencyCode = countryNameMapper[country];
  return {
    currencySymbol: currencyCodeToCurrencySymbolMapper[currencyCode],
    listPrice: fusionPrices?.listPrice ?? 0,
    salePrice: fusionPrices?.salePrice,
    memberPrice: fusionPrices?.memberPrice ?? 0,
    tradePrice: fusionPrices?.tradePrice ?? 0,
    contractPrice: fusionPrices?.contractPrice ?? 0,
    onSale: fusionPrices?.salePrice ? true : false,
    onClearance: false,
    showMemberPrice: true,
    fullSkuId: ""
  };
};
