import { Theme, useMediaQuery } from "@material-ui/core";
import RHCarousel from "component-rh-carousel";
import RHImage from "component-rh-image";
import RHLink from "components/RHLink";
import { Hidden } from "hooks/useSsrHooks";
import React, { useEffect, useRef } from "react";
import "react-magic-slider-dots/dist/magic-dots.css";
import memoize from "utils/memoize";
import { useCardImageStyles } from "../styles";

const SmallScreenPGPImageCard = props => {
  const {
    showCarousel,
    normalizedImages,
    presetImage,
    productDetails,
    bindPinch,
    noCarouselProps = {},
    productUrl
  } = props;

  const {
    zoomedImages,
    setZoomedImages,
    fullMainImageURL,
    onErrorImageURL,
    disabledShapeMainImage,
    loadingbehaviorMainImage,
    imageUrl,
    swatchHandlerProps = {}
  } = noCarouselProps;

  const { isSWatchClicked, setIsWatchClicked, isSwatchPresent } =
    swatchHandlerProps;
  const classes = useCardImageStyles({
    reactSlickHeight: productDetails?.imageContainerStyle?.height || "auto"
  })();
  const parentRef = useRef<React.RefObject<HTMLElement> | null>(null);
  const currentRef = parentRef?.current as {
    flipTo?: (index: number) => void;
  };

  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  useEffect(() => {
    if (isSwatchPresent) {
      if (isSWatchClicked && currentRef?.flipTo) {
        setIsWatchClicked(false);
        currentRef.flipTo(0);
      }
    }
  }, [isSwatchPresent, isSWatchClicked, setIsWatchClicked, currentRef]);

  return (
    <Hidden mdUp={mdUp}>
      {showCarousel ? (
        <RHCarousel
          className={classes.carousel}
          persistDots
          visibleDots={4}
          arrowsVisible="never"
          infinite={true}
          dots={true}
        >
          {[...normalizedImages]?.map(src => {
            const isZoomedImage = zoomedImages?.has(src);
            const loadingbehaviorImage = isZoomedImage ? "eager" : "lazy";
            const disabledShapeImage = loadingbehaviorImage ? true : false;
            const fullImageURL = presetImage(src, isZoomedImage);
            return (
              <RHLink underline="none" to={productUrl}>
                <RHImage
                  rootProps={{ style: { height: "100%" } }}
                  useCssAspectratio
                  disableInitialShapeEffect={disabledShapeImage}
                  key={src}
                  loadingBehavior={loadingbehaviorImage}
                  src={fullImageURL}
                  isShimmerStopped={true}
                  imgStyle={productDetails?.imageStyle}
                  {...bindPinch(src, zoomedImages, setZoomedImages)}
                />
              </RHLink>
            );
          })}
        </RHCarousel>
      ) : (
        <RHLink underline="none" to={productUrl}>
          <RHImage
            useCssAspectratio
            rootProps={{ style: { height: "100%" } }}
            imgStyle={productDetails?.imageStyle}
            src={fullMainImageURL}
            disableInitialShapeEffect={disabledShapeMainImage}
            loadingBehavior={loadingbehaviorMainImage}
            onError={onErrorImageURL}
            isShimmerStopped={true}
            {...bindPinch(imageUrl, zoomedImages, setZoomedImages)}
          />
        </RHLink>
      )}
    </Hidden>
  );
};

export default memoize(SmallScreenPGPImageCard);
