import React, { FC, useState, useEffect, useRef } from "react";
import { Fade, Fab, useTheme } from "utils/material-ui-core";
import RHArrowIcon from "icon-arrow";

export const RHScrollUpButton: FC = () => {
  const theme = useTheme();
  const ref = useRef<HTMLButtonElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.documentElement.style.overflow = "visible";

    return () => {
      document.documentElement.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const docHeight = document.body.scrollHeight;
      const shouldBeVisible =
        window.scrollY > window.innerHeight &&
        docHeight - window.scrollY - window.innerHeight > 300;

      setIsVisible(shouldBeVisible);
      if (ref.current) {
        const position =
          (ref.current.parentElement?.getBoundingClientRect()?.bottom ?? 0) <
          window.innerHeight
            ? "absolute"
            : "fixed";
        ref.current.style.position = position;
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const scrollTop = () => {
    const header = document.querySelector("#spa-root");
    header?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Fade in={isVisible}>
      <Fab
        ref={ref}
        className={
          "bottom-20 right-4 !bg-white !border !border-solid !border-[#999999] z-[11]"
        }
        onClick={scrollTop}
      >
        <RHArrowIcon
          style={{ transform: "rotate(-90deg)" }}
          className={"!text-[15px]"}
        />
      </Fab>
    </Fade>
  );
};

export default RHScrollUpButton;
