import ImageSkeleton from "@RHCommerceDev/skeleton-image";
import { processEnvServer } from "hooks/useSsrHooks";
import type { ComponentProps } from "react";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { useHistory } from "react-router";
import EventEmitter, { COMPONENT_DID_LOAD_EVENT } from "utils/EventEmitter";
import memoize from "utils/memoize";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";

const IMAGE_FALLBACK_URL =
  "https://media.restorationhardware.com/is/image/rhis?src=noimage";
const RHImageV2 = (
  props: ComponentProps<"img"> & {
    imageContainerStyle?: any;
    skeletonClass?: string;
    skeletonColor?: string;
  }
) => {
  const {
    imageContainerStyle = {},
    skeletonClass,
    skeletonColor,
    ...imgProps
  } = props;
  const [imgSrc, setImgSrc] = useState(imgProps.src || IMAGE_FALLBACK_URL);
  const [doneLoading, setDoneLoading] = useState(false);
  const [loadedSrc, setLoadedSrc] = useState("");

  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const history = useHistory();
  const isSSRLoad =
    (isSSRToggledWithCSR && !document.referrer && history.action !== "PUSH") ||
    processEnvServer;

  useEffect(() => {
    if (imgProps.src && imgProps.src !== imgSrc) {
      setImgSrc(imgProps.src);
    }
  }, [imgProps.src, setImgSrc, imgSrc]);

  const handleImageError = () => {
    setImgSrc(IMAGE_FALLBACK_URL);
  };

  useLayoutEffect(() => {
    if (loadedSrc !== imgSrc) {
      setDoneLoading?.(false);
      setDoneLoading(false);
    } else {
      setDoneLoading?.(true);
      setDoneLoading(true);
    }
  }, [imgSrc, loadedSrc, setDoneLoading]);

  const handleLoad = useCallback(
    (event: SyntheticEvent<HTMLImageElement, Event>) => {
      setLoadedSrc(imgSrc);
      imgProps.onLoad?.(event);
      EventEmitter.dispatch(COMPONENT_DID_LOAD_EVENT);
      setDoneLoading?.(true);
      setDoneLoading(true);
    },
    [imgSrc, setLoadedSrc, imgProps, setDoneLoading]
  );

  return (
    <div
      className="relative flex flex-initial items-end h-full"
      style={{ ...imageContainerStyle }}
      data-testid="rh-image-v2-container"
    >
      {!isSSRLoad && !doneLoading && (
        <ImageSkeleton
          className={skeletonClass}
          color={skeletonColor}
          aspectRatio={imgProps.style?.aspectRatio as unknown as any}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        />
      )}
      <img
        onLoad={handleLoad}
        src={imgSrc}
        onError={handleImageError}
        data-testid="rh-image-v2"
        style={{
          visibility: doneLoading || processEnvServer ? "visible" : "hidden",
          width: "100%",
          height: "100%",
          position: doneLoading || processEnvServer ? "relative" : "absolute"
        }}
        {...imgProps}
      />
    </div>
  );
};

export default memoize(RHImageV2);
