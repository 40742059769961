import React, { useState, FC, useCallback } from "react";
import NotificationContext, {
  NotificationVariant,
  NotificationContent
} from "./RHNotificationContext";
import RHNotification from "./RHNotification";
import { errorParse } from "utils/error-handlers/error-parse";

const RHNotificationProvider: FC = ({ children }) => {
  const [display, setDisplay] = useState(false);
  const [variant, setVariant] = useState<NotificationVariant>("success");
  const [content, setContent] = useState<NotificationContent>("");
  const [title, setTitle] = useState("");
  const closeNotificationModal = useCallback((onClose = () => {}) => {
    if (typeof onClose === "function") onClose();
    setDisplay(false);
  }, []);

  const showNotificationModal = useCallback(
    (
      title: string,
      content?: NotificationContent,
      variant?: NotificationVariant
    ) => {
      setTitle(title);
      setContent(
        typeof content === "string" ? errorParse(content || "") : content
      );
      setVariant(variant || "success");
      setDisplay(true);
    },
    []
  );

  return (
    <NotificationContext.Provider
      value={{
        showNotificationModal,
        closeNotificationModal
      }}
    >
      {children}
      <RHNotification
        open={display}
        handleClose={closeNotificationModal}
        title={title}
        content={content}
        variant={variant}
      />
    </NotificationContext.Provider>
  );
};

export default RHNotificationProvider;
