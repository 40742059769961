import React, { FC } from "react";
import { Theme, createStyles, makeStyles } from "utils/material-ui-core";
import cn from "classnames";

type Props = {
  className?: string;
  spacing?: number;
};

const useStyles = (spacing: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      container: {
        position: "relative",
        "& > div": {
          padding: theme.spacing(spacing)
        }
      }
    })
  );

export const Container: FC<Props> = ({ children, className, spacing = 0 }) => {
  const classes = useStyles(spacing)();

  return <div className={cn([className, classes.container])}>{children}</div>;
};
