import React, { FC } from "react";
import FormDialog from "layout-form-dialog";
import {
  Typography,
  useTheme,
  useMediaQuery,
  Theme,
  Grid
} from "utils/material-ui-core";
import useRHStyles from "hooks/useRHStyles";
import {
  NotificationVariant,
  NotificationContent
} from "./RHNotificationContext";
import ErrorIcon from "icon-error";
import SuccessIcon from "icon-success-checkmark";
import memoize from "utils/memoize";

interface RHNotificationProps {
  open: boolean;
  title: string;
  content: NotificationContent;
  variant: NotificationVariant;
  handleClose: (onClose?: () => any) => void;
}

const RHNotification: FC<RHNotificationProps> = ({
  open,
  title,
  content,
  variant,
  handleClose
}) => {
  const rhClasses = useRHStyles({
    keys: ["rhNotificationDialogFixedHeight", "fullHeight", "bottomMargin"]
  });
  const theme = useTheme();
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  return (
    <FormDialog
      open={open}
      fullScreen={smDown}
      onClose={handleClose}
      maxWidth="sm"
    >
      <Grid direction="column" container alignItems="center" justify="center">
        <Typography style={{ marginBottom: theme.spacing(1) }}>
          {variant === "warning" && "warning"}
          {variant === "success" && (
            <SuccessIcon style={{ fontSize: theme.typography.pxToRem(64) }} />
          )}
          {variant === "error" && (
            <ErrorIcon style={{ fontSize: theme.typography.pxToRem(64) }} />
          )}
        </Typography>
        <Typography
          variant="h4"
          style={{ marginBottom: theme.spacing(1), textAlign: "center" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{ marginBottom: theme.spacing(1) }}
        >
          {content}
        </Typography>
      </Grid>
    </FormDialog>
  );
};

export default memoize(RHNotification, { deep: false });
