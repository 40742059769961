import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Product Grid",
  type: "rh/components/content/productgrid",
  isContainer: false,
  config: {
    emptyLabel: "No product(s) or category selected",
    isEmpty: ({ productIds, categoryId }: PropTypes) =>
      !Boolean(productIds ?? categoryId)
  }
};
