import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { Theme } from "@mui/material";
import React from "react";
import { usePageContent } from "customProviders/LocationProvider";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    checkbox: {
      marginLeft: "0px !important",
      marginRight: "8px !important",
      border: "none !important",
      backgroundColor: "transparent !important",
      "& svg": {
        width: "12px",
        height: "12px",
        border: "0.5px solid #000000 !important"
      }
    },
    checkboxLabel: {
      margin: "0px !important",
      "& .MuiTypography-body1": {
        fontFamily: "RHSans-Light",
        fontWeight: 300,
        [theme.breakpoints.up("xs")]: {
          fontSize: 11,
          lineHeight: "13.2px"
        },
        [theme.breakpoints.up("md")]: {
          fontSize: 11,
          lineHeight: "13.2px"
        },
        letterSpacing: "0.165px"
      }
    },
    timeLeadMessage: {
      fontFamily: "RHSans-ExtraLight",
      fontSize: "11px",
      fontWeight: 200,
      lineHeight: "12px",
      letterSpacing: "0.02em",
      textAlign: "center",
      color: "#201F1F",
      cursor: "pointer"
    },
    checkboxLeadGrid: {
      display: "flex",
      gap: "8px",
      alignItems: "center"
    },
    checked: {
      height: "12px",
      width: "12px",
      border: "0.5px solid #000000 !important"
    }
  });
});

export const InstockOptionsFilter = ({ checked, handleChecked }) => {
  const classes = useStyles();
  const { pageContent } = usePageContent();

  return (
    <Grid
      className={classes.checkboxLeadGrid}
      id="checkbox-lead-grid"
      data-testid="checkbox-lead-grid"
    >
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            icon={<div className={classes.checked}></div>}
            checked={checked}
            onChange={() => handleChecked()}
            style={{ height: "auto", width: "auto" }}
          />
        }
        label={pageContent?.SHOW_IN_STOCK_OPTIONS}
        className={classes.checkboxLabel}
      />
      <Typography
        className={classes.timeLeadMessage}
        id="time-lead-message"
        data-testid="time-lead-message"
      >
        {pageContent?.SWATCH_DELIVERY_MESSAGE}
      </Typography>
    </Grid>
  );
};
