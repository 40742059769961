import { processEnvServer } from "hooks/useSsrHooks";
import React, { FC, memo } from "react";
import { Helmet } from "react-helmet";
import { Theme, useMediaQuery } from "utils/material-ui-core";

/* 


this implementation realies on the index.html 
look for the script tag with the id wistia_index_html_script
also with sessionProviderV2
if there is any wistiahero on the page it will be rendered on the server side some information, and we use that to set some flags and defer the 

*/

/* 
 for authors is better if the box2 or other parent container is sync about bgcolor
*/
declare global {
  interface Window {
    _wq: Array<any>;
    _wq_disabled_pipedream: boolean;
    _rh_wait_for_wistia_hero: boolean;
    _rh_on_wistia_hero_play: any;
  }
}

export type WistiaHeroProps = {
  smId: string;
  smPadding: string;
  mdId: string;
  mdPadding: string;
  lgId: string;
  lgPadding: string;
};

const smMediaId = "(max-width: 767px)";
const mdMediaId = "(min-width: 768px) and (max-width: 1365px)";
const lgMediaId = "(min-width: 1366px)";

export type WistiaScriptProps = {
  id: string;
  media: string;
};
const WistiaScripts: FC<WistiaScriptProps> = ({ id, media }) => {
  return (
    <Helmet>
      <link
        data-wistia-clean-up-helmet="true"
        rel="preload"
        href={`https://fast.wistia.com/embed/medias/${id}.json`}
        as="fetch"
        media={media}
        crossOrigin="anonymous"
        {...({ fetchpriority: "high" } as any)}
      />
      <link
        data-wistia-clean-up-helmet="true"
        rel="preload"
        href={`https://fast.wistia.com/embed/medias/${id}.m3u8`}
        as="fetch"
        media={media}
        crossOrigin="anonymous"
        {...({ fetchpriority: "high" } as any)}
      />

      <style data-wistia-clean-up-helmet="true" type="text/css">{`
            #wistia-${id} {
              display:none;
              @media ${media} {
                display:block;
              }
            }
          `}</style>
      <script data-wistia-clean-up-helmet="true" id={`wistia-on-load-${id}`}>{`
           window._wq = window._wq || [];
           _wq.push({ id: "${id}", onReady: function(video) {
             video.bind('play', function() {
              window._rh_wait_for_wistia_hero = false;
              window._rh_on_wistia_hero_play?.();
              window._rh_on_wistia_hero_play = null;
               return video.unbind;
             });
           
           }});
          `}</script>
    </Helmet>
  );
};

const WistiaHero: FC<WistiaHeroProps> = ({
  smId,
  lgId,
  mdId,
  smPadding,
  lgPadding,
  mdPadding
}) => {
  const isLgScreen = useMediaQuery<Theme>(
    theme => theme.breakpoints.up(1366) /*  {
    noSsr: true
    // is this relevant?? review later
  } */
  );

  /* 
  
  on custom providers the query for the media is done on the server side
    const smDown = processEnvServer
    ? req.cookies.PF_EXP?.toLowerCase() === "mobile"
    : useMediaQuery(`(max-width: ${BREAKPOINT_SM}px)`);
  
    would be good to know the criteria for this on akamai, how they decide what is mobile and what is desktop

    also we only have 2 distinctions or mobile or desktop, we should decide if we want the video set on server or the front end

  */

  const isSmScreen = useMediaQuery<Theme>(
    theme => theme.breakpoints.down("xs") /*  {
    noSsr: true
  } */
  );
  /* xs down means max-width 767 */

  const id =
    isSmScreen /* this is the main condition base on the server breakpoint */
      ? smId
      : processEnvServer
      ? lgId
      : !isLgScreen
      ? mdId
      : lgId; /* ? lgId : !isLgScreen : mdId  */

  return (
    <>
      <Helmet>
        <script data-wistia-clean-up-helmet="true" id="wistia-main-data">{`
            window._rh_wait_for_wistia_hero = true;
          `}</script>
        <script
          data-wistia-clean-up-helmet="true"
          id="wistia_script"
          src="https://fast.wistia.com/assets/external/E-v1.js"
          async
          {...({ fetchpriority: "high" } as any)}
        ></script>
      </Helmet>
      {smId && <WistiaScripts id={smId} media={smMediaId} />}
      {mdId && <WistiaScripts id={mdId} media={mdMediaId} />}
      {lgId && <WistiaScripts id={lgId} media={lgMediaId} />}

      <div style={{ position: "relative" }}>
        <h1
          data-debugging-wistia
          style={{ display: "none" }}
        >{`is sm down ${isSmScreen} and id ${id}`}</h1>
        <div style={{ position: "relative", width: "100%" }}>
          <div id={`wistia-${smId}`} style={{ padding: smPadding }}></div>
          <div id={`wistia-${mdId}`} style={{ padding: mdPadding }}></div>
          <div id={`wistia-${lgId}`} style={{ padding: lgPadding }}></div>
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: "100%",
              left: 0,
              position: "absolute",
              top: 0,
              width: "100%"
            }}
          >
            <div
              className={`wistia_embed wistia_async_${id}`}
              key={id}
              {...({ seo: "true" } as any)}
              videoFoam="true"
              style={{
                height: "100%",
                left: 0,
                position: "relative",
                width: "100%"
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

const WistiaHeroMemo = memo(WistiaHero);

const PreWistiaMain: FC<any> = props => {
  const { smId, smPadding, mdId, mdPadding, lgId, lgPadding } = props;
  return (
    <WistiaHeroMemo
      smId={smId}
      smPadding={smPadding}
      mdId={mdId}
      mdPadding={mdPadding}
      lgId={lgId}
      lgPadding={lgPadding}
    />
  );
};

export default PreWistiaMain;
