import React, { useState } from "react";
import memoize from "utils/memoize";

const CarouselImg = ({ imageStyle, imageContainerStyle, src, key }) => {
  const [showSkeleton, setShowSkeleton] = useState(true);

  const onLoad = () => {
    setShowSkeleton(false);
  };

  return (
    <>
      {showSkeleton && (
        <div
          id={key}
          data-testid={`carousel-image-skeleton-${key}`}
          className="h-full animate-pulse bg-[#F1EFEC] w-full absolute"
          style={{ display: showSkeleton ? "block" : "none" }}
        />
      )}

      <img
        id={key}
        data-testid={`carousel-image-${key}`}
        src={src}
        loading="lazy"
        className={`mix-blend-darken !object-contain`}
        style={{
          ...imageStyle,
          ...imageContainerStyle,
          visibility: !showSkeleton ? "visible" : "hidden",
          opacity: !showSkeleton ? 1 : 0,
          transition: !showSkeleton ? "opacity 3s" : ""
        }}
        onLoad={() => {
          onLoad();
        }}
      />
    </>
  );
};
export default memoize(CarouselImg);
