import React, { FC, Fragment, useCallback, useMemo } from "react";
import useState from "hooks/useState";
import useAppData from "hooks/useAppData";
import {
  Typography,
  Grid,
  useMediaQuery,
  Theme,
  Button
} from "utils/material-ui-core";
import InStockProductCard from "component-in-stock-product-card";
import PostalCodeDialog from "dialog-postal-code";
import { useQuery } from "@apollo/client";
import { queryInStockItems } from "graphql-client/queries/product-inStockItems";
import { queryOnSaleItems } from "graphql-client/queries/product-onSaleItems";
import RHDivider from "component-rh-divider";
import he from "he";
import { MonogramOrderProps } from "page-monogram-dialog";
import { CustomProductMapper } from "component-custom-product";
import InternationalPostalCodeDialog from "dialog-international-postal-code";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHUnderline from "component-rh-underline";
import { useCurrencyCode } from "hooks/useCurrencyCode";
import useUserPreferences from "hooks/useUserPreferences";
import useSite from "hooks/useSite";
import { useIsoCookies } from "hooks/useIsoCookies";
import StockContentSkeleton from "skeleton-stock-content";
import useLocale from "hooks-use-locale/useLocale";
import { usePageContent } from "customProviders/LocationProvider";
import { useRhUserAtomValue } from "hooks/atoms";
import { useInStockItemsWithAddedQuantity } from "./useInStockItemsWithAddedQuantity";

export interface InStockDialogContentProps {
  items: Maybe<InstockItem[]>;
  monogramOrder?: MonogramOrderProps;
  postalCode: string;
  productId: string;
  productPrice?: any;
  productName: string;
  salePriceLabel: string;
  isAuthenticated?: boolean;
  customInfo?: CustomProductMapper | SkuCustomizationType;
  maxOrderQty?: number;
  showViewMore?: boolean;
  isLoading?: boolean;
  contentLoading?: boolean;
  multiSkuAdditionalMessage?: string;
  isRHRValue?: boolean;

  loadMore?: () => void;
  metaProduct?: boolean;
}

const DEFAULT_ONSALE_ITEM_COUNT = 50;
const DEFAULT_INSTOCK_ITEM_COUNT = 50;
const DEFAULT_ONSALE_ITEM_PAGE_COUNT = 0;
const DEFAULT_ONSALE_ITEM_RESULT_PER_PAGE = 50;

export const Component: FC<InStockDialogContentProps> = ({
  items,
  monogramOrder,
  postalCode,
  productId,
  productName,
  salePriceLabel,
  productPrice,
  customInfo,
  isAuthenticated,
  maxOrderQty,
  showViewMore,
  isLoading,
  contentLoading,
  multiSkuAdditionalMessage,
  isRHRValue,
  loadMore,
  metaProduct
}) => {
  const env = useEnv();
  const internationalFlag = yn(env.FEATURE_INTERNATIONAL);
  const { pageContent } = usePageContent();

  const [postalCodeDialog, , setPostalCodeDialog] = useState({
    open: false
  });

  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH2", "rhBaseCaption"]
  });
  const postalCodeData = useIsoCookies(["pc"], true)?.pc || postalCode;

  const postalCodeAvailable = useMemo(() => {
    return (
      !!postalCodeData &&
      postalCodeData !== "null" &&
      postalCodeData !== "undefined" &&
      postalCodeData !== "false"
    );
  }, [postalCodeData]);

  // eslint-disable-next-line no-debugger
  // debugger;

  return (
    <Grid
      container
      spacing={2}
      className="!w-full"
      id={"component-in-stock-dialog-content"}
    >
      <Grid item xs={12}>
        <Typography
          className={typographyStyles.rhBaseH2}
          component="p"
          paragraph
        >
          {he.decode(productName)}
        </Typography>
      </Grid>
      {multiSkuAdditionalMessage && (
        <Grid item xs={12}>
          <Typography
            className={typographyStyles.rhBaseCaption}
            component="p"
            paragraph
            gutterBottom
          >
            {multiSkuAdditionalMessage}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {postalCodeAvailable ? (
          <Typography component="p" paragraph gutterBottom>
            {pageContent?.OPTIONS_AVAILABLE_FOR}{" "}
            <RHUnderline
              data-testid={"in-stock-link"}
              onClick={() => setPostalCodeDialog("open")(true)}
            >
              {postalCodeData}
            </RHUnderline>
          </Typography>
        ) : (
          <Typography
            className={typographyStyles.rhBaseCaption}
            component="p"
            paragraph
            gutterBottom
          >
            <RHUnderline
              data-testid={"in-stock-link-enter-location"}
              onClick={() => setPostalCodeDialog("open")(true)}
            >
              {pageContent?.ENTER_LOCATION}
            </RHUnderline>
          </Typography>
        )}
      </Grid>
      {contentLoading ? (
        <Grid item className="gridItem !w-full">
          <StockContentSkeleton data-testid="on-sale-content-loading" />
        </Grid>
      ) : (
        items?.map((item, index) => (
          <Fragment key={`InStockProduct-${item.fullSkuId}`}>
            <Grid item xs={12}>
              <InStockProductCard
                data={item}
                productId={productId}
                skuPrice={productPrice}
                customInfo={customInfo}
                monogramOrder={monogramOrder}
                productDisplayName={productName}
                salePriceLabel={salePriceLabel}
                isAuthenticated={isAuthenticated}
                maxOrderQty={maxOrderQty}
                isRHRValue={isRHRValue}
                metaProduct={metaProduct}
              />
            </Grid>

            <Grid item xs={12}>
              {index < items.length - 1 && <RHDivider />}
            </Grid>
          </Fragment>
        ))
      )}

      {postalCodeDialog ? (
        internationalFlag ? (
          <InternationalPostalCodeDialog
            open={postalCodeDialog.open}
            style={{ paddingBottom: smUp ? "0" : "60%" }}
            onClose={() => setPostalCodeDialog("open")(false)}
          />
        ) : (
          <PostalCodeDialog
            open={postalCodeDialog.open}
            style={{ paddingBottom: smUp ? "0" : "60%" }}
            onClose={() => setPostalCodeDialog("open")(false)}
          />
        )
      ) : null}

      {showViewMore && (
        <Grid container justify="center">
          <Grid item xs={6} sm={4} md={3}>
            <Button
              fullWidth
              disabled={isLoading}
              variant="contained"
              color="primary"
              className={
                "text-white bg-black hover:bg-gray-1 hover:border-gray-1"
              }
              onClick={loadMore}
            >
              {pageContent?.LOAD_MORE}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const OnSaleDialogContent: FC<{
  monogramOrder?: MonogramOrderProps;
  productId: string;
  productName: string;
  postalCode?: string;
  salePriceLabel: string;
  productPrice?: any;
  isAuthenticated?: boolean;
  customInfo?: CustomProductMapper | SkuCustomizationType;
  userType?: string;
  isRHR?: boolean;
  multiSkuAdditionalMessage?: string;
  metaProduct?: boolean;
}> = props => {
  const env = useEnv();
  const { app } = useAppData();
  const siteId = useSite();
  const pc = useIsoCookies(["pc"]).pc;
  const postalCode = props.postalCode || app.postalCode || pc || "";
  const locale = useLocale();

  const { previousState } = useUserPreferences();
  const { userType } = useRhUserAtomValue();

  const [itemsFetched, setItemsFetched] = useState(DEFAULT_ONSALE_ITEM_COUNT);
  const [itemsFetchedPage, setItemsFetchedPage] = useState(
    DEFAULT_ONSALE_ITEM_PAGE_COUNT
  );
  const [resultPerPage, setResultPerPage] = useState(
    DEFAULT_ONSALE_ITEM_RESULT_PER_PAGE
  );
  const [isLoading, setIsLoading] = useState(false);

  const currencyCode = useCurrencyCode({
    country: previousState.country,
    postalCode,
    userType
  });

  const {
    data: { onsaleItems } = {} as Query,
    fetchMore,
    loading: saleItemLoading
  } = useQuery<Query>(queryOnSaleItems, {
    variables: {
      productId: props.productId,
      postalCode,
      filter: "saleSkipInventory",
      monogram: props?.monogramOrder,
      userType: props.userType,
      currencyCode,
      siteId,
      locale,
      fromCount: "0",
      toCount: DEFAULT_ONSALE_ITEM_COUNT.toString(),
      nextgenDriven: true,
      page: "0",
      resultsPerPage: DEFAULT_ONSALE_ITEM_RESULT_PER_PAGE.toString()
    }
  });

  const items = useInStockItemsWithAddedQuantity(onsaleItems?.instockItems);

  const showViewMore = onsaleItems?.showViewMore === "true";

  const handleLoadMore = useCallback(async () => {
    setIsLoading(true);
    await fetchMore({
      variables: {
        fromCount: `${itemsFetched + 1}`,
        toCount: `${DEFAULT_ONSALE_ITEM_COUNT + itemsFetched}`,
        page: `${itemsFetchedPage + 1}`,
        resultPerPage: `${DEFAULT_ONSALE_ITEM_RESULT_PER_PAGE + resultPerPage}`
      },
      updateQuery: (previousQueryResult, { fetchMoreResult }) =>
        ({
          ...fetchMoreResult,
          onsaleItems: {
            ...fetchMoreResult?.onsaleItems,
            instockItems: [
              ...(previousQueryResult?.onsaleItems?.instockItems ?? []),
              ...(fetchMoreResult?.onsaleItems?.instockItems ?? [])
            ]
          }
        } as Query)
    });
    setItemsFetchedPage(itemsFetchedPage + 1);
    setItemsFetched(itemsFetched + DEFAULT_ONSALE_ITEM_COUNT);
    setIsLoading(false);
  }, [fetchMore, itemsFetched]);

  return (
    <Component
      productId={props?.productId}
      isRHRValue={props?.isRHR}
      productName={props?.productName}
      postalCode={postalCode}
      items={items}
      monogramOrder={props?.monogramOrder}
      salePriceLabel={props.salePriceLabel}
      productPrice={props?.productPrice}
      customInfo={props?.customInfo}
      isAuthenticated={props?.isAuthenticated}
      showViewMore={showViewMore}
      isLoading={isLoading}
      loadMore={handleLoadMore}
      contentLoading={saleItemLoading}
      multiSkuAdditionalMessage={props?.multiSkuAdditionalMessage}
      metaProduct={props?.metaProduct}
    />
  );
};

export const InStockDialogContent: FC<{
  productId: string;
  metaProduct?: boolean;
  productPrice?: any;
  productName: string;
  postalCode?: string;
  salePriceLabel: string;
  isAuthenticated?: boolean;
  monogramOrder: MonogramOrderProps;
  customInfo?: CustomProductMapper | SkuCustomizationType;
  userType?: string;
  maxOrderQty?: number;
  multiSkuAdditionalMessage?: string;
  isRHR?: boolean;
}> = props => {
  const env = useEnv();
  const { app } = useAppData();
  const postalCode = props.postalCode || app.postalCode || "";
  const {
    previousState: { country }
  } = useUserPreferences();

  const [itemsFetched, setItemsFetched] = useState(DEFAULT_INSTOCK_ITEM_COUNT);
  const [itemsFetchedPage, setItemsFetchedPage] = useState(
    DEFAULT_ONSALE_ITEM_PAGE_COUNT
  );
  const [resultPerPage, setResultPerPage] = useState(
    DEFAULT_ONSALE_ITEM_RESULT_PER_PAGE
  );
  const [isLoading, setIsLoading] = useState(false);
  const locale = useLocale();

  const currencyCode = useCurrencyCode({
    postalCode,
    country,
    userType: props?.userType
  });

  const siteId = useSite();

  const {
    data: { instockItems: instockItemData } = {} as Query,
    fetchMore,
    loading: inStockItemLoading
  } = useQuery<Query>(queryInStockItems, {
    variables: {
      productId: props.productId,
      postalCode,
      filter: app.saleContextFilter,
      monogram: props?.monogramOrder,
      userType: props.userType,
      currencyCode,
      siteId,
      fromCount: "0",
      locale,
      toCount: DEFAULT_INSTOCK_ITEM_COUNT.toString(),
      nextgenDriven: true,
      page: "0",
      resultsPerPage: DEFAULT_ONSALE_ITEM_RESULT_PER_PAGE.toString()
    },
    skip: !postalCode
  });

  const items = useInStockItemsWithAddedQuantity(instockItemData?.instockItems);

  const showViewMore = instockItemData?.showViewMore === "true";

  const handleLoadMore = useCallback(async () => {
    setIsLoading(true);
    await fetchMore({
      variables: {
        fromCount: `${itemsFetched + 1}`,
        toCount: `${DEFAULT_INSTOCK_ITEM_COUNT + itemsFetched}`,
        page: `${itemsFetchedPage + 1}`,
        resultPerPage: `${DEFAULT_ONSALE_ITEM_RESULT_PER_PAGE + resultPerPage}`
      },
      updateQuery: (previousQueryResult, { fetchMoreResult }) =>
        ({
          ...fetchMoreResult,
          instockItems: {
            ...fetchMoreResult?.instockItems,
            instockItems: [
              ...(previousQueryResult?.instockItems?.instockItems ?? []),
              ...(fetchMoreResult?.instockItems?.instockItems ?? [])
            ]
          }
        } as Query)
    });
    setItemsFetchedPage(itemsFetchedPage + 1);
    setItemsFetched(itemsFetched + DEFAULT_INSTOCK_ITEM_COUNT);
    setIsLoading(false);
  }, [fetchMore, itemsFetched]);

  return (
    <Component
      productId={props?.productId}
      productName={props?.productName}
      monogramOrder={props?.monogramOrder}
      postalCode={postalCode}
      items={items}
      salePriceLabel={props.salePriceLabel}
      productPrice={props?.productPrice}
      customInfo={props?.customInfo}
      isAuthenticated={props?.isAuthenticated}
      maxOrderQty={props?.maxOrderQty}
      showViewMore={showViewMore}
      isLoading={isLoading}
      loadMore={handleLoadMore}
      contentLoading={inStockItemLoading}
      multiSkuAdditionalMessage={props?.multiSkuAdditionalMessage}
      isRHRValue={props.isRHR}
      metaProduct={props?.metaProduct}
    />
  );
};
