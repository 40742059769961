import React, { FC, HTMLAttributes, ReactElement } from "react";
import {
  Typography,
  Theme,
  Collapse,
  Grid,
  Container,
  useTheme,
  makeStyles,
  createStyles,
  Backdrop
} from "utils/material-ui-core";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import RHDropdownIcon from "icon-dropdown";
import { RHMenuLinkProps } from "../RHMenuLinkList";
import useToggle from "hooks/useToggle";
import { useEnv } from "hooks/useEnv";
import { useCountry } from "hooks/useCountry";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import { handleKeyboardPressWithEvent } from "utils/accessibility";

interface RHSectionLinkListProps {
  title: string | ReactElement;
  links: RHMenuLinkProps[];
  style?: React.CSSProperties;
  collapseOnLinkClick?: boolean;
  accessibilityProps?: HTMLAttributes<HTMLElement>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor:
        theme.palette.background.default || theme.palette.common.white
    },
    title: {
      textTransform: "uppercase",
      fontSize: theme.typography.pxToRem(11),
      letterSpacing: 1,
      cursor: "pointer"
    },
    link: {
      color: "#666",
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: theme.typography.pxToRem(11),
      letterSpacing: 1
    },
    activeLink: {
      color: theme.palette.common.black
    },
    collapse: {
      backgroundColor: theme.palette.common.white,
      position: "absolute",
      width: "100%",
      top: "100%",
      zIndex: -1,
      boxShadow: theme.shadows[2]
    },
    linkContainer: {
      margin: "auto",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4)
    },
    rhrCollapse: {
      backgroundColor:
        theme.palette.background.default || theme.palette.common.white,
      position: "absolute",
      width: "100%",
      top: "100%",
      zIndex: -1,
      boxShadow: theme.shadows[2]
    }
  })
);

const RHSectionLinkList: FC<RHSectionLinkListProps> = ({
  title,
  links,
  style,
  collapseOnLinkClick,
  accessibilityProps
}) => {
  const [status, toggle] = useToggle();
  const classes = useStyles();
  const theme = useTheme();
  const { FEATURE_RHR, FEATURE_BG_GREY } = useEnv();
  const containerZIndex = FEATURE_RHR
    ? theme.zIndex.appBar - 4
    : theme.zIndex.appBar;
  const country = useCountry();

  return (
    <>
      <Backdrop
        open={status}
        style={{ position: "fixed", zIndex: 1 }}
        onClick={toggle}
      />
      <Grid container style={{ zIndex: containerZIndex, position: "relative" }}>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          classes={{ root: classes.root }}
          style={style}
          onClick={toggle}
          tabIndex={0}
          onKeyDown={handleKeyboardPressWithEvent(toggle)}
          aria-expanded={status}
          {...accessibilityProps}
        >
          <Grid item component={Container} xs={12} lg={10} maxWidth="xl">
            {typeof title === "string" && (
              <Grid container item alignItems="center">
                <Typography
                  variant="subtitle1"
                  display="inline"
                  classes={{ root: classes.title }}
                >
                  {title}
                </Typography>
                <RHDropdownIcon
                  style={{
                    fontSize: theme.typography.pxToRem(10),
                    width: "10px !important",
                    height: "10px !important",
                    marginLeft: theme.spacing(1),
                    transition: "all .2s ease-in-out",
                    transform: `rotate(${status ? 180 : 0}deg)`
                  }}
                />
              </Grid>
            )}
            {typeof title !== "string" && title}
          </Grid>
        </Grid>
        <Collapse
          in={status}
          className={FEATURE_BG_GREY ? classes.rhrCollapse : classes.collapse}
        >
          <Grid
            item
            component={Container}
            xs={12}
            lg={10}
            maxWidth="xl"
            classes={{ root: classes.linkContainer }}
          >
            <Grid container direction="column">
              {links
                ?.filter(e => !e.hide)
                .map(({ url, content, customClasses, activeClassName }) => {
                  if (
                    (url?.indexOf("GiftRegistry") >= 0 ||
                      url?.indexOf("wish-list") >= 0) &&
                    checkCountryIsEu({ country })
                  ) {
                    return <></>;
                  }
                  return (
                    <NavLink
                      key={`${url}:${content}`}
                      className={classNames([classes.link, customClasses])}
                      activeClassName={classNames([
                        classes.activeLink,
                        activeClassName
                      ])}
                      style={{
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1)
                      }}
                      to={url}
                      onClick={collapseOnLinkClick ? toggle : null}
                    >
                      {content}
                    </NavLink>
                  );
                })}
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </>
  );
};

export default RHSectionLinkList;
