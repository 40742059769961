import React from "react";
import {
  Container,
  Typography,
  Theme,
  makeStyles,
  createStyles
} from "utils/material-ui-core";
import classNames from "classnames";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHImage from "component-rh-image";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerTitle: {
      //using inline style due to SSR styling issue
      textTransform: "uppercase"
    },
    headerTitleFont: {
      color: "#000000 !important",
      fontSize: "2.6666666666666665rem !important",
      fontFamily: "RHSans-UltraThin, Helvetica, Arial !important",
      lineHeight: "2.6666666666666665rem !important",
      letterSpacing: "-0.02em !important"
    }
  })
);

export interface PageHeaderProps {
  id?: string;
  title: string | JSX.Element | (() => JSX.Element);
  subtitle?: string | JSX.Element;
  rootStyle?: React.CSSProperties | null | undefined;
  titleStyle?: React.CSSProperties | null | undefined;
  subtitleStyle?: React.CSSProperties | null | undefined;
  className?: string;
  sale?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  id,
  title,
  subtitle,
  rootStyle,
  titleStyle,
  subtitleStyle,
  children,
  className,
  sale
}) => {
  const classes = useStyles();
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH1", "rhBaseH3", "rhSaleBaseH1"]
  });
  const bannerImageUrl =
    "https://media.restorationhardware.com/is/image/rhis/SaleLP_Banner?wid=2000";

  return (
    <Container
      id={id || "component-page-header"}
      className={classNames("text-center pb-[14.4px] xl:pb-[48px]", className)}
      style={rootStyle ?? {}}
    >
      {typeof title === "function" ? (
        <Typography
          variant="h1"
          className={classNames([typographyStyles.rhBaseH1])}
          style={{ ...titleStyle, textTransform: "uppercase" }}
        >
          {title?.()}
        </Typography>
      ) : sale ? (
        // <Typography
        //   style={{ ...titleStyle }}
        //   dangerouslySetInnerHTML={{ __html: title as string }}
        // />
        <RHImage
          data-id="component-salepage-banner"
          src={bannerImageUrl}
          alt="Sale Page Banner"
        />
      ) : (
        <Typography
          variant="h1"
          className={classNames([typographyStyles.rhBaseH1])}
          style={{ ...titleStyle, textTransform: "uppercase" }}
          dangerouslySetInnerHTML={{ __html: title as string }}
        />
      )}

      {subtitle && (
        <Typography
          className={sale ? "" : typographyStyles.rhBaseH3}
          style={{ ...subtitleStyle }}
          dangerouslySetInnerHTML={{ __html: subtitle as string }}
        />
      )}
      {children}
    </Container>
  );
};

export default PageHeader;
