export const sanitizedImages = (
  images: string[],
  productDetails?: any
): string[] => {
  const SPLIT = "?";
  if (productDetails?.alternateImages?.length) {
    const sanitizeImgs = images
      ?.filter(Boolean)
      ?.map((url: string) => {
        const [path, search = ""] = url?.split(SPLIT);
        return { path, search };
      })
      .reduce((acc, curr) => {
        if (acc.find((d: any) => d?.path === curr?.path)) {
          acc.push({ ...curr, repeated: true });
        } else {
          acc.push({ ...curr, repeated: false });
        }
        return acc;
      }, [] as { path: string; search: string; repeated: boolean }[])
      ?.filter(d => !d?.repeated)
      ?.map(d =>
        [d?.path, d?.search]
          ?.filter(Boolean)
          ?.join(SPLIT)
          ?.replace(/\$.*\$/, "")
      );
    return sanitizeImgs;
  } else {
    return [productDetails?.imageUrl];
  }
};

const arrowDistanceToEdge = "0px";
const arrowPadding = "0px";
const arrowSide = "15px";

export const getArrowStyles = (arrowSideKey: "right" | "left") => ({
  carouselArrowGridStyles: {
    [arrowSideKey]: arrowDistanceToEdge,
    padding: arrowPadding
  },
  carouselArrowStyles: {
    width: arrowSide,
    fontSize: arrowSide
  }
});

export const getPresetMap = (isConciergeRHRImage, isRHRImage) => ({
  xlUp: {
    "col-1": "",
    "col-2": isConciergeRHRImage
      ? "$CON_IS_768$"
      : isRHRImage
      ? "$np-fullwidth-md$"
      : "$np-fullwidth-lg$",
    "col-3": isConciergeRHRImage
      ? "$CON_IS_768$"
      : isRHRImage
      ? "$np-fullwidth-sm$"
      : "$np-fullwidth-md$"
  },
  lgUp: {
    "col-1": "",
    "col-2": isConciergeRHRImage
      ? "$CON_IS_768$"
      : isRHRImage
      ? "$np-fullwidth-sm$"
      : "$np-fullwidth-md$",
    "col-3": isConciergeRHRImage
      ? "$CON_IS_375$"
      : isRHRImage
      ? "$np-fullwidth-xs$"
      : "$np-fullwidth-sm$"
  },
  mdUp: {
    "col-1": "",
    "col-2": isConciergeRHRImage
      ? "$CON_IS_768$"
      : isRHRImage
      ? "$np-common-md$"
      : "$np-common-lg$",
    "col-3": isConciergeRHRImage
      ? "$CON_IS_375$"
      : isRHRImage
      ? "$np-common-sm$"
      : "$np-common-md$"
  },
  smUp: {
    "col-1": "",
    "col-2": isConciergeRHRImage
      ? "$CON_IS_375$"
      : isRHRImage
      ? "$np-common-sm$"
      : "$np-common-md$",
    "col-3": isConciergeRHRImage
      ? "$CON_IS_375$"
      : isRHRImage
      ? "$np-common-xs$"
      : "$np-common-sm$"
  },
  xsUp: {
    "col-1": isConciergeRHRImage
      ? "$CON_IS_375$"
      : isRHRImage
      ? "$np-common-xs$"
      : "$np-common-sm$",
    "col-2": isConciergeRHRImage
      ? "$CON_IS_375$"
      : isRHRImage
      ? "$np-common-xs$"
      : "$np-common-xs$",
    "col-3": ""
  },
  rhrThreeK: "$highres-zoom-3K-WRH$",
  threeK: "$np-fullwidth-xl$"
});
