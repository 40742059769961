import React, { FC, ReactNode, MouseEvent } from "react";
import { Typography, useTheme, Divider } from "utils/material-ui-core";
import RHLink, { RHLinkProps } from "component-rh-link";

export interface RHLinkListAnchorProps
  extends Omit<RHLinkProps, "title" | "variant" | "onClick"> {
  title: ReactNode;
  variant?: "tall" | "short";
  divider?: "none" | "top" | "bottom" | "both";
  endIcon?: ReactNode;
  productId?: string | undefined;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  "data-testid"?: string;
  minHeight?: number;
}

export const RHLinkListAnchor: FC<RHLinkListAnchorProps> = ({
  children,
  title,
  variant,
  divider,
  endIcon,
  productId,
  minHeight,
  ...rest
}) => {
  const theme = useTheme();
  const styles = {
    minHeight: minHeight ? minHeight : theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer"
  } as React.CSSProperties;

  return (
    <RHLink underline="none" {...rest} data-testid={rest["data-testid"]}>
      {(divider === "top" || divider === "both") && <Divider />}
      <div
        id={productId ? `js-instock-button-${productId}` : undefined}
        className={productId ? "js-show-instock" : undefined}
        style={styles}
      >
        <Typography variant={variant === "tall" ? "h4" : "h5"}>
          {title}
        </Typography>
        {endIcon}
      </div>
      {children}
      {(divider === "bottom" || divider === "both") && <Divider />}
    </RHLink>
  );
};

RHLinkListAnchor.defaultProps = {
  variant: "tall",
  divider: "top"
};

export default RHLinkListAnchor;
