import React, { FC } from "react";
import { Grid } from "utils/material-ui-core";
import RHArrowIcon from "icon-arrow";
import RHLinkListItem from "../RHLinkListItem";
import { usePageContent } from "customProviders/LocationProvider";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { BE, DE } from "utils/constants";
import { VIEW_AVAILABLE_OPTIONS } from "@RHCommerceDev/page-level-resources-schemas/address-book.json";

export interface RHLinkListSwatchAnchorProps {
  onClick: () => void;
  template: string;
  stockedSwatchesLength: number;
  groupMaterial: string;
  customSwatchesLength: number;
  triggerSwatchImage: string;
  borderType?: {
    top?: boolean;
    bottom?: boolean;
  };
}

const esses = ["FINISH"];
const getPluralSuffix = (term: string = ""): string =>
  esses.includes(term.toUpperCase()) ? "ES" : "S";

const buildTerm = (term: string = "", amount: number = 1): string =>
  `${term}${amount > 1 ? getPluralSuffix(term) : ""}`;

export const RHLinkListSwatchAnchor: FC<RHLinkListSwatchAnchorProps> = ({
  onClick,
  template,
  stockedSwatchesLength,
  groupMaterial,
  customSwatchesLength,
  triggerSwatchImage,
  borderType
}) => {
  const { pageContent } = usePageContent();
  const country = getCountryFromUrl();
  const { raw: language } = getLanguageFromUrl();

  return (
    <RHLinkListItem
      borderType={borderType}
      data-testid="swatch-dialog-opener"
      onClick={onClick}
      title={
        (country === DE && language === "de") ||
        (country === BE && language !== "en")
          ? pageContent?.VIEW_AVAILABLE_OPTIONS ?? VIEW_AVAILABLE_OPTIONS
          : template === "panel"
          ? `${pageContent?.SELECT_FROM} ${stockedSwatchesLength} ${buildTerm(
              groupMaterial,
              stockedSwatchesLength
            )}`
          : `${pageContent?.SELECT_FROM} ${
              stockedSwatchesLength
                ? `${stockedSwatchesLength} ${pageContent?.IN_STOCK_ONLY}`
                : ""
            }${
              stockedSwatchesLength && customSwatchesLength
                ? ` ${pageContent?.AND} `
                : ""
            }${
              customSwatchesLength
                ? `${customSwatchesLength} ${pageContent?.SPECIAL_ORDER}`
                : ""
            } ${buildTerm(
              groupMaterial,
              stockedSwatchesLength + customSwatchesLength
            )}`
      }
      Icon={<RHArrowIcon />}
      labelChildren={
        <>
          {triggerSwatchImage && (
            <Grid
              container
              item
              xs={12}
              sm={6}
              onClick={onClick}
              className={"[&>img]:block"}
              dangerouslySetInnerHTML={{
                __html: triggerSwatchImage
              }}
            />
          )}
        </>
      }
    />
  );
};

export default RHLinkListSwatchAnchor;
