import React, { useState, useRef, useEffect, useCallback } from "react";

import Arrows from "./Arrows";
import DotButton from "./DotButton";
import memoize from "utils/memoize";
import { processEnvServer } from "hooks/useSsrHooks";
import { isMobileOrTablet } from "hooks/useDeviceOrientation";
import RHImageV3 from "@RHCommerceDev/component-rh-image-v3";
const styles = {
  emblaContainer: {},
  skeletonLoader: {
    backgroundColor: "#e0e0e0",
    animation: "pulse 1.5s infinite ease-in-out"
  },
  "@keyframes pulse": {
    "0%, 100%": {
      opacity: 1
    },
    "50%": {
      opacity: 0.5
    }
  }
};
const ImageCarousel = ({
  slides = [],
  imageContainerStyle = {} as React.CSSProperties,
  imageStyle,
  presetImage,
  loop = true,
  imageFlip = false,
  hideArrows = false,
  isClicked,
  setIsClicked,
  hideDots = false // default carousel don't hide dots,
}) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isAutoplay, setIsAutoplay] = useState(false);
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const carouselRef = useRef<HTMLDivElement>(null);
  const slideCount = slides.length;
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);
  const [previousIndex, setPreviousIndex] = useState(0); // Track the previous index
  const [reverseCount, setReverseCount] = useState(0);
  const [reverseIndex, setReverseIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (isClicked) {
      setTransitionEnabled(false);
      setCurrentIndex(1);
      setIsClicked(false);
    }
  }, [isClicked, slides]);

  useEffect(() => {
    const img = new Image();
    const handleLoad = () => {
      setIsLoaded(true);
      setHasError(false);
    };
    const handleError = () => {
      setIsLoaded(false);
      setHasError(true);
    };
    img.src = slides[0];
    img.onload = handleLoad;
    img.onerror = handleError;
    // Clean up event listeners when the component unmounts
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [slides[0]]);

  const goToNextSlide = useCallback(e => {
    e?.preventDefault();
    setCurrentIndex(prevIndex => {
      setPreviousIndex(prevIndex);
      return prevIndex + 1;
    });
  }, []);

  const goToPrevSlide = useCallback(e => {
    e?.preventDefault();
    setCurrentIndex(prevIndex => {
      setPreviousIndex(prevIndex);
      return prevIndex - 1;
    });
  }, []);

  const startAutoplay = useCallback(() => {
    setIsAutoplay(true);
  }, []);

  const stopAutoplay = useCallback(() => {
    setIsAutoplay(false);
  }, []);
  useEffect(() => {
    if (!isAutoplay) return;
    const intervalId = setInterval(e => {
      goToNextSlide(e);
    }, 1000); // Adjust time interval as needed
    return () => clearInterval(intervalId);
  }, [isAutoplay, goToNextSlide]);

  useEffect(() => {
    if (imageFlip && !isMobileOrTablet) {
      if (carouselRef.current) {
        carouselRef.current.addEventListener("mouseenter", startAutoplay);
        carouselRef.current.addEventListener("mouseleave", stopAutoplay);
      }
      return () => {
        if (carouselRef.current && !isMobileOrTablet) {
          carouselRef.current.removeEventListener("mouseenter", startAutoplay);
          carouselRef.current.removeEventListener("mouseleave", stopAutoplay);
        }
      };
    }
  }, [imageFlip, startAutoplay, stopAutoplay]);

  const scrollTo = useCallback((e, index: number) => {
    e.preventDefault();
    setCurrentIndex(prev => {
      setPreviousIndex(prev);
      return index;
    });
  }, []);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchEndX(null);
  };
  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStartX || !touchEndX) return;
    const diff = touchStartX - touchEndX;
    if (diff > 50) {
      goToNextSlide({ preventDefault: () => {} });
    } else if (diff < -50) {
      goToPrevSlide({ preventDefault: () => {} });
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };
  useEffect(() => {
    if (currentIndex === slideCount + 1) {
      setTransitionEnabled(false);
      setCurrentIndex(prev => {
        setPreviousIndex(prev);
        return 1;
      });
    } else if (currentIndex === 0) {
      setTransitionEnabled(false);
      setCurrentIndex(prev => {
        setPreviousIndex(prev);
        return slideCount;
      });
    } else {
      setTransitionEnabled(true);
    }
  }, [currentIndex, slideCount]);

  useEffect(() => {
    if (previousIndex > currentIndex - 1) {
      setReverseCount(reverseCount + 1);
    } else {
      setReverseCount(0);
      setReverseIndex(0);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (reverseCount == 1) {
      setReverseIndex(previousIndex);
    }
  }, [reverseCount]);

  useEffect(() => {
    if (reverseCount == 1) {
      setReverseIndex(previousIndex);
    }
  }, [reverseCount]);

  if (slides.length === 0) return null;

  const getVisibleDots = () => {
    const totalDots = slides.length;
    if (totalDots <= 5) {
      return slides.map((_, index) => index);
    }
    return Array.from({ length: slides.length }, (_, i) => i);
  };

  return (
    <>
      {!processEnvServer && !isLoaded && (
        <div
          id="img-carousal-skeleton"
          className="flex justify-center h-full relative align-center"
          style={{
            height: imageContainerStyle?.maxHeight || 250,
            minHeight: 100,
            ...styles.skeletonLoader
          }}
        >
          <div className="skeleton-loader w-full h-full bg-gray-100 animate-pulse">
            {" "}
          </div>
        </div>
      )}
      {isLoaded && (
        <div className="flex items-end" style={{ ...imageContainerStyle }}>
          <div
            className={`embla overflow-hidden w-full relative group/item group block  max-h-[${
              imageStyle?.maxHeight
            }] h-auto ${isMobileOrTablet ? "inline-table" : ""}`}
            ref={carouselRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              className="embla__container flex h-full items-center"
              style={{
                ...styles.emblaContainer,

                transform: `translateX(-${(currentIndex - 1) * 100}%)`,
                transition: transitionEnabled
                  ? "transform 0.5s ease-in-out"
                  : "none"
              }}
            >
              {[...slides].map((item, idx) => (
                <div
                  key={idx}
                  className="embla__slide min-w-0 flex-[0_0_100%] xs:pt-4"
                  style={{
                    height: item ? imageStyle?.height || "auto" : 250,
                    justifyContent: "space-around"
                  }}
                >
                  <RHImageV3
                    src={presetImage ? presetImage(item) : item}
                    alt={`carousel-item-${idx}`}
                    className="w-auto h-full object-contain max-w-full"
                    loading="lazy"
                    imageContainerStyle={{
                      ...imageStyle,
                      justifyContent: "space-around",
                      width: "100%",
                      height: "100%"
                    }}
                    style={{
                      maxHeight: imageStyle?.maxHeight || "100%",
                      height: item ? imageStyle?.maxHeight || "auto" : 250
                    }}
                    lazyLoadRequired={true}
                  />
                </div>
              ))}
            </div>
            {/* Action handlers */}
            <div className="embla__controls flex justify-center align-center items-center">
              {/* Arrows */}
              {!isMobileOrTablet && (
                <Arrows
                  scrollPrev={goToPrevSlide}
                  scrollNext={goToNextSlide}
                  index={currentIndex}
                  slides={slideCount}
                />
              )}
              {/* Dots */}
              {isMobileOrTablet && (
                <div
                  className={`embla__dots flex ${
                    slides.length <= 4 ? "justify-center" : "justify-start"
                  } items-center overflow-hidden w-[50px] h-[18.5px]`}
                >
                  <div
                    className={`flex transition-transform duration-300  `}
                    style={{
                      transform: `translateX(-${
                        previousIndex - 1 <= currentIndex - 1
                          ? currentIndex - 1 >= 4 &&
                            currentIndex - 1 < slides.length - 1
                            ? (currentIndex - 4) * 10
                            : currentIndex - 1 >= slides.length - 1
                            ? (currentIndex - 5) * 10
                            : 0
                          : currentIndex - 1 <= slides.length - 1 &&
                            currentIndex - 1 != 0
                          ? reverseCount >= 3
                            ? (currentIndex - 2) * 10
                            : currentIndex - 1 >= slides.length - 3
                            ? (reverseIndex - 5) * 10
                            : reverseIndex >= 3 && slides.length > 5
                            ? (reverseIndex - 4) * 10
                            : 0
                          : currentIndex - 1 == 0
                          ? 0
                          : (currentIndex - 5) * 10
                      }px)`
                    }}
                  >
                    {slides.length > 1 &&
                      getVisibleDots().map(index => (
                        <DotButton
                          key={index}
                          selected={index === currentIndex - 1}
                          selectedIndex={currentIndex - 1}
                          onClick={e => scrollTo(e, index)}
                          index={index}
                          totalDots={slides.length}
                          reverseIndex={reverseIndex - 1}
                          isReverse={previousIndex > currentIndex}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default memoize(ImageCarousel);
