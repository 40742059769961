import React, { FC } from "react";
import {
  EInStockProductCardActionsProps,
  EInStockProductCardActions
} from "./InStockProductCardActions";

export const InStockProductCardActions: FC<EInStockProductCardActionsProps> = ({
  ...rest
}) => {
  return <EInStockProductCardActions {...rest} />;
};

export type InStockProductCardActionsProps = EInStockProductCardActionsProps;
export default InStockProductCardActions;
