import React, { FC, useState, MouseEvent } from "react";
import RHArrowIcon from "icon-arrow";
import RHLinkListItem, { RHLinkListItemProps } from "../RHLinkListItem";
import RHDialogTitle from "component-rh-dialog-title";
import { DialogContent } from "utils/material-ui-core";
import Drawer from "component-drawer";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

export interface RHLinkListDrawerProps extends RHLinkListItemProps {
  dialogHeader: string;
  productId?: string;
}

export const RHLinkListDrawer: FC<RHLinkListDrawerProps> = ({
  dialogHeader,
  children,
  onClick,
  productId,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOnClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(evt);
    toggleOpen();
  };
  const toggleOpen = () => {
    setOpen(!open);
  };
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  return (
    <>
      <RHLinkListItem
        {...rest}
        labelOnClick={handleOnClick}
        Icon={<RHArrowIcon />}
        productId={productId}
      />
      <DrawerComponent anchor="right" open={open}>
        <RHDialogTitle title={dialogHeader} onBack={toggleOpen} />
        <DialogContent style={{ overflow: "auto" }}>{children}</DialogContent>
      </DrawerComponent>
    </>
  );
};

RHLinkListDrawer.defaultProps = {};

export default RHLinkListDrawer;
