import classNames from "classnames";
import Price from "component-price-range-display/Price";
import PriceLabel from "component-price-range-display/PriceLabel";
import {
  calculatePrices,
  convertProductPriceRangeDisplayToPriceRange,
  getUserTypeLabel,
  getUserTypePrice,
  PickProductPriceRangeDisplay
} from "component-price-range-display/utils/convertProductPriceRangeDisplay";
import { usePageContent } from "customProviders/LocationProvider";
import { useRhUserAtomValue } from "hooks/atoms";
import { useEnv } from "hooks/useEnv";
import { useIsoCookies } from "hooks/useIsoCookies";
import useParams from "hooks/useParams";
import useTypographyStyles from "hooks/useTypographyStyles";
import React, { CSSProperties, FC, useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import {
  countries,
  countryNameMapper,
  currencySymbolToCurrencyCodeMapper
} from "resources/countries-config.json";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL
} from "utils/constants";
import { getCookie } from "utils/cookies";
import { formatCurrencyWithoutCS } from "utils/currencyHelpers";
import getCountryFromUrl from "utils/getCountryFromUrl";
import {
  ButtonBase,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery
} from "utils/material-ui-core";
import memoize from "utils/memoize";
import { parseUserContextCookie } from "utils/parseUserContextCookie";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";

export interface RHPriceRangeDisplayProps {
  data: PickProductPriceRangeDisplay &
    Pick<
      ProductPriceRangeDisplay,
      | "overridePriceLabel"
      | "hasOnlyOneSku"
      | "currencyLabel"
      | "currencySymbol"
      | "priceMessage"
      | "priceMessagePrice"
    >;
  isSkuPrice?: number;
  onSale?: boolean;
  variant?: "small" | "default" | "medium" | "print";
  /** @deprecated not used anymore. color should always be body2 blk  */
  color?: string;
  /** @deprecated not used anymore. colorSale should always be body2 blk  */
  colorSale?: string;
  topLabel?: string;
  display?: "inline" | "block";
  currency?: Maybe<string>;
  showSaleMessage?: boolean;
  salePriceLabel?: string;
  metaProductComponent?: boolean;
  emptyProduct?: boolean;
  memberPrice?: number;
  skulSalePrice?: SkuSalePrice;
  isStockedFilterActive?: boolean;
  inStockPgPrice?: SkuResponsePriceInfo;
  isMetaProduct?: boolean;
  isSkuLevel?: boolean;
  centerAlignFlag?: boolean;
  saleMemberPrice?: number;
  nextGenDrivenOnSale?: boolean;
  onViewSelectItemsOnSaleClick?: () => void;
  tradePrice?: number;
  skuOnSalePercentage?: number;
  lowestPgSkuMemberPrice?: number;
  lowestPgSkuSalePrice?: number;
  isCustomProduct?: boolean;
  productId?: string;
  drivedStyles?: CSSProperties;
  addOnV2Pricing?: boolean;
  isSaleFilterEnabled?: boolean;
  viewPriceOnly?: boolean;
  showSalePriceRange?: boolean;
  isSwatchPanelOpen?: boolean;
  hideSalePrice?: boolean;
  configureLabel?: string;
  hideUpSellSale?: boolean;
  isNewPDPLayout?: boolean;
  isNewPDPButton?: boolean;
  showGiftCardPrice?: boolean;
  isYaml?: boolean;
  showMembershipProductPrice?: boolean;
  isFabricPricing?: boolean;
}

const env = useEnv();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    priceRangeContainer: {
      "& > div": { display: "contents" },
      "& p": {
        fontWeight: "400"
      },
      "& p[data-testid='price-for-view-sale']": {
        fontWeight: "100 !important"
      },
      "& p[data-testid='price-view-for-new-pdplayout']": {
        fontFamily: "RHSans-Light !important",
        fontSize: "13px !important",
        fontWeight: "200 !important",
        lineHeight: "110% !important",
        letterSpacing: "0.26px !important",
        textDecorationLine: "underline",
        textTransform: "uppercase",
        paddingTop: "0px !important"
      },
      "& p.newPDPViewSalePriceLabel": {
        color: "#CA2022 !important",
        fontFamily: "RHSans-Light !important",
        fontSize: "13px !important",
        fontWeight: 200,
        lineHeight: "110% !important",
        letterSpacing: "0.26px"
      },
      "& p.newPDPViewSalePriceMember": {
        fontFamily: "RHSans-Light !important",
        fontSize: "16px !important",
        fontWeight: 300,
        lineHeight: "100% !important"
      },
      "& p.newPDPViewSalePriceRegular": {
        fontFamily: "RHSans-Light !important",
        fontSize: "13px !important",
        fontWeight: 200,
        lineHeight: "110% !important",
        letterSpacing: "0.26px"
      },
      "& p[data-testid='pdp-price-for-view-sale']": {
        color: "#CA2022 !important",
        fontSize: "13px !important",
        letterSpacing: "0.20px !important",
        lineHeight: "15.60px !important",
        fontWeight: "100 !important"
      },
      "& p[data-testid='pdp-price-for-SM-sale']": {
        fontSize: "11px !important",
        lineHeight: "13.20px !important",
        letterSpacing: "0.17px !important",
        color: "#CA2022 !important",
        fontWeight: "100 !important"
      },

      "& #mobile-first-price-lable": {
        fontFamily: "RHSans-Roman !important",
        fontWeight: "400",
        fontSize: "",
        color: "#000",
        display: "none"
      },

      "& #newPDPMemberPrice": {
        letterSpacing: "0.20px !important",
        fontSize: "13px !important",
        lineHeight: "15.60px !important"
      },

      "& #newPDPSaleSMStyle": {
        fontSize: "11px !important",
        lineHeight: "13.20px !important",
        letterSpacing: "0.17px !important"
      },

      "& #newPDPSaleXSStyle": {
        fontSize: "11px !important",
        lineHeight: "13.20px !important",
        letterSpacing: "0.17px !important"
      },

      "& #regularPriceContainer *": {
        fontWeight: "100",
        fontFamily: "RHSans"
      },

      [theme.breakpoints.up(BREAKPOINT_SM)]: {
        "& #memberPrice": {
          fontSize: "13px",
          lineHeight: "15.6px",
          letterSpacing: "0.52px"
        },
        "& #regularPriceContainer *": {
          fontWeight: "100",
          fontSize: "13px"
        },
        "& p": {
          fontSize: "13px"
        }
      },
      [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
        "& #memberPrice": {
          fontSize: "13px"
        },
        "& #regularPriceContainer *": {
          fontSize: "13px"
        },
        "& p": {
          fontSize: "13px"
        }
      },
      [theme.breakpoints.between(BREAKPOINT_LG, BREAKPOINT_XL)]: {
        "& #regularPriceContainer *": {
          fontSize: "13px"
        }
      }
    },
    container: {
      "& p": {
        lineHeight: theme.typography.pxToRem(18)
      },
      letterSpacing: "0.20px"
    },
    priceBox: {
      display: "flex",
      flexWrap: "wrap"
    },
    textStrikeBox: {
      flexBasis: "100%",
      textDecoration: "line-through",
      textDecorationThickness: "from-font",
      textDecorationColor: "#898886"
    },
    regularPriceNumber: {
      color: "#666666 !important"
    },
    regularPriceText: {
      color: "#666666 !important"
    },
    finalSaleNumber: {
      color: "#CA2022 !important"
    },
    finalSaleText: {
      color: "#CA2022 !important"
    },
    memberPriceV2: {
      color: "#000"
    },
    imageContainerPriceLabel: {
      textTransform: "uppercase"
    },
    newPDPViewSaleStyle: {
      fontSize: "13.46px !important",
      lineHeight: "20.19px !important",
      letterSpacing: "0.20px !important",
      textTransform: "uppercase"
    },
    newPriceRangeSMSaleLabelAndMember: {
      fontSize: "11px !important",
      lineHeight: "13.20px !important",
      letterSpacing: "0.17px !important"
    },
    newPDPViewSaleXSStyle: {
      fontSize: "11px !important",
      lineHeight: "16.50px !important",
      letterSpacing: "0.17px !important"
    },
    newPDPViewSaleSMStyle: {
      fontSize: "13px !important",
      lineHeight: "15.60px !important"
    },
    newPDPSaleSMStyle: {
      fontSize: "11px !important",
      lineHeight: "13.20px !important",
      letterSpacing: "0.17px !important"
    },
    newPDPSaleXSStyle: {
      fontSize: "11px !important",
      lineHeight: "13.20px !important",
      letterSpacing: "0.17px !important"
    },
    newPDPtopLabel: {
      fontFamily: "RHSans-Light !important",
      fontSize: "13px !important",
      fontWeight: 200,
      lineHeight: "110% !important",
      letterSpacing: "0.26px"
    },
    viewSaleStyle: {
      fontSize: 13
    },
    ssrStyle: {
      lineHeight: "2rem"
    },
    topLabelStyle: {
      fontSize: "13px !important",
      lineHeight: "15.60px !important",
      letterSpacing: "0.20px"
    },
    regularPriceStyle: {
      fontSize: "13px !important",
      lineHeight: "15.60px !important",
      letterSpacing: "0.20px !important"
    },
    salePrice: {
      color: "var(--ReimagineWarm-RH-Red, #CA2022) !important",
      textAlign: "center",
      fontFamily: "RHSans-Light !important",
      fontSize: "16px !important",
      fontWeight: 300,
      lineHeight: "100%" /* 16px */
    },
    salePriceRegular: {
      color: "var(--ReimagineWarm-RH-Red, #CA2022) !important",
      textAlign: "center",
      fontFamily: "RHSans-ExtraLight !important",
      fontSize: "13px !important",
      fontWeight: 200,
      letterSpacing: "0.26px !important",
      lineHeight: "110% !important" /* 14.3px */
    },
    memberPriceStrike: {
      color: "var(--ReimagineWarm-RH-Dark, #898886) !important",
      textAlign: "center",
      fontFamily: "RHSans-Light !important",
      fontSize: "13px !important",
      fontWeight: 300,
      textDecorationLine: "line-through",
      lineHeight: "100% !important"
    },
    regularPriceStrike: {
      color: "var(--ReimagineWarm-RH-Dark, #898886) !important",
      textAlign: "center",
      fontFamily: "RHSans-ExtraLight !important",
      fontSize: "11px !important",
      fontWeight: 200,
      letterSpacing: "0.22px !important",
      lineHeight: "110% !important",
      textDecorationLine: "line-through"
    },
    yamlPricingMember: {
      height: "12px",
      alignSelf: "stretch",
      flexGrow: 0,
      WebkitTextStroke: "0 #898886",
      fontFamily: "RHSans-Light !important",
      fontSize: "10px !important",
      fontWeight: 300,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.2 !important",
      letterSpacing: "0.4px !important",
      textAlign: "center",
      color: "#000000 !important"
    },
    yamlPricingRegular: {
      height: "12px",
      alignSelf: "stretch",
      flexGrow: 0,
      WebkitTextStroke: "0 #898886",
      fontFamily: "RHSans-Thin !important",
      fontSize: "10px !important",
      fontWeight: 300,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.2 !important",
      letterSpacing: "0.4px !important",
      textAlign: "center",
      color: "#201f1f !important"
    },
    fabricRegularPrice: {
      fontFamily: "RHSans-Thin !important",
      fontSize: "11px !important",
      color: "var(--ReimagineWarm-RH-Darker,#201f1f) !important",
      lineHeight: "13.2px",
      letterSpacing: "0.44px"
    },
    fabricMemberPrice: {
      fontFamily: "RHSans-Light !important",
      fontSize: "11px !important",
      color: "var(--ReimagineWarm-RH-Black, #000000) !important",
      lineHeight: "13.2px !important",
      letterSpacing: "0.44px !important"
    },
    fabricRegularSalePrice: {
      fontFamily: "RHSans-Thin !important",
      color: "#ca2022",
      lineHeight: "13.2px",
      letterSpacing: "0.44px"
    },
    fabricMemberSalePrice: {
      fontFamily: "RHSans-Light !important",
      color: "#ca2022",
      lineHeight: "13.2px",
      letterSpacing: "0.44px"
    }
  })
);

export interface UniversalPriceDisplay {
  amount: number;
  label: string;
  strikethrough?: boolean | null;
  style?: React.CSSProperties; // probably going to deprecate this key
  className?: string; // probably going to deprecate this key
  isCartPage?: boolean;
  tradePrice?: number;
}

interface MemberPriceDisplay extends UniversalPriceDisplay {
  showMemberPrice?: boolean;
}

export interface PriceRange {
  regular: UniversalPriceDisplay;
  sale?: UniversalPriceDisplay;
  finalSale?: UniversalPriceDisplay;
  member?: MemberPriceDisplay;
  contract?: UniversalPriceDisplay;
  trade?: UniversalPriceDisplay;
  lowestSkuMemberPrice?: UniversalPriceDisplay;
  lowestSkuSalePrice?: UniversalPriceDisplay;
  lowestSkuRegularPrice?: UniversalPriceDisplay;
}

/**
 * Universal Price Range Display
 *    - the idea here is to pass in an Object (PriceRange) of maybe keys regular, sale, finalSale, member, trade and/or contract
 *    - When one is null, the label/price will not show.
 *    - !!! This is how we can control what is displayed with more of a data-driven approached. (This really helps with troubleshooting display and styles)
 * @param prop.topLabel string "Starting At" text for example
 * @param {*} prop.range Object typeof PriceRange
 *
 * ... there are more props
 * @returns
 */
export const UniversalPriceRangeDisplay: FC<
  Omit<RHPriceRangeDisplayProps, "data"> & {
    isCartPage?: boolean;
    topLabel?: string;
    range: PriceRange;
    /** @deprecated use currency */
    currencyLabel?: string;
    /** @deprecated use currency */
    currencySymbol?: string;
    showCents?: boolean;
    priceMessage?: string;
  }
> = ({
  topLabel,
  range: { regular, sale, finalSale, member, trade, contract } = {},
  currencyLabel,
  currencySymbol,
  currency,
  showCents = false,
  variant,
  priceMessage,
  isCartPage
}) => {
  const env = useEnv();
  const { pageContent } = usePageContent();
  const typographyClasses = useTypographyStyles({
    keys: [
      "priceRange",
      "priceRangeSmall",
      "priceRangeBold",
      "priceRangeSale",
      "priceRangeMember",
      "priceRangeCaptionSmall",
      "priceRangeLabelAndMember",
      "addOnPriceRangeLabelAndMember",
      "priceRangeSaleLabelAndMember",
      "rhBaseBody2",
      "rhBaseBody1",
      "newPriceRangeSaleLabelAndMember",
      "saleFontWeight"
    ]
  });
  const localClasses = useStyles();
  const genericProps = {
    currencyLabel,
    currencySymbol,
    currency,
    showCents
  };
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const showMemberColumn = countries[getCountryFromUrl() || "US"]["member"];

  const regularClassNames = classNames(
    typographyClasses.priceRange,
    { [typographyClasses.priceRangeSale]: false },
    variant === "small" && !mdUp ? typographyClasses.priceRangeSmall : "",
    typographyClasses.rhBaseBody1
  );
  const saleClassNames = classNames({
    [typographyClasses.priceRange]: !env.FEATURE_RHR,
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeBold]: !env.FEATURE_RHR,
    [typographyClasses.rhBaseBody1]: true,
    [typographyClasses.priceRangeSale]: true
  });
  const memberClassNames = classNames({
    [typographyClasses.priceRange]: !env.FEATURE_RHR,
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeBold]: !env.FEATURE_RHR,
    [typographyClasses.priceRangeSale]: false,
    [typographyClasses.priceRangeMember]: env.FEATURE_RHR,
    [typographyClasses.rhBaseBody2]: true
  });
  const formatCurrencyPrice = price => {
    let currencyCode = Boolean(env.FEATURE_INTERNATIONAL)
      ? countryNameMapper[getCountryFromUrl() || "US"]
      : currencySymbol && currencySymbolToCurrencyCodeMapper[currencySymbol];
    return formatCurrencyWithoutCS(price, currencyCode, false);
  };

  return (
    <Grid
      container
      spacing={0}
      className={`${localClasses.container} ${localClasses.priceRangeContainer}`}
      id="rh-price-range-display"
    >
      {!isCartPage && !!topLabel && (
        <Grid item xs={12}>
          <Typography
            className={classNames(
              typographyClasses.priceRange,
              typographyClasses.priceRangeLabelAndMember,
              {
                [typographyClasses.priceRangeSmall]:
                  variant === "small" && !mdUp
              }
            )}
            style={{
              textTransform: "none" // stop the capitalization
            }}
          >
            {topLabel}
          </Typography>
        </Grid>
      )}
      <Grid item container spacing={1}>
        {/* PRICE COLUMN */}
        {
          <Grid item>
            {regular?.amount && (
              <Price
                className={`${localClasses.regularPriceNumber} ${regularClassNames}`}
                style={regular.style}
                id="regularPrice"
                data-testid={`price-for-regular`}
                strikethrough={regular?.strikethrough!}
                {...genericProps}
              >
                {currency
                  ? regular?.amount
                  : formatCurrencyPrice(regular?.amount)}
              </Price>
            )}
            {sale && (
              <Price
                className={saleClassNames}
                style={sale.style}
                data-testid={`price-for-sale`}
                strikethrough={sale?.strikethrough!}
                {...genericProps}
              >
                {currency ? sale.amount : formatCurrencyPrice(sale.amount)}
              </Price>
            )}
            {finalSale && (
              <Price
                {...genericProps}
                className={`${localClasses.finalSaleText} ${saleClassNames}`}
                style={finalSale.style}
                data-testid={`price-for-final-sale`}
                strikethrough={finalSale?.strikethrough!}
              >
                {currency
                  ? finalSale.amount
                  : formatCurrencyPrice(finalSale.amount)}
              </Price>
            )}
            {member &&
              member.showMemberPrice &&
              showMemberColumn &&
              showMemberColumn && (
                <Price
                  className={memberClassNames}
                  style={{
                    ...member.style,
                    color: priceMessage ? "#666666" : ""
                  }}
                  id="memberPrice"
                  data-testid={`price-for-member`}
                  strikethrough={member?.strikethrough!}
                  {...genericProps}
                >
                  {currency
                    ? member.amount
                    : formatCurrencyPrice(member.amount)}
                </Price>
              )}
            {contract && (
              <Price
                className={memberClassNames}
                style={contract.style}
                data-testid={`price-for-contract`}
                strikethrough={contract?.strikethrough!}
                {...genericProps}
              >
                {currency
                  ? contract.amount
                  : formatCurrencyPrice(contract.amount)}
              </Price>
            )}
            {trade && (
              <Price
                className={memberClassNames}
                style={trade.style}
                data-testid={`price-for-trade`}
                strikethrough={trade?.strikethrough!}
                {...genericProps}
              >
                {currency ? trade.amount : formatCurrencyPrice(trade.amount)}
              </Price>
            )}
          </Grid>
        }

        {/* LABEL COLUMN */}
        <Grid item>
          {regular?.amount && (
            <PriceLabel
              className={`${localClasses.regularPriceText} ${regularClassNames}`}
              style={regular.style}
            >
              {regular?.label || pageContent?.REGULAR}
            </PriceLabel>
          )}
          {member &&
            member.showMemberPrice &&
            showMemberColumn &&
            showMemberColumn && (
              <PriceLabel className={memberClassNames} style={member.style}>
                {member.label || pageContent?.MEMBER}
              </PriceLabel>
            )}
          {contract && (
            <PriceLabel className={memberClassNames} style={contract.style}>
              {contract.label || pageContent?.CONTRACT}
            </PriceLabel>
          )}
          {trade && (
            <PriceLabel className={memberClassNames} style={trade.style}>
              {trade.label || pageContent?.TRADE}
            </PriceLabel>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const RHRUniversalPriceRangeDisplay: FC<
  Omit<RHPriceRangeDisplayProps, "data"> & {
    isCartPage?: boolean;
    topLabel?: string;
    range: PriceRange;
    /** @deprecated use currency */
    currencyLabel?: string;
    /** @deprecated use currency */
    currencySymbol?: string;
    showCents?: boolean;
    priceMessage?: string;
    priceMessagePrice?: number;
    inStockPgPrice?: SkuResponsePriceInfo;
    salePriceLabel?: string;
    showSaleMessage?: boolean;
    emptyProduct?: boolean;
    isStockedFilterActive?: boolean;
    isMetaProduct?: boolean;
    isSkuLevel?: boolean;
    centerAlignFlag?: boolean;
    nextGenDrivenOnSale?: boolean;
    metaProductComponent?: boolean;
    skuOnSalePercentage?: number;
    isCustomProduct?: boolean;
    productId?: string;
    drivedStyles?: CSSProperties;
    addOnV2Pricing?: boolean;
    showSalePriceRange?: boolean;
    hideSalePrice?: boolean;
    configureLabel?: string;
    hideUpSellSale?: boolean;
    isNewPDPLayout?: boolean;
    isNewPDPButton?: boolean;
    showGiftCardPrice?: boolean;
    isConfirmationDialog?: boolean;
    showMembershipProductPrice?: boolean;
  }
> = ({
  topLabel,
  range: {
    regular,
    sale,
    finalSale,
    member,
    trade,
    contract,
    lowestSkuMemberPrice,
    lowestSkuRegularPrice,
    lowestSkuSalePrice
  } = {},
  currencyLabel,
  currencySymbol,
  currency,
  showCents = false,
  variant,
  priceMessage,
  priceMessagePrice,
  isCartPage,
  showSaleMessage,
  emptyProduct,
  isStockedFilterActive,
  inStockPgPrice,
  isMetaProduct,
  isSkuLevel = false,
  centerAlignFlag,
  nextGenDrivenOnSale,
  onViewSelectItemsOnSaleClick,
  metaProductComponent,
  skuOnSalePercentage,
  isCustomProduct,
  drivedStyles = {},
  viewPriceOnly = false,
  showSalePriceRange,
  hideSalePrice,
  isSwatchPanelOpen,
  configureLabel,
  color,
  hideUpSellSale = false,
  isNewPDPLayout,
  isNewPDPButton,
  showGiftCardPrice,
  isConfirmationDialog,
  addOnV2Pricing,
  isYaml = false,
  showMembershipProductPrice,
  isFabricPricing = false
}) => {
  const env = useEnv();
  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const { pageContent } = usePageContent();
  const cookies = useIsoCookies();
  const location = useLocation();
  const selectedAccount = cookies["selected_customer"];
  const { userType = selectedAccount } = useRhUserAtomValue();
  const typographyClasses = useTypographyStyles({
    keys: [
      "priceRange",
      "priceRangeSmall",
      "priceRangeBold",
      "priceRangeSale",
      "priceRangeRegular",
      "priceRangeMember",
      "priceRangeLabelAndMember",
      "priceRangeSaleLabelAndMember",
      "addOnPriceRangeLabelAndMember",
      "priceRangeSaleRegular",
      "priceRangeStrikethroughRegular",
      "priceRangeStrikethroughMember",
      "priceRangeCaptionSmall",
      "rhBaseBody2",
      "rhBaseBody1",
      "rhBaseBody3",
      "rhBaseCaption",
      "priceViewSaleLinkBtn",
      "priceViewSaleLink",
      "priceViewSale",
      "newPriceRangeSaleLabelAndMember",
      "saleFontWeight",
      "priceRangeSale2"
    ]
  });
  const localClasses = useStyles();
  const genericProps = {
    currencyLabel,
    currencySymbol,
    currency,
    showCents
  };
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const screenXS = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const screenSm = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
  const showMemberColumn = countries[getCountryFromUrl() || "US"];
  const fontStyle = useMemo<CSSProperties>(() => {
    let style: CSSProperties = {
      fontWeight: "none",
      lineHeight: "1.5rem",
      color: color
    };

    if (variant === "small") {
      style = { ...style, fontSize: 11 };
    } else if (variant === "medium") {
      style = { ...style, fontSize: 13 };
    }
    if (variant === "print") {
      style = { fontSize: "25px", lineHeight: 1.43 };
    }

    return style;
  }, [variant, color]);

  const regularSaleClassNames = classNames({
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeSaleRegular]: true,
    [typographyClasses.rhBaseBody3]: variant === "small" && !mdUp
  });

  const saleClassNames = classNames({
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeSaleLabelAndMember]: true,
    [typographyClasses.rhBaseBody3]: true
  });

  const memberClassNames = classNames({
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeSale]: false,
    [typographyClasses.priceRangeLabelAndMember]: true,
    [typographyClasses.rhBaseBody3]: false // PDP-273 price setting price font to RHSans-Light, Helvetica, Arial
  });

  const isTrade = userType === "TRADE";
  const isContract = userType === "CONTRACT";
  const showMemberPrice = !isTrade && !isContract;
  const isProductPage = location.pathname.includes("product/product.jsp");

  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  const formatCurrencyPrice = price => {
    let currencyCode = Boolean(env.FEATURE_INTERNATIONAL)
      ? countryNameMapper[getCountryFromUrl() || "US"]
      : currencySymbolToCurrencyCodeMapper[currencySymbol!];
    return formatCurrencyWithoutCS(price, currencyCode, false);
  };

  const RHRPriceSeparator = memoize(() => {
    const typographyClasses = useTypographyStyles({
      keys: ["priceRangeRegular", "priceRangeStrikethroughRegular"]
    });

    return (
      <Typography
        className={
          isYaml
            ? localClasses.yamlPricingRegular
            : isNewPDPLayout && sale
            ? localClasses.regularPriceStrike
            : sale
            ? isSkuLevel
              ? typographyClasses?.priceRangeRegular
              : typographyClasses?.priceRangeStrikethroughRegular
            : typographyClasses?.priceRangeRegular
        }
        style={{ fontSize: variant === "small" ? 11 : 13, color: color }}
        display="inline"
        id="rhr-price-separator"
      >
        {" / "} {isNewPDPButton && !(smUp && smDown) ? <br /> : ""}
      </Typography>
    );
  });

  const RHRSalePriceSeparator = memoize(() => {
    const typographyClasses = useTypographyStyles({
      keys: ["priceRangeSaleRegular"]
    });
    return (
      <Typography
        className={
          isNewPDPLayout
            ? localClasses.salePriceRegular
            : typographyClasses?.priceRangeSaleRegular
        }
        style={{ fontSize: variant === "small" ? 11 : 13, color: color }}
        display="inline"
      >
        {" / "}
      </Typography>
    );
  });

  const saleCheck = isStockedFilterActive ? !!inStockPgPrice?.onSale : sale;

  const params = useParams({
    sale: ""
  });

  const {
    regularPriceValue,
    salePriceValue,
    memberPriceValue,
    memberSalePriceValue,
    tradePrice,
    contractPrice,
    tradeSalePrice,
    contractSalePrice
  } = useMemo(
    () =>
      calculatePrices({
        isStockedFilterActive,
        inStockPrice: inStockPgPrice,
        regular,
        params,
        lowestSkuMemberPrice,
        lowestSkuRegularPrice,
        lowestSkuSalePrice,
        member,
        tradePrice: trade?.amount,
        contractPrice: contract?.amount,
        nextGenDrivenOnSale,
        showSaleMessage,
        skuOnSalePercentage,
        sale,
        isMetaProduct
      }),
    [
      isMetaProduct,
      params?.sale,
      regular?.amount,
      sale?.amount,
      inStockPgPrice?.listPrice,
      inStockPgPrice?.memberPrice,
      trade?.amount
    ]
  );

  const openSwatchPanelstyle: CSSProperties = isSwatchPanelOpen
    ? {
        display: "flex",
        flexDirection: "column"
      }
    : {
        paddingRight: "12px",
        display: `${sale ? "contents" : ""}`
      };

  const renderPriceSection = (priceData: any) => {
    if (!priceData || !priceData.price) return null;

    const { price, label, style, classNames } = priceData;

    return (
      <>
        {!!price && (
          <Price
            className={`${classNames} ${
              sale && isNewPDPLayout ? localClasses.salePrice : ""
            }`}
            display="inline"
            style={style}
            data-testid={`price-for-${label.toLowerCase()}`}
            {...genericProps}
          >
            {currency ? price + " " : formatCurrencyPrice(price) + " "}
          </Price>
        )}{" "}
        <PriceLabel
          className={`${classNames} ${
            sale && isNewPDPLayout ? localClasses.salePrice : ""
          }`}
          display="inline"
          style={style}
        >
          {pageContent?.[label.toUpperCase()] || label}
        </PriceLabel>
      </>
    );
  };

  const refactorPricing = false;
  const priceLabelValue =
    isNewPDPLayout && sale
      ? pageContent?.["STARTING_AT"]
      : configureLabel
      ? configureLabel
      : topLabel;

  const priceLabelValueUi = useMemo(() => {
    return (
      !isCartPage &&
      !isCustomProduct &&
      !hideSalePrice &&
      regularPriceValue >= 0 &&
      !!priceLabelValue && (
        <span
          id="mobile-first-price-lable"
          className={
            isNewPDPLayout && sale
              ? localClasses.salePriceRegular
              : sale && !showGiftCardPrice
              ? typographyClasses.priceRangeSaleLabelAndMember
              : addOnV2Pricing
              ? typographyClasses.addOnPriceRangeLabelAndMember
              : typographyClasses.priceRangeLabelAndMember
          }
          style={
            isSSRToggledWithCSR
              ? {
                  lineHeight: "2rem",
                  ...fontStyle,
                  ...drivedStyles
                }
              : {
                  textTransform: "none", // stop the capitalization
                  color: "#000",
                  ...fontStyle,
                  ...drivedStyles
                }
          }
        >
          {priceLabelValue}
          &nbsp;
        </span>
      )
    );
  }, [
    isCartPage,
    isCustomProduct,
    hideSalePrice,
    regularPriceValue,
    priceLabelValue
  ]);

  return (
    <Grid
      container
      id="rh-price-range-display"
      data-testid="rh-price-range-display"
      className={`${localClasses.priceBox} ${localClasses.priceRangeContainer}`}
      alignItems="baseline"
      style={{
        justifyContent: centerAlignFlag ? "center" : "left",
        textAlign: centerAlignFlag ? "center" : "left",
        flexDirection: isSwatchPanelOpen ? "column" : "row",
        paddingRight: "10px"
      }}
    >
      {!isCartPage &&
        !isCustomProduct &&
        !hideSalePrice &&
        regularPriceValue >= 0 &&
        !!priceLabelValue &&
        !showMembershipProductPrice && (
          <Grid item>
            {sale && (
              <span
                className={classNames(`uppercase my-0 mr-1`, {
                  "text-[#CA2022]": sale,
                  "text-[#000]": !sale
                })}
              >
                {pageContent?.sale ? pageContent?.sale : pageContent?.SALE}
              </span>
            )}
            <Typography
              id="first-price-label-swatch"
              className={
                isYaml
                  ? localClasses.yamlPricingMember
                  : isNewPDPLayout && sale
                  ? localClasses.salePriceRegular
                  : sale && !showGiftCardPrice
                  ? typographyClasses.priceRangeSaleLabelAndMember
                  : addOnV2Pricing
                  ? typographyClasses.addOnPriceRangeLabelAndMember
                  : typographyClasses.priceRangeLabelAndMember
              }
              style={
                isSSRToggledWithCSR
                  ? {
                      lineHeight: "2rem",
                      ...fontStyle,
                      ...drivedStyles
                    }
                  : {
                      textTransform: "none", // stop the capitalization
                      color: "#000",
                      ...fontStyle,
                      ...drivedStyles
                    }
              }
            >
              {priceLabelValue}
              &nbsp;
            </Typography>
          </Grid>
        )}

      {/* for price refactoring once done we need to remove it unnecessary sections currently it is supporting all price styling expcept PDPV2 and in stock */}
      {/* by defaul it is false */}
      {refactorPricing ? (
        <>
          {isSkuLevel ? (
            <>
              {/* sku level sale pricing */}
              <Grid
                item
                style={{
                  ...openSwatchPanelstyle,
                  ...drivedStyles
                }}
              >
                <span id="swatchSkuMemberPrice">
                  {showMemberColumn && (
                    <>
                      {renderPriceSection({
                        price: getUserTypePrice(userType, {
                          tradePrice: tradeSalePrice!,
                          contractPrice: contractSalePrice!,
                          memberPrice: memberSalePriceValue!
                        }),
                        label: getUserTypeLabel(userType, {
                          trade: pageContent?.TRADE || trade?.label,
                          contract: pageContent?.CONTRACT || contract?.label,
                          member: pageContent?.MEMBER || member?.label
                        }),
                        style: { ...member?.style, ...fontStyle },
                        classNames:
                          typographyClasses.priceRangeSaleLabelAndMember
                      })}
                    </>
                  )}
                </span>
                <span id="swatchSkuRegularPrice">
                  {!!salePriceValue && (
                    <>
                      &nbsp;
                      {renderPriceSection({
                        price: salePriceValue,
                        label: isConfirmationDialog
                          ? pageContent?.sale
                          : pageContent?.SALE,
                        style: { fontStyle },
                        classNames: classNames([
                          typographyClasses?.priceRangeSale2
                        ])
                      })}
                      <RHRPriceSeparator />
                    </>
                  )}
                  {!!regularPriceValue && !!regular && (
                    <>
                      &nbsp;
                      {renderPriceSection({
                        price: regularPriceValue,
                        label: pageContent?.REGULAR || regular?.label,
                        style: {
                          textDecoration: `${
                            !salePriceValue ? "initial" : "line-through"
                          }`,
                          ...fontStyle,
                          textDecorationThickness: "from-font"
                        },
                        classNames: typographyClasses?.priceRangeRegular
                      })}
                      <RHRPriceSeparator />
                    </>
                  )}
                </span>
              </Grid>
            </>
          ) : (
            <>
              {!isCustomProduct && (
                <>
                  <>
                    {!!saleCheck && (
                      <Grid item>
                        {showMemberColumn && (
                          <>
                            {renderPriceSection({
                              price: getUserTypePrice(userType, {
                                tradePrice: tradeSalePrice!,
                                contractPrice: contractSalePrice!,
                                memberPrice: memberSalePriceValue!
                              }),
                              label: getUserTypeLabel(userType, {
                                trade: pageContent?.TRADE || trade?.label,
                                contract:
                                  pageContent?.CONTRACT || contract?.label,
                                member: pageContent?.MEMBER || member?.label
                              }),
                              style: { ...member?.style, ...fontStyle },
                              classNames:
                                typographyClasses.priceRangeSaleLabelAndMember
                            })}
                          </>
                        )}
                        {sale && (
                          <>
                            <RHRSalePriceSeparator />
                            {renderPriceSection({
                              price: salePriceValue,
                              label: pageContent?.REGULAR || regular?.label,
                              style: { ...sale.style, ...fontStyle },
                              classNames: classNames([
                                regularSaleClassNames,
                                typographyClasses?.priceRangeSaleRegular
                              ])
                            })}
                          </>
                        )}
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={!!saleCheck ? 12 : undefined}
                      className={
                        isNewPDPLayout
                          ? ""
                          : sale
                          ? localClasses.textStrikeBox
                          : ""
                      }
                      style={{
                        ...fontStyle,
                        ...(!!saleCheck ? drivedStyles : "")
                      }}
                    >
                      {showMemberColumn && (
                        <>
                          {renderPriceSection({
                            price: getUserTypePrice(userType, {
                              tradePrice: tradePrice!,
                              contractPrice: contractPrice!,
                              memberPrice: memberPriceValue!
                            }),
                            label: getUserTypeLabel(userType, {
                              trade: pageContent?.TRADE || trade?.label,
                              contract:
                                pageContent?.CONTRACT || contract?.label,
                              member: pageContent?.MEMBER || member?.label
                            }),
                            style: { ...member?.style!, ...fontStyle },
                            classNames: sale
                              ? typographyClasses?.priceRangeStrikethroughMember
                              : typographyClasses?.priceRangeLabelAndMember
                          })}
                        </>
                      )}
                      <>
                        <span id="regularPriceContainer">
                          <RHRPriceSeparator />
                          {renderPriceSection({
                            price: regularPriceValue,
                            label: pageContent?.REGULAR || regular?.label,
                            style: { ...fontStyle, ...regular?.style },
                            classNames: sale
                              ? typographyClasses?.priceRangeStrikethroughRegular
                              : typographyClasses?.priceRangeRegular
                          })}
                        </span>
                      </>
                    </Grid>
                  </>
                  <Grid item xs={12}>
                    {priceMessage &&
                      nextGenDrivenOnSale &&
                      (!params.sale || isProductPage) && (
                        <>
                          <Grid
                            container
                            className={localClasses.container}
                            alignItems="baseline"
                            style={{
                              justifyContent: centerAlignFlag
                                ? "center"
                                : "left",
                              textAlign: centerAlignFlag ? "center" : "left",
                              ...(isNewPDPLayout && { marginBottom: "12px" })
                            }}
                          >
                            <Grid item>
                              <ButtonBase
                                onClick={onViewSelectItemsOnSaleClick}
                                disabled={!onViewSelectItemsOnSaleClick}
                                className={
                                  typographyClasses.priceViewSaleLinkBtn
                                }
                              >
                                <Typography
                                  className={classNames(
                                    typographyClasses.rhBaseBody3,
                                    !onViewSelectItemsOnSaleClick
                                      ? typographyClasses.priceViewSale
                                      : typographyClasses.priceViewSaleLink
                                  )}
                                  style={{
                                    paddingTop: "10px",
                                    fontSize: 13,
                                    lineHeight: isSSRToggledWithCSR
                                      ? "2rem"
                                      : ""
                                  }}
                                  data-testid={
                                    isNewPDPLayout
                                      ? "price-view-for-new-pdplayout"
                                      : ""
                                  }
                                >
                                  {!onViewSelectItemsOnSaleClick
                                    ? pageContent?.SELECT_ITEM_ON_SALE
                                    : pageContent?.VIEW_ITEM_ON_SALE}
                                </Typography>
                              </ButtonBase>
                            </Grid>
                          </Grid>
                          {!metaProductComponent && (
                            <Grid
                              container
                              className={localClasses.container}
                              alignItems="baseline"
                              style={{
                                justifyContent: centerAlignFlag
                                  ? "center"
                                  : "left",
                                textAlign: centerAlignFlag ? "center" : "left"
                              }}
                            >
                              <Grid item>
                                {lowestSkuMemberPrice?.amount && (
                                  <>
                                    {renderPriceSection({
                                      price: lowestSkuMemberPrice?.amount,
                                      label: getUserTypeLabel(userType, {
                                        trade:
                                          pageContent?.TRADE || trade?.label,
                                        contract:
                                          pageContent?.CONTRACT ||
                                          contract?.label,
                                        member:
                                          pageContent?.MEMBER || member?.label
                                      }),
                                      style: {
                                        ...member?.style!,
                                        ...fontStyle
                                      },
                                      classNames:
                                        typographyClasses.priceRangeSaleLabelAndMember
                                    })}
                                  </>
                                )}
                                {lowestSkuSalePrice?.amount && (
                                  <>
                                    <RHRSalePriceSeparator />
                                    {renderPriceSection({
                                      price: lowestSkuSalePrice?.amount,
                                      label:
                                        pageContent?.REGULAR || regular?.label,
                                      style: { ...sale?.style!, ...fontStyle },
                                      classNames: classNames([
                                        regularSaleClassNames,
                                        typographyClasses?.priceRangeSaleRegular
                                      ])
                                    })}
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {showGiftCardPrice || showMembershipProductPrice ? (
            <>
              {renderPriceSection({
                price: regularPriceValue,
                label: "",
                style: { ...fontStyle, ...regular?.style },
                classNames: typographyClasses?.priceRangeRegular
              })}
            </>
          ) : (
            <>
              {!isCustomProduct && (
                <>
                  {!!saleCheck &&
                    (!isSkuLevel ? (
                      <>
                        {/* product level sale pricing  */}
                        <Grid item>
                          {member &&
                            member.showMemberPrice &&
                            showMemberColumn && (
                              <>
                                <Price
                                  className={
                                    isNewPDPLayout && sale
                                      ? localClasses.salePrice
                                      : sale
                                      ? typographyClasses.priceRangeSaleLabelAndMember
                                      : typographyClasses.priceRangeLabelAndMember
                                  }
                                  display="inline"
                                  style={{ ...member.style, ...fontStyle }}
                                  data-testid={`price-for-member`}
                                  {...genericProps}
                                >
                                  {currency
                                    ? memberSalePriceValue
                                    : formatCurrencyPrice(memberSalePriceValue)}
                                </Price>{" "}
                                <PriceLabel
                                  className={
                                    isNewPDPLayout && sale
                                      ? localClasses.salePrice
                                      : sale
                                      ? typographyClasses.priceRangeSaleLabelAndMember
                                      : typographyClasses.priceRangeLabelAndMember
                                  }
                                  display="inline"
                                  style={{ ...member.style, ...fontStyle }}
                                >
                                  {pageContent?.MEMBER || member?.label}
                                </PriceLabel>
                              </>
                            )}
                          {contract && (
                            <>
                              <Price
                                className={classNames(memberClassNames, {
                                  [typographyClasses?.priceRangeSale]:
                                    finalSale || sale
                                })}
                                display="inline"
                                style={{ ...contract.style, ...fontStyle }}
                                data-testid={`price-for-contract`}
                                {...genericProps}
                              >
                                {currency
                                  ? contractSalePrice
                                  : formatCurrencyPrice(contractSalePrice)}
                              </Price>{" "}
                              <PriceLabel
                                className={classNames(memberClassNames, {
                                  [typographyClasses?.priceRangeSale]:
                                    finalSale || sale
                                })}
                                display="inline"
                                style={{ ...contract.style, ...fontStyle }}
                              >
                                {pageContent?.CONTRACT || contract?.label}
                              </PriceLabel>
                            </>
                          )}
                          {trade && (
                            <>
                              <Price
                                className={classNames(memberClassNames, {
                                  [typographyClasses?.priceRangeSale]:
                                    finalSale || sale
                                })}
                                display="inline"
                                style={{ ...trade.style, ...fontStyle }}
                                data-testid={`price-for-trade`}
                                {...genericProps}
                              >
                                {currency
                                  ? tradeSalePrice
                                  : formatCurrencyPrice(tradeSalePrice)}
                              </Price>{" "}
                              <PriceLabel
                                className={classNames(memberClassNames, {
                                  [typographyClasses?.priceRangeSale]:
                                    finalSale || sale
                                })}
                                display="inline"
                                style={{ ...trade.style, ...fontStyle }}
                              >
                                {pageContent?.TRADE || trade?.label}
                              </PriceLabel>
                            </>
                          )}
                          {sale && (
                            <>
                              <RHRSalePriceSeparator />
                              <Price
                                className={classNames(
                                  isNewPDPLayout
                                    ? localClasses.salePriceRegular
                                    : [
                                        regularSaleClassNames,
                                        typographyClasses?.priceRangeSaleRegular
                                      ]
                                )}
                                display="inline"
                                style={{ ...sale.style, ...fontStyle }}
                                data-testid={`price-for-sale`}
                                {...genericProps}
                              >
                                {currency
                                  ? salePriceValue
                                  : formatCurrencyPrice(salePriceValue)}
                              </Price>{" "}
                              <PriceLabel
                                className={classNames(
                                  isNewPDPLayout
                                    ? localClasses.salePriceRegular
                                    : [
                                        regularSaleClassNames,
                                        typographyClasses?.priceRangeSaleRegular
                                      ]
                                )}
                                display="inline"
                                style={{ ...sale.style, ...fontStyle }}
                              >
                                {pageContent?.REGULAR || regular?.label}
                              </PriceLabel>
                            </>
                          )}
                          {finalSale && (
                            <>
                              <RHRSalePriceSeparator />
                              <Price
                                {...genericProps}
                                display="inline"
                                className={classNames(
                                  saleClassNames,
                                  typographyClasses?.priceRangeSaleRegular
                                )}
                                style={{ ...finalSale.style, ...fontStyle }}
                                data-testid={`price-for-final-sale`}
                              >
                                {currency
                                  ? finalSale.amount
                                  : formatCurrencyPrice(finalSale.amount)}
                              </Price>{" "}
                              <PriceLabel
                                className={classNames(
                                  saleClassNames,
                                  typographyClasses?.priceRangeSaleRegular
                                )}
                                display="inline"
                                style={{ ...finalSale.style, ...fontStyle }}
                              >
                                {pageContent?.REGULAR || regular?.label}
                              </PriceLabel>
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={
                            isNewPDPLayout
                              ? ""
                              : sale
                              ? localClasses.textStrikeBox
                              : ""
                          }
                          style={{ ...fontStyle }}
                        >
                          {member &&
                            member.showMemberPrice &&
                            showMemberColumn && (
                              <>
                                <Price
                                  className={
                                    isNewPDPLayout && sale
                                      ? localClasses.memberPriceStrike
                                      : sale
                                      ? typographyClasses?.priceRangeStrikethroughMember
                                      : typographyClasses?.priceRangeLabelAndMember
                                  }
                                  display="inline"
                                  style={{ ...member.style, ...fontStyle }}
                                  data-testid={`price-for-member`}
                                  {...genericProps}
                                >
                                  {currency
                                    ? memberPriceValue + " "
                                    : formatCurrencyPrice(memberPriceValue) +
                                      " "}
                                </Price>{" "}
                                <PriceLabel
                                  className={
                                    isNewPDPLayout && sale
                                      ? localClasses.memberPriceStrike
                                      : sale
                                      ? typographyClasses?.priceRangeStrikethroughMember
                                      : typographyClasses?.priceRangeLabelAndMember
                                  }
                                  display="inline"
                                  style={{ ...member.style, ...fontStyle }}
                                >
                                  {pageContent?.MEMBER || member?.label}
                                </PriceLabel>{" "}
                              </>
                            )}
                          {contract && (
                            <>
                              <Price
                                className={
                                  sale
                                    ? typographyClasses?.priceRangeStrikethroughMember
                                    : typographyClasses?.priceRangeLabelAndMember
                                }
                                display="inline"
                                style={{ ...contract.style, ...fontStyle }}
                                data-testid={`price-for-contract`}
                                {...genericProps}
                              >
                                {currency
                                  ? contractPrice + " "
                                  : formatCurrencyPrice(contractPrice) + " "}
                              </Price>{" "}
                              <PriceLabel
                                className={
                                  sale
                                    ? typographyClasses?.priceRangeStrikethroughMember
                                    : typographyClasses?.priceRangeLabelAndMember
                                }
                                display="inline"
                                style={{ ...contract.style, ...fontStyle }}
                              >
                                {pageContent?.CONTRACT || contract?.label}
                              </PriceLabel>{" "}
                            </>
                          )}
                          {trade && (
                            <>
                              <Price
                                className={
                                  sale
                                    ? typographyClasses?.priceRangeStrikethroughMember
                                    : typographyClasses?.priceRangeLabelAndMember
                                }
                                display="inline"
                                style={{ ...trade.style, ...fontStyle }}
                                data-testid={`price-for-trade`}
                                {...genericProps}
                              >
                                {currency
                                  ? tradePrice + " "
                                  : formatCurrencyPrice(tradePrice) + " "}
                              </Price>{" "}
                              <PriceLabel
                                className={
                                  sale
                                    ? typographyClasses?.priceRangeStrikethroughMember
                                    : typographyClasses?.priceRangeLabelAndMember
                                }
                                display="inline"
                                style={{ ...trade.style, ...fontStyle }}
                              >
                                {pageContent?.TRADE || trade?.label}
                              </PriceLabel>{" "}
                            </>
                          )}
                          <>
                            <span id="regularPriceContainer">
                              <RHRPriceSeparator />
                              <Price
                                className={
                                  isNewPDPLayout && sale
                                    ? localClasses.regularPriceStrike
                                    : sale
                                    ? typographyClasses?.priceRangeStrikethroughRegular
                                    : typographyClasses?.priceRangeRegular
                                }
                                display="inline"
                                style={{ ...fontStyle }}
                                id="regularPrice"
                                data-testid={`price-for-regular`}
                                {...genericProps}
                              >
                                {currency
                                  ? regularPriceValue + " "
                                  : formatCurrencyPrice(regularPriceValue) +
                                    " "}
                              </Price>{" "}
                              <PriceLabel
                                className={
                                  isNewPDPLayout && sale
                                    ? localClasses.regularPriceStrike
                                    : sale
                                    ? typographyClasses?.priceRangeStrikethroughRegular
                                    : typographyClasses?.priceRangeRegular
                                }
                                display="inline"
                                style={{ ...fontStyle, ...regular?.style }}
                              >
                                {pageContent?.REGULAR || regular?.label}
                              </PriceLabel>{" "}
                            </span>
                          </>
                        </Grid>
                      </>
                    ) : (
                      <>
                        {/* sku level sale pricing */}
                        <Grid
                          item
                          style={{
                            ...openSwatchPanelstyle,
                            ...drivedStyles
                          }}
                        >
                          <span id="swatchSkuMemberPrice">
                            {memberSalePriceValue && showMemberPrice && (
                              <>
                                {memberSalePriceValue ? (
                                  <Price
                                    className={
                                      isYaml
                                        ? localClasses.yamlPricingMember
                                        : classNames(memberClassNames, {
                                            [typographyClasses?.priceRangeSale]:
                                              true
                                          })
                                    }
                                    display="inline"
                                    style={{
                                      ...fontStyle,
                                      ...member?.style
                                    }}
                                    id="memberPrice"
                                    data-testid={`price-for-member`}
                                    {...genericProps}
                                  >
                                    {currency
                                      ? memberSalePriceValue
                                      : formatCurrencyPrice(
                                          memberSalePriceValue
                                        )}
                                  </Price>
                                ) : null}{" "}
                                <PriceLabel
                                  className={
                                    isYaml
                                      ? localClasses.yamlPricingMember
                                      : classNames(memberClassNames, {
                                          [typographyClasses?.priceRangeSale]:
                                            true
                                        })
                                  }
                                  display="inline"
                                  style={{ ...fontStyle, ...member?.style }}
                                >
                                  {pageContent?.MEMBER || member?.label}
                                </PriceLabel>
                                {!isSwatchPanelOpen && (
                                  <RHRSalePriceSeparator />
                                )}
                              </>
                            )}
                            {contractSalePrice && isContract && (
                              <>
                                <Price
                                  className={classNames(memberClassNames, {
                                    [typographyClasses?.priceRangeSale]:
                                      finalSale || sale
                                  })}
                                  display="inline"
                                  style={{ ...fontStyle, ...contract?.style }}
                                  data-testid={`price-for-contract`}
                                  {...genericProps}
                                >
                                  {currency
                                    ? contractSalePrice
                                    : formatCurrencyPrice(contractSalePrice)}
                                </Price>{" "}
                                <PriceLabel
                                  className={classNames(memberClassNames, {
                                    [typographyClasses?.priceRangeSale]:
                                      finalSale || sale
                                  })}
                                  display="inline"
                                  style={{ ...fontStyle, ...contract?.style }}
                                >
                                  {contract?.label || pageContent?.CONTRACT}
                                </PriceLabel>
                                <RHRPriceSeparator />
                              </>
                            )}
                            {isTrade && tradeSalePrice && (
                              <>
                                <Price
                                  className={classNames(memberClassNames, {
                                    [typographyClasses?.priceRangeSale]: true
                                  })}
                                  display="inline"
                                  style={{
                                    ...fontStyle,
                                    ...(trade?.["style"] || {})
                                  }}
                                  data-testid={`price-for-trade`}
                                  {...genericProps}
                                >
                                  {currency
                                    ? tradeSalePrice
                                    : formatCurrencyPrice(tradeSalePrice)}
                                </Price>{" "}
                                <PriceLabel
                                  className={classNames(memberClassNames, {
                                    [typographyClasses?.priceRangeSale]: true
                                  })}
                                  display="inline"
                                  style={{
                                    ...fontStyle,
                                    ...(trade?.["style"] || {})
                                  }}
                                >
                                  {trade?.["label"] || pageContent?.TRADE}
                                </PriceLabel>
                                <RHRPriceSeparator />
                              </>
                            )}
                          </span>
                          <span id="swatchSkuRegularPrice">
                            {/* {hideSalePrice ? null : (
                              <>
                                {!!salePriceValue && (
                                  <>
                                    &nbsp;
                                    <Price
                                      className={classNames(
                                        typographyClasses?.priceRangeSale2
                                      )}
                                      display="inline"
                                      data-testid={`price-for-sale`}
                                      style={fontStyle}
                                      {...genericProps}
                                    >
                                      {currency
                                        ? salePriceValue
                                        : formatCurrencyPrice(salePriceValue)}
                                    </Price>{" "}
                                  </>
                                )}
                                <PriceLabel
                                  display="inline"
                                  className={classNames(
                                    typographyClasses?.priceRangeSale2
                                  )}
                                  style={fontStyle}
                                >
                                  {isConfirmationDialog
                                    ? pageContent?.sale
                                    : pageContent?.sale}
                                </PriceLabel>
                                  {!hideSalePrice && isFabricPricing ? null : (
                                  <RHRPriceSeparator />
                                )}
                              </>
                            )} */}
                            {!hideSalePrice && isFabricPricing ? null : (
                              <>
                                <PriceLabel
                                  className={classNames(
                                    hideSalePrice
                                      ? {
                                          [typographyClasses?.priceRangeSale2]:
                                            true
                                        }
                                      : typographyClasses?.priceRangeRegular
                                  )}
                                  display="inline"
                                  style={{
                                    textDecoration: `${
                                      hideSalePrice ? "initial" : "line-through"
                                    }`,
                                    ...fontStyle,
                                    textDecorationThickness: "from-font"
                                  }}
                                >
                                  {`
                                  ${`${currencySymbol}${formatCurrencyPrice(
                                    regularPriceValue
                                  )}`}
                                  ${pageContent?.REGULAR || regular?.label}
                               `}
                                </PriceLabel>
                              </>
                            )}
                          </span>
                        </Grid>
                      </>
                    ))}

                  {/* product is not on sale*/}
                  {!saleCheck && regularPriceValue >= 0 && (
                    <Grid
                      item
                      className={sale ? localClasses.textStrikeBox : ""}
                      style={
                        isNewPDPButton
                          ? {
                              ...fontStyle,
                              ...drivedStyles,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "2px"
                            }
                          : isSwatchPanelOpen
                          ? {
                              ...fontStyle,
                              display: "flex",
                              flexDirection: "column",
                              ...drivedStyles
                            }
                          : {
                              ...fontStyle,
                              ...drivedStyles
                            }
                      }
                    >
                      {priceLabelValueUi}
                      <span id="swatchMemberPrice">
                        {member &&
                          member.showMemberPrice &&
                          showMemberColumn &&
                          showMemberPrice && (
                            <>
                              {!!memberPriceValue && (
                                <>
                                  <Price
                                    className={
                                      isFabricPricing
                                        ? localClasses.fabricMemberPrice
                                        : isYaml
                                        ? localClasses.yamlPricingMember
                                        : addOnV2Pricing
                                        ? typographyClasses?.addOnPriceRangeLabelAndMember
                                        : typographyClasses?.priceRangeLabelAndMember
                                    }
                                    display="inline"
                                    style={{
                                      ...fontStyle,
                                      ...member.style,
                                      ...drivedStyles
                                    }}
                                    id="memberPrice"
                                    data-testid={`price-for-member`}
                                    {...genericProps}
                                  >
                                    {currency
                                      ? memberPriceValue + " "
                                      : formatCurrencyPrice(memberPriceValue) +
                                        " "}
                                  </Price>{" "}
                                  <PriceLabel
                                    className={
                                      isFabricPricing
                                        ? localClasses.fabricMemberPrice
                                        : isYaml
                                        ? localClasses.yamlPricingMember
                                        : addOnV2Pricing
                                        ? typographyClasses?.addOnPriceRangeLabelAndMember
                                        : typographyClasses?.priceRangeLabelAndMember
                                    }
                                    display="inline"
                                    style={{
                                      ...fontStyle,
                                      ...member.style,
                                      ...drivedStyles
                                    }}
                                  >
                                    {pageContent?.MEMBER || member?.label}
                                  </PriceLabel>
                                </>
                              )}
                            </>
                          )}
                        {contract && (
                          <>
                            {!!memberPriceValue && <RHRPriceSeparator />}
                            <Price
                              className={
                                addOnV2Pricing
                                  ? typographyClasses?.addOnPriceRangeLabelAndMember
                                  : typographyClasses?.priceRangeLabelAndMember
                              }
                              display="inline"
                              style={{ ...fontStyle, ...contract.style }}
                              data-testid={`price-for-contract`}
                              {...genericProps}
                            >
                              {currency
                                ? (contractPrice || "") + " "
                                : formatCurrencyPrice(contractPrice || "") +
                                  " "}
                            </Price>{" "}
                            <PriceLabel
                              className={
                                addOnV2Pricing
                                  ? typographyClasses?.addOnPriceRangeLabelAndMember
                                  : typographyClasses?.priceRangeLabelAndMember
                              }
                              display="inline"
                              style={{ ...fontStyle, ...contract.style }}
                            >
                              {pageContent?.CONTRACT || contract?.label}
                            </PriceLabel>{" "}
                          </>
                        )}
                        {trade && (
                          <>
                            {(!!memberPriceValue || !!contract) && (
                              <RHRPriceSeparator />
                            )}
                            <Price
                              className={
                                isFabricPricing
                                  ? localClasses.fabricRegularPrice
                                  : isYaml
                                  ? localClasses.yamlPricingRegular
                                  : addOnV2Pricing
                                  ? typographyClasses?.addOnPriceRangeLabelAndMember
                                  : typographyClasses?.priceRangeLabelAndMember
                              }
                              display="inline"
                              style={{ ...fontStyle, ...trade.style }}
                              data-testid={`price-for-trade`}
                              {...genericProps}
                            >
                              {currency
                                ? tradePrice + " "
                                : formatCurrencyPrice(tradePrice) + " "}
                            </Price>{" "}
                            <PriceLabel
                              className={
                                addOnV2Pricing
                                  ? typographyClasses?.addOnPriceRangeLabelAndMember
                                  : typographyClasses?.priceRangeLabelAndMember
                              }
                              display="inline"
                              style={{
                                ...fontStyle,
                                ...trade.style,
                                ...drivedStyles
                              }}
                            >
                              {pageContent?.TRADE || trade?.label}
                            </PriceLabel>{" "}
                          </>
                        )}
                      </span>
                      <span
                        id="regularPriceContainer"
                        style={
                          isNewPDPButton
                            ? { display: "flex", gap: "2px" }
                            : undefined
                        }
                      >
                        {!isSwatchPanelOpen && <RHRPriceSeparator />}
                        <Price
                          className={
                            isFabricPricing
                              ? localClasses.fabricRegularPrice
                              : isYaml
                              ? localClasses.yamlPricingRegular
                              : typographyClasses?.priceRangeRegular
                          }
                          display="inline"
                          style={{
                            ...fontStyle,
                            ...drivedStyles,
                            ...(screenSm ? { fontSize: 13 } : {})
                          }}
                          id="regularPrice"
                          data-testid={`price-for-regular`}
                          {...genericProps}
                        >
                          {currency
                            ? regularPriceValue + " "
                            : formatCurrencyPrice(regularPriceValue) + " "}
                        </Price>{" "}
                        <PriceLabel
                          className={
                            isFabricPricing
                              ? localClasses.fabricRegularPrice
                              : isYaml
                              ? localClasses.yamlPricingRegular
                              : typographyClasses?.priceRangeRegular
                          }
                          display="inline"
                          style={{
                            ...fontStyle,
                            ...drivedStyles,
                            ...(screenSm ? { fontSize: 13 } : {})
                          }}
                        >
                          {pageContent.REGULAR || regular?.label}
                        </PriceLabel>{" "}
                      </span>
                    </Grid>
                  )}
                </>
              )}
              {!hideUpSellSale && (
                <Grid item xs={12}>
                  {/* view select item on sale and below pricing  */}
                  {/* we should not suppress sale pricing in pdp : SR-765 */}
                  {priceMessage &&
                    nextGenDrivenOnSale &&
                    (!params.sale || isProductPage) && (
                      <>
                        <Grid
                          container
                          className={localClasses.container}
                          alignItems="baseline"
                          style={{
                            justifyContent: centerAlignFlag ? "center" : "left",
                            textAlign: centerAlignFlag ? "center" : "left",
                            ...(isNewPDPLayout && { marginBottom: "12px" })
                          }}
                        >
                          <Grid item>
                            <ButtonBase
                              onClick={onViewSelectItemsOnSaleClick}
                              disabled={!onViewSelectItemsOnSaleClick}
                              className={typographyClasses.priceViewSaleLinkBtn}
                            >
                              <Typography
                                className={classNames(
                                  typographyClasses.rhBaseBody3,
                                  !onViewSelectItemsOnSaleClick
                                    ? typographyClasses.priceViewSale
                                    : typographyClasses.priceViewSaleLink
                                )}
                                style={{
                                  paddingTop: "10px",
                                  fontSize: 13,
                                  lineHeight: isSSRToggledWithCSR ? "2rem" : ""
                                }}
                                data-testid={
                                  isNewPDPLayout
                                    ? "price-view-for-new-pdplayout"
                                    : ""
                                }
                              >
                                {!onViewSelectItemsOnSaleClick
                                  ? pageContent?.SELECT_ITEM_ON_SALE
                                  : pageContent?.VIEW_ITEM_ON_SALE}
                              </Typography>
                            </ButtonBase>
                          </Grid>
                        </Grid>
                        {!isCustomProduct && (
                          <>
                            {!metaProductComponent && (
                              <Grid
                                container
                                className={localClasses.container}
                                alignItems="baseline"
                                style={{
                                  justifyContent: centerAlignFlag
                                    ? "center"
                                    : "left",
                                  textAlign: centerAlignFlag ? "center" : "left"
                                }}
                              >
                                {!isCartPage && (
                                  <Grid item>
                                    <Typography
                                      className={classNames({
                                        [typographyClasses.priceRangeSaleLabelAndMember]:
                                          !isNewPDPLayout,
                                        [typographyClasses.priceRangeSmall]:
                                          variant === "small" && !mdUp,
                                        newPDPViewSalePriceLabel: isNewPDPLayout
                                      })}
                                      style={
                                        isSSRToggledWithCSR
                                          ? {
                                              lineHeight: "2rem",

                                              ...fontStyle
                                            }
                                          : {
                                              textTransform: "none", // stop the capitalization
                                              color: "#CA2022",
                                              ...fontStyle
                                            }
                                      }
                                    >
                                      {topLabel}&nbsp;
                                    </Typography>
                                  </Grid>
                                )}

                                <Grid item>
                                  {lowestSkuMemberPrice?.amount &&
                                    showMemberPrice && (
                                      <>
                                        <Price
                                          className={classNames(
                                            typographyClasses.priceRangeSaleLabelAndMember,
                                            {
                                              [typographyClasses?.priceRangeSale]:
                                                true,
                                              newPDPViewSalePriceMember:
                                                isNewPDPLayout
                                            }
                                          )}
                                          display="inline"
                                          style={{
                                            ...fontStyle,
                                            ...member?.style
                                          }}
                                          data-testid={`price-for-member`}
                                          {...genericProps}
                                        >
                                          {currency
                                            ? lowestSkuMemberPrice?.amount
                                            : formatCurrencyPrice(
                                                lowestSkuMemberPrice?.amount
                                              )}
                                        </Price>{" "}
                                        <PriceLabel
                                          className={classNames(
                                            memberClassNames,
                                            {
                                              [typographyClasses?.priceRangeSale]:
                                                true,
                                              newPDPViewSalePriceMember:
                                                isNewPDPLayout
                                            }
                                          )}
                                          display="inline"
                                          style={{
                                            ...fontStyle,
                                            ...member?.style
                                          }}
                                        >
                                          {pageContent?.MEMBER || member?.label}
                                        </PriceLabel>
                                      </>
                                    )}
                                  {contract && (
                                    <>
                                      <Price
                                        className={classNames(
                                          memberClassNames,
                                          {
                                            [typographyClasses?.priceRangeSale]:
                                              finalSale || sale
                                          }
                                        )}
                                        display="inline"
                                        style={{
                                          ...fontStyle,
                                          ...contract.style
                                        }}
                                        data-testid={`price-for-contract`}
                                        {...genericProps}
                                      >
                                        {currency
                                          ? lowestSkuMemberPrice?.amount
                                          : formatCurrencyPrice(
                                              lowestSkuMemberPrice?.amount
                                            )}
                                      </Price>{" "}
                                      <PriceLabel
                                        className={classNames(
                                          memberClassNames,
                                          {
                                            [typographyClasses?.priceRangeSale]:
                                              finalSale || sale
                                          }
                                        )}
                                        display="inline"
                                        style={{
                                          ...fontStyle,
                                          ...contract.style
                                        }}
                                      >
                                        {pageContent?.CONTRACT ||
                                          contract?.label}
                                      </PriceLabel>
                                    </>
                                  )}
                                  {trade && (
                                    <>
                                      <Price
                                        className={classNames(
                                          memberClassNames,
                                          {
                                            [typographyClasses?.priceRangeSale]:
                                              true
                                          }
                                        )}
                                        display="inline"
                                        style={{
                                          ...fontStyle,
                                          ...trade.style
                                        }}
                                        data-testid={`price-for-trade`}
                                        {...genericProps}
                                      >
                                        {currency
                                          ? lowestSkuMemberPrice?.amount
                                          : formatCurrencyPrice(
                                              lowestSkuMemberPrice?.amount
                                            )}
                                      </Price>{" "}
                                      <PriceLabel
                                        className={classNames(
                                          memberClassNames,
                                          {
                                            [typographyClasses?.priceRangeSale]:
                                              true
                                          }
                                        )}
                                        display="inline"
                                        style={{
                                          ...fontStyle,
                                          ...trade.style
                                        }}
                                      >
                                        {pageContent?.TRADE || trade?.label}
                                      </PriceLabel>
                                    </>
                                  )}

                                  {lowestSkuSalePrice &&
                                    lowestSkuSalePrice?.amount && (
                                      <>
                                        <RHRSalePriceSeparator />
                                        <Price
                                          className={classNames(
                                            memberClassNames,
                                            typographyClasses.priceRangeSaleRegular,
                                            {
                                              newPDPViewSalePriceRegular:
                                                isNewPDPLayout
                                            }
                                          )}
                                          display="inline"
                                          style={{
                                            ...fontStyle
                                          }}
                                          data-testid={`price-for-trade`}
                                          {...genericProps}
                                        >
                                          {currency
                                            ? lowestSkuSalePrice?.amount
                                            : formatCurrencyPrice(
                                                lowestSkuSalePrice?.amount
                                              )}
                                        </Price>{" "}
                                        <PriceLabel
                                          variant={"caption"}
                                          display="inline"
                                          className={classNames(
                                            {
                                              [typographyClasses.priceRangeCaptionSmall]:
                                                variant === "small",
                                              newPDPViewSalePriceRegular:
                                                isNewPDPLayout
                                            },
                                            typographyClasses.priceRangeSaleRegular
                                          )}
                                          style={fontStyle}
                                        >
                                          {pageContent?.REGULAR ||
                                            regular?.label}
                                        </PriceLabel>
                                      </>
                                    )}
                                </Grid>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    )}
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export const RHPriceRangeDisplay: FC<RHPriceRangeDisplayProps> = ({
  data,
  variant,
  topLabel,
  display = "block",
  currency,
  showSaleMessage = false,
  emptyProduct,
  onViewSelectItemsOnSaleClick,
  inStockPgPrice,
  isStockedFilterActive,
  isMetaProduct,
  isSkuLevel,
  centerAlignFlag,
  nextGenDrivenOnSale,
  metaProductComponent,
  skuOnSalePercentage,
  lowestPgSkuMemberPrice,
  lowestPgSkuSalePrice,
  saleMemberPrice,
  isCustomProduct,
  productId,
  drivedStyles = {},
  isSaleFilterEnabled,
  viewPriceOnly,
  showSalePriceRange,
  isSwatchPanelOpen,
  hideSalePrice,
  configureLabel,
  hideUpSellSale = false,
  color,
  isNewPDPLayout,
  isNewPDPButton,
  showGiftCardPrice,
  addOnV2Pricing,
  onSale,
  isYaml,
  showMembershipProductPrice,
  isFabricPricing
}) => {
  const cookies = useIsoCookies();
  const selectedAccount = cookies["selected_customer"];
  const { userType = selectedAccount } = useRhUserAtomValue();
  const { pageContent } = usePageContent();

  const isActiveSale = isSaleFilterEnabled || false;

  const range = useMemo((): PriceRange => {
    return convertProductPriceRangeDisplayToPriceRange(
      data,
      userType,
      isActiveSale,
      onSale
    );
  }, [data, userType]);

  if (
    range.lowestSkuSalePrice?.amount &&
    range.lowestSkuSalePrice.amount < range.regular.amount
  ) {
    metaProductComponent = false;
  }

  //--- commenting for testing new sale price---/

  // if (isActiveSale && siteId === RHUK_HOST) {
  //   isSkuLevel = true;
  // }

  //-----------------------------------------/

  const getCurrencyLabel = useCallback(() => {
    const latestCookie = parseUserContextCookie(getCookie("userContext"));
    return latestCookie?.currencyCode === "CAD"
      ? ""
      : data?.currencyLabel ?? "";
  }, [data, parseUserContextCookie, getCookie]);

  const STARTING_AT_PRICE = pageContent?.["STARTING_AT"];
  return display === "block" ? (
    <UniversalPriceRangeDisplay
      topLabel={
        topLabel === "no-label"
          ? ""
          : configureLabel?.length
          ? configureLabel
          : pageContent?.STARTING_AT ??
            pageContent?.authPages?.STARTING_AT ??
            topLabel ??
            (data?.overridePriceLabel ||
              (data?.hasOnlyOneSku ? "" : STARTING_AT_PRICE))
      }
      range={range}
      currencyLabel={
        userType === "CONTRACT" ? getCurrencyLabel() : data?.currencyLabel ?? ""
      }
      currencySymbol={data?.currencySymbol!}
      currency={currency}
      variant={variant}
      color={color}
      priceMessage={data?.priceMessage!}
      salePriceLabel={data?.salePriceLabel!}
      saleMemberPrice={saleMemberPrice}
      isNewPDPLayout={isNewPDPLayout}
    />
  ) : (
    <>
      <RHRUniversalPriceRangeDisplay
        isNewPDPButton={isNewPDPButton}
        configureLabel={configureLabel}
        topLabel={
          topLabel ??
          pageContent?.STARTING_AT ??
          pageContent?.authPages?.STARTING_AT ??
          (data?.overridePriceLabel || (data?.hasOnlyOneSku && ""))
        }
        range={range}
        currencyLabel={
          userType === "CONTRACT"
            ? getCurrencyLabel()
            : data?.currencyLabel ?? ""
        }
        currencySymbol={data?.currencySymbol!}
        currency={currency}
        variant={variant}
        color={color}
        priceMessage={data?.priceMessage!}
        showSaleMessage={showSaleMessage}
        salePriceLabel={data?.salePriceLabel!}
        emptyProduct={emptyProduct}
        priceMessagePrice={
          data?.priceMessage
            ? data?.priceMessagePrice ?? data?.salePrices?.[0]!
            : data?.priceMessagePrice!
        }
        onViewSelectItemsOnSaleClick={onViewSelectItemsOnSaleClick}
        saleMemberPrice={saleMemberPrice}
        metaProductComponent={metaProductComponent}
        nextGenDrivenOnSale={nextGenDrivenOnSale}
        skuOnSalePercentage={skuOnSalePercentage}
        isStockedFilterActive={isStockedFilterActive}
        inStockPgPrice={inStockPgPrice}
        isMetaProduct={isMetaProduct}
        isSkuLevel={isSkuLevel}
        centerAlignFlag={centerAlignFlag}
        lowestPgSkuMemberPrice={lowestPgSkuMemberPrice}
        lowestPgSkuSalePrice={lowestPgSkuSalePrice}
        isCustomProduct={isCustomProduct}
        productId={productId}
        drivedStyles={drivedStyles}
        viewPriceOnly={viewPriceOnly}
        showSalePriceRange={showSalePriceRange}
        isSwatchPanelOpen={isSwatchPanelOpen}
        hideSalePrice={hideSalePrice}
        hideUpSellSale={hideUpSellSale}
        isNewPDPLayout={isNewPDPLayout}
        showGiftCardPrice={showGiftCardPrice}
        showMembershipProductPrice={showMembershipProductPrice}
        addOnV2Pricing={addOnV2Pricing}
        isYaml={isYaml}
        isFabricPricing={isFabricPricing}
      />
    </>
  );
};

RHPriceRangeDisplay.defaultProps = {
  variant: "default"
};

export default memoize(RHPriceRangeDisplay);
