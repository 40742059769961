import classnames from "classnames";
import { usePageContent } from "customProviders/LocationProvider";
import { useEnv } from "hooks/useEnv";
import { useIsoCookies } from "hooks/useIsoCookies";
import useTypographyStyles from "hooks/useTypographyStyles";
import React, { FC } from "react";
import {
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from "utils/material-ui-core";
import yn from "yn";
import { PriceLabelOnPDP, RHRPriceSeparator } from "./components";
import {
  keyArr,
  contractTradeTypes,
  ContractType,
  TradeType
} from "./constants";
import { getPriceAmount } from "./helpers";
import { ProductSkuPriceInfoProps } from "./types";
import { useRhUserAtomValue } from "hooks/atoms";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spanTextDecorationThickness: {
      textDecorationThickness: "from-font"
    },
    spanTextDecoration: {
      textDecoration: "line-through"
    }
  })
);

const ProductSkuPriceInfoComponent: FC<ProductSkuPriceInfoProps> = ({
  data,
  salePriceLabel,
  overrideConfiguredPriceLabel,
  rhrPdp,
  isRegistryDialog,
  propsPageContent = null,
  ...rest
}) => {
  const env = useEnv();
  const { userType } = useRhUserAtomValue();
  const { pageContent: defaultPageContent } = usePageContent();
  const isContractTrade = contractTradeTypes.includes(userType || "");
  const FEATURE_PDP = useIsoCookies(["FEATURE_PDP"]).FEATURE_PDP;
  const isPDP = yn(env.FEATURE_PDP) || yn(FEATURE_PDP);
  const classes = useTypographyStyles({
    keys: keyArr
  });

  const pageContent =
    !!propsPageContent && propsPageContent !== null
      ? propsPageContent
      : defaultPageContent;

  const localClasses = useStyles();

  return (
    <>
      {isPDP ? (
        <PriceLabelOnPDP
          data={data}
          rhrPdp={rhrPdp}
          classes={classes}
          pageContent={pageContent}
          isSalePrice={data?.salePrice}
          isTrade={userType === TradeType}
          isContract={userType === ContractType}
          salePriceLabel={salePriceLabel}
          isMemberPrice={!isContractTrade && data?.showMemberPrice}
        />
      ) : (
        <>
          <Typography className={classnames([classes.priceRange])}>
            {overrideConfiguredPriceLabel || pageContent?.CONFIGURES_PRICE}
          </Typography>
          <Typography
            {...rest}
            id={"component-product-sku-price-info"}
            className={classnames(
              classes.priceRange,
              rest.className,
              classes.rhBaseBody1
            )}
          >
            {!data ? null : (
              <Grid container spacing={1}>
                <Grid item spacing={0}>
                  {!isContractTrade && data.showMemberPrice && (
                    <Typography display="inline">
                      <span
                        className={classnames({
                          [classes.skuListColumn2]: true,
                          [classes.priceRangeBold]: true,
                          "item-price__amount--member": true,
                          [classes.priceRangeSale2]: data.onSale,
                          [classes.priceRangeMember2]: true
                        })}
                      >
                        {getPriceAmount("memberPrice", data)}{" "}
                        {pageContent?.MEMBER}{" "}
                      </span>
                    </Typography>
                  )}
                  {isRegistryDialog ? (
                    <Grid container direction="column">
                      {userType === TradeType && (
                        <Typography display="inline">
                          <span
                            className={classnames({
                              [classes.skuListColumn2]: true,
                              [classes.priceRangeSale]: data.onSale,
                              [classes.priceRangeBold]: true,
                              "item-price__amount--trade": true,
                              [classes.priceRangeMember2]: true
                            })}
                          >
                            {getPriceAmount("tradePrice", data)}
                            &nbsp;
                            {pageContent?.trade}
                          </span>
                        </Typography>
                      )}
                      {data.salePrice && (
                        <>
                          <Typography display="inline">
                            <span
                              className={classnames([
                                classes.priceRangeBold,
                                classes.priceRangeSale2,
                                classes.skuListColumn2
                              ])}
                            >
                              {getPriceAmount("salePrice", data)}{" "}
                              {salePriceLabel && pageContent?.SALE}{" "}
                            </span>
                          </Typography>
                        </>
                      )}
                      <Typography display="inline">
                        <span
                          className={`${classnames([
                            classes.priceRangeRegular,
                            classes.skuListColumn2,
                            "item-price__amount"
                          ])} ${localClasses.spanTextDecorationThickness} ${
                            data.onSale && localClasses.spanTextDecoration
                          }`}
                        >
                          {getPriceAmount("listPrice", data)}{" "}
                          {pageContent?.REGULAR}{" "}
                          {/*{ getPriceAmount("salePrice", data)}{" "}*/}
                          {/*{salePriceLabel && pageContent?.SALE}{" "}*/}
                        </span>
                      </Typography>
                      {userType === ContractType && (
                        <Grid item>
                          <span
                            className={classnames({
                              [classes.priceRangeSale]: data.onSale,
                              [classes.priceRangeBold]: true,
                              "item-price__amount--contract": true
                            })}
                          >
                            {getPriceAmount("contractPrice", data)}
                          </span>
                        </Grid>
                      )}
                      <Grid item spacing={0}>
                        {userType === ContractType && (
                          <Grid item>
                            <span
                              className={classnames({
                                [classes.priceRangeSale]: data.onSale,
                                [classes.priceRangeBold]: true,
                                "item-price__amount--contract": true
                              })}
                            >
                              {pageContent?.contract}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {userType === TradeType && (
                        <Typography display="inline">
                          <span
                            className={classnames({
                              [classes.skuListColumn2]: true,
                              [classes.priceRangeSale]: data.onSale,
                              [classes.priceRangeBold]: true,
                              "item-price__amount--trade": true,
                              [classes.priceRangeMember2]: true
                            })}
                          >
                            {getPriceAmount("tradePrice", data)}
                            &nbsp;
                            {pageContent?.TRADE}
                          </span>
                        </Typography>
                      )}
                      <RHRPriceSeparator data={data} />{" "}
                      {data.salePrice && (
                        <>
                          <Typography display="inline">
                            <span
                              className={classnames([
                                classes.priceRangeBold,
                                classes.priceRangeSale2,
                                classes.skuListColumn2
                              ])}
                            >
                              {getPriceAmount("salePrice", data)}{" "}
                              {salePriceLabel && pageContent?.SALE}{" "}
                            </span>
                          </Typography>
                          <RHRPriceSeparator data={data} />{" "}
                        </>
                      )}
                      <Typography display="inline">
                        <span
                          className={`${classnames([
                            classes.priceRangeRegular,
                            classes.skuListColumn2,
                            "item-price__amount"
                          ])} ${localClasses.spanTextDecorationThickness} ${
                            data.onSale && localClasses.spanTextDecoration
                          }`}
                        >
                          {getPriceAmount("listPrice", data)}{" "}
                          {pageContent?.REGULAR}{" "}
                        </span>
                      </Typography>
                      {userType === ContractType && (
                        <Grid item>
                          <span
                            className={classnames({
                              [classes.priceRangeSale]: data.onSale,
                              [classes.priceRangeBold]: true,
                              "item-price__amount--contract": true
                            })}
                          >
                            {getPriceAmount("contractPrice", data)}
                          </span>
                        </Grid>
                      )}
                      <Grid item spacing={0}>
                        {userType === ContractType && (
                          <Grid item>
                            <span
                              className={classnames({
                                [classes.priceRangeSale]: data.onSale,
                                [classes.priceRangeBold]: true,
                                "item-price__amount--contract": true
                              })}
                            >
                              {pageContent?.contract}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                  {/* // redundant code */}
                  {/* {!salePriceLabel ? (
                    <Typography style={{ display: "inline" }}>
                      <span
                        className={classnames([
                          classes.priceRangeRegular,
                          classes.skuListColumn2,
                          "item-price__amount"
                        ])}
                        style={{
                          textDecoration: data.onSale ? "line-through" : "",
                          textDecorationThickness: "from-font"
                        }}
                      >
                        {data?.currency
                          ? formatCurrency(data?.listPrice, data?.currency)
                          : `${data?.currencySymbol}${formatCurrencyWithoutCS(
                              data?.listPrice
                            )}`}{" "}
                        {pageContent.REGULAR}{" "}
                      </span>
                    </Typography>
                  ) : (
                    <></>
                  )} */}
                  {/* {userType === "TRADE" && (
                    <Grid item>
                      <span
                        className={classnames({
                          [classes.priceRangeSale]: data.onSale,
                          [classes.priceRangeBold]: true,
                          "item-price__amount--trade": true
                        })}
                      >
                        {data?.currency
                          ? formatCurrency(data?.tradePrice, data?.currency)
                          : `${data?.currencySymbol}${formatCurrencyWithoutCS(
                              data?.tradePrice
                            )}`}
                      </span>
                    </Grid>
                  )} */}
                  {userType === "CONTRACT" && (
                    <Grid item>
                      <span
                        className={classnames({
                          [classes.priceRangeSale]: data.onSale,
                          [classes.priceRangeBold]: true,
                          "item-price__amount--contract": true
                        })}
                      >
                        {getPriceAmount("contractPrice", data)}
                      </span>
                    </Grid>
                  )}
                </Grid>
                <Grid item spacing={0}>
                  {/* {userType === "TRADE" && (
                    <Grid item>
                      <span
                        className={classnames({
                          [classes.priceRangeSale]: data.onSale,
                          [classes.priceRangeBold]: true,
                          "item-price__amount--trade": true
                        })}
                      >
                        {pageContent?.trade}
                      </span>
                    </Grid>
                  )} */}
                  {userType === "CONTRACT" && (
                    <Grid item>
                      <span
                        className={classnames({
                          [classes.priceRangeSale]: data.onSale,
                          [classes.priceRangeBold]: true,
                          "item-price__amount--contract": true
                        })}
                      >
                        {pageContent?.contract}
                      </span>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Typography>
        </>
      )}
    </>
  );
};

export default ProductSkuPriceInfoComponent;
