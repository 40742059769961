import React, { useState } from "react";
import { RHLink } from "component-rh-link";
import Prop65Dialog from "dialog-prop65";
import { useQuery } from "@apollo/client";
import { queryProp65Fragment } from "graphql-client/queries/pdp-warning";

const Prop65 = ({ warning }) => {
  const [open, setOpen] = useState(false);
  const { data: { contentFragment } = {} as Query } = useQuery<Query>(
    queryProp65Fragment,
    {
      variables: {
        key: warning?.dataKey || "pdp-warning-text",
        group: warning?.dataGroup
      }
    }
  );

  const toggleDialog = () => {
    setOpen(!open);
  };

  return (
    <>
      <RHLink onClick={toggleDialog} underline="always" id={"component-prop65"}>
        Warning: California Prop 65
      </RHLink>
      <Prop65Dialog
        content={contentFragment?.text ?? ""}
        open={open}
        onClose={toggleDialog}
      />
    </>
  );
};

export default Prop65;
