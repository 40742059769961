import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback
} from "react";
// import { Fade } from "@mui/material";

import classNames from "classnames";

import RHPagination from "component-pagination";
import ItemsPerPage from "component-product-grid/ItemsPerPage";

import RHDivider from "components/RHDivider";

import { usePageContent } from "customProviders/LocationProvider";
import { useImageSize } from "graphql-client/contexts/ImageSizeContext";

import { useEnv } from "hooks/useEnv";
import { useFetchModel } from "hooks/useFetchModel";

import useMediaString from "hooks/useMediaString";
import useParams from "hooks/useParams";

import {
  processEnvServer as isServer,
  processEnvServer
} from "hooks/useSsrHooks";
import _chunk from "lodash/chunk";

import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { RHRProductCardSkeleton } from "skeleton-rhr-product-list";
import {
  DEFAULT_GRID_COLUMNS,
  DEFAULT_VIEW,
  IMAGE_ASPECT_RATIO,
  INITIAL_PG_IMAGE_CONTAINER_DIMENSION,
  ITEMS_PER_PAGE_PREFERENCE,
  PG_IMAGE_CONTAINER_DIMENSION,
  ZERO_RESULTS
} from "utils/constants";

import prasePGCropRules from "utils/prasePGCropRules";

import yn from "yn";

import { PC } from "./ProductCard";

function extractSkuOtions(option: string) {
  const facets = option?.split("|")?.reduce((acc, part) => {
    const [id, type, value] = part?.split("~~");
    acc[type?.toLowerCase()] = value;
    return acc;
  }, {});

  return facets;
}

export const getUrl = (
  item: SearchResultRecord | undefined,
  host: string = "",
  stocked: boolean,
  isRefinementFilterActive: boolean,
  totalNumRecs: number,
  isSale: boolean,
  isConcierge?: boolean,
  filterQueries?: string[],
  selectedSwatch?: string | null,
  isNextGen = false,
  inStockFlow = true
) => {
  const saleParamString = isSale ? "true" : "";
  let url = new URL(
    `/catalog/product/product.jsp`,
    host || "http://www.example.com"
  );
  url.pathname += `/${item?.product?.repositoryId}`;
  {
    isSale && url.searchParams.set("sale", saleParamString);
  }

  if (item?.product?.skuOptiondata?.length && isNextGen && !stocked) {
    const skuOptiondata = extractSkuOtions(item?.product?.skuOptiondata);
    Object.keys(skuOptiondata)?.map(key => {
      if (skuOptiondata[key] && key) {
        url.searchParams?.set(key, skuOptiondata[key]);
      }
    });
  }

  // SR-1909 : PDP-Page URL has sku details appended to product ID
  // if (
  //   item?.product?.repositoryId !== item?.sku?.fullSkuId &&
  //   (stocked || isRefinementFilterActive || totalNumRecs === 1) &&
  //   !isNextGen
  // ) {
  //   const skuOptiondataToObj = item?.product?.skuOptiondata
  //     ?.split(",")
  //     .reduce((acc, pair) => {
  //       const [key, value] = pair.split(":").map(item => item.trim());
  //       acc[key] = value;
  //       return acc;
  //     }, {});
  //   let color = skuOptiondataToObj?.["Color"] ?? null;
  //   totalNumRecs === 1 && !stocked
  //     ? url.searchParams.set("categoryId", `search`)
  //     : (url.searchParams.set("color", `${color}`),
  //       url.searchParams.set("categoryId", "search"));
  // }

  if (
    item?.product?.newPdpLayout &&
    // @ts-ignore
    !(!isConcierge && item?.product?.conciergeOnlyForNewPDP)
  ) {
    url.searchParams.set("version", "v2");
  }

  if (selectedSwatch) {
    url.searchParams.set("swatch", selectedSwatch);
  }
  url.searchParams.set("layout", `${item?.product?.pdpImageLayout}`);

  if (stocked) {
    if (inStockFlow) {
      url.searchParams.set("fullSkuId", item?.sku?.fullSkuId ?? "");
    } else {
      url.searchParams.set("inStock", "true");
    }
  }

  return {
    to: !host ? url.pathname + url.search : undefined
  };
};

export const getPriceUserType = (userType: string, price) => {
  switch (userType) {
    case "CONTRACT":
      return price?.contractPrice;
    case "TRADE":
      return price?.tradePrice;
    default:
      return price?.memberPrice;
  }
};

interface ProductGrid {
  isStockedFilterActive: boolean;
  isRefinementFilterActive: boolean;
  gridColumns: any;
  view: string;
  totalNumRecs: number;
  loadMoreData: () => any;
  productList: any[];
  noLazy?: boolean;
  host?: string;
  brand?: string;
  isSort?: boolean;
  productClickHandler?: (sipId: string) => void;
  selectedProductId?: string;
  disableFadeEffect?: boolean;
  isSale?: boolean;
  isSaleFilterEnabled?: boolean;
  productTitle?: string;
  pgCropRulesFromCg?: string;
  infiniteScrollEnabled?: boolean;
  isNextPageLoading?: boolean;
  recsPerPage: number;
  firstRecNum?: number;
  lastRecNum?: number;
  filterQueries?: string[];
  nextgenDriven?: boolean;
  inStockFlow?: boolean;
}
const ProductGrid: FC<ProductGrid> = ({
  gridColumns,
  isStockedFilterActive,
  isRefinementFilterActive,
  totalNumRecs,
  productList,
  loadMoreData,
  recsPerPage,
  noLazy = false,
  brand,
  isSort,
  productClickHandler,
  selectedProductId,
  isSale,
  isSaleFilterEnabled,
  productTitle,
  view,
  infiniteScrollEnabled = true,
  isNextPageLoading = false,
  filterQueries,
  inStockFlow
}) => {
  const env = useEnv();

  const FEATURE_INFINITE_SCROLL_REFACTOR = yn(
    env.FEATURE_INFINITE_SCROLL_REFACTOR
  );

  const isPaginationFeatureEnabled = yn(env.FEATURE_PAGINATION);
  const isPaginationTuningFeatureEnabled = yn(env.FEATURE_PAGINATION_TUNING);
  const isRHRImageFeatureEnabled = yn(env.FEATURE_RHR_IMAGE);

  const { generateGridMap } = useImageSize();
  const [skeletonHeight, setSkeletonHeight] = useState(0);
  const [productGridColumns, setProductGridColumns] = useState<number>(4);
  const selectedProductRef = useRef<HTMLDivElement | null>(null);
  const skeletonRef = useRef<HTMLDivElement | null>(null);
  const loaderRef = useRef<HTMLDivElement | null>(null);

  const { pathname } = useLocation();
  const isAemPage = !pathname?.includes(".jsp");
  const { pageContent } = !isAemPage
    ? usePageContent()
    : useFetchModel("/admin/products", false, false);
  const ItemsPerPageOptions = JSON.parse(
    pageContent?.items_per_page_options || "[]"
  );

  useEffect(() => {
    const currentSelectedProductRef = selectedProductRef?.current;
    if (!currentSelectedProductRef) return;
    setTimeout(() => {
      currentSelectedProductRef.scrollIntoView({
        behavior: "auto",
        block: "center"
      });
    }, 250);
  }, []);

  useEffect(() => {
    if (infiniteScrollEnabled) return;
    window?.scrollTo(0, 0);
  }, [infiniteScrollEnabled]);

  const derivedProductList = useMemo(
    () =>
      noLazy ? productList?.slice(0, (12 / gridColumns) * 2) : productList,
    [noLazy, productList, gridColumns]
  );

  useEffect(() => {
    if (isRHRImageFeatureEnabled) {
      const sections: any = [];
      let counter = 0;
      derivedProductList?.forEach((item, i) => {
        if (i === 0) {
          sections[counter] = [i];
        }

        if (
          i > 0 &&
          item?.product?.anchor &&
          derivedProductList[i - 1]?.product?.anchor !==
            item?.product?.anchor &&
          !isSort
        ) {
          sections[counter].push(i - 1);
          counter++;
          sections[counter] = [i];
        }

        if (i === derivedProductList?.length - 1) {
          sections[counter].push(i);
        }
      });

      generateGridMap(sections, 12 / gridColumns);
    }

    setTimeout(
      () => {
        if (productGridColumns !== gridColumns) {
          setProductGridColumns(gridColumns);
        }
      },
      isRHRImageFeatureEnabled ? 500 : 0
    );
  }, [derivedProductList, gridColumns]);

  useEffect(() => {
    const newSkeletonHeight = skeletonRef?.current?.clientHeight || 40;
    if (skeletonHeight === newSkeletonHeight) return;
    setSkeletonHeight(newSkeletonHeight);
  }, [gridColumns]);

  const derivedProductListGroupedByAnchor = useMemo(
    () =>
      derivedProductList?.reduce(
        (acc = [[]], rec) => {
          const pgCropRules = prasePGCropRules(rec?.product?.pgCropRules);

          const record = {
            ...rec,
            product: { ...rec?.product, pgCropRules }
          };

          const arrayLastIndex = acc?.length - 1;
          const innerArrayLastIndex = acc?.[arrayLastIndex]?.length - 1;

          const arrayRecord = acc?.[arrayLastIndex];
          const innerArrayRecord = arrayRecord?.[innerArrayLastIndex];

          if (record?.product?.anchor) {
            const isTitleEqualToAnchor =
              productTitle?.toLowerCase() !==
              record?.product?.anchor?.toLowerCase();
            const isPrevAnchorNotEqualToNextAnchor =
              innerArrayRecord?.product?.anchor !== record?.product?.anchor;

            if (
              isTitleEqualToAnchor &&
              isPrevAnchorNotEqualToNextAnchor &&
              !isSort
            ) {
              acc?.push([record]);
            } else {
              arrayRecord?.push(record);
            }
          } else {
            if (Array.isArray(arrayRecord)) {
              arrayRecord?.push(record);
            } else {
              acc?.push([record]);
            }
          }

          return acc;
        },
        [[]]
      ),
    [derivedProductList, isSort, productTitle]
  );

  const mediaString = useMediaString();

  const parsedDerivedProductList = useMemo(() => {
    return derivedProductListGroupedByAnchor?.flatMap(derivedProductList =>
      _chunk(derivedProductList, 12 / gridColumns)?.flatMap(
        (groupedDerivedProduct: any) => {
          const imgContainerHeight = groupedDerivedProduct?.map(
            derivedProduct => derivedProduct?.product?.pgCropRules?.height
          );
          const maxImageContainerHeight = Math?.max(...imgContainerHeight);

          const [MAX_IMG_CONTAINER_HEIGHT] =
            PG_IMAGE_CONTAINER_DIMENSION?.[gridColumns]?.[mediaString] ??
            INITIAL_PG_IMAGE_CONTAINER_DIMENSION;

          const imageContainerHeight = `${
            (maxImageContainerHeight / 100) * MAX_IMG_CONTAINER_HEIGHT
          }px`;

          const aspectRatio =
            view === "vertical"
              ? IMAGE_ASPECT_RATIO?.verticalProductTile
              : IMAGE_ASPECT_RATIO?.horizontalProductTile;

          return groupedDerivedProduct?.map(derivedProduct => {
            const imgHeight = `${
              (derivedProduct?.product?.pgCropRules?.height / 100) *
              MAX_IMG_CONTAINER_HEIGHT
            }px`;

            return {
              ...derivedProduct,
              product: {
                ...derivedProduct?.product,
                imageStyle: {
                  objectFit: "fill",
                  alignSelf: "flex-end",
                  maxWidth: "100%",
                  maxHeight: derivedProduct?.product?.rhr ? imgHeight : "100%",
                  width: "auto",
                  height: "auto",
                  transitionProperty: "opacity"
                },
                imageContainerStyle: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  aspectRatio: derivedProduct?.product?.rhr
                    ? null
                    : aspectRatio?.toString(),
                  height: derivedProduct?.product?.rhr
                    ? imageContainerHeight
                    : undefined
                }
              }
            };
          });
        }
      )
    );
  }, [derivedProductListGroupedByAnchor, gridColumns, mediaString, view]);

  const innerGrid = useMemo(() => {
    return (
      <div
        className={classNames(
          `grid gap-x-4 sm:gap-x-8 md:gap-x-10 gap-y-8 sm:gap-y-9 md:gap-y-12 lg:gap-y-[60px] mb-8 md:mb-9 lg:mb-[60px] !items-end`,
          {
            "grid-cols-3": gridColumns === 4,
            "grid-cols-2": gridColumns === 6,
            "grid-cols-1": gridColumns === 12
          }
        )}
      >
        {parsedDerivedProductList?.map((item, index) => (
          <>
            {productTitle?.toLowerCase() !==
            item?.product?.anchor?.toLowerCase() ? (
              <>
                {item?.product?.anchor &&
                  parsedDerivedProductList[index - 1]?.product?.anchor !==
                    item?.product?.anchor &&
                  !isSort && (
                    <div className="col-span-full">
                      {index > 0 && <RHDivider className="!mb-[60px]" />}{" "}
                      <div className="uppercase font-primary-ultra-thin !text-[24px]">
                        {item?.product?.anchor}
                      </div>
                    </div>
                  )}
              </>
            ) : null}
            <div
              key={`innerGrid_item_${index}`}
              id={`${brand}__${item?.sku?.fullSkuId}__${index}`}
              className={
                !processEnvServer
                  ? "flex justify-center productVisible mb-3"
                  : ""
              }
              ref={
                `${item?.sku?.fullSkuId}__${index}` === selectedProductId
                  ? selectedProductRef
                  : null
              }
            >
              <PC
                item={item}
                isSale={isSale}
                isSaleFilterEnabled={isSaleFilterEnabled}
                totalNumRecs={totalNumRecs}
                isStockedFilterActive={isStockedFilterActive}
                isRefinementFilterActive={isRefinementFilterActive}
                gridColumns={gridColumns}
                filterQueries={filterQueries}
                pageContent={pageContent}
                productTitle={productTitle}
                onProductClick={() => {
                  productClickHandler?.(`${item?.sku?.fullSkuId}__${index}`);
                }}
                inStockFlow={inStockFlow}
              />
            </div>
          </>
        ))}
      </div>
    );
  }, [parsedDerivedProductList]);

  const skeletonUi = useMemo(
    () =>
      Array.from(new Array(12 / gridColumns)).map((item, index) => (
        <RHRProductCardSkeleton key={`${index}`} />
      )),
    [gridColumns]
  );

  const storedItemsPerPagePreference = !isServer
    ? localStorage.getItem(ITEMS_PER_PAGE_PREFERENCE)
    : undefined;

  const params = useParams({
    no: "0",
    maxnrpp:
      storedItemsPerPagePreference ??
      (isPaginationTuningFeatureEnabled ? "24" : "48"),
    loadAll: ""
  });

  const handleObserver = useCallback(
    entries => {
      const target = entries[0];
      if (target.isIntersecting && productList.length < totalNumRecs) {
        loadMoreData();
      }
    },
    [loadMoreData, productList.length, totalNumRecs]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "50px",
      threshold: 0.5
    });

    if (loaderRef.current) observer.observe(loaderRef.current);

    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, [handleObserver]);

  const parsedDerivedProductListWithLoader = useMemo(() => {
    if (!parsedDerivedProductList) return [];
    const listWithLoader = [...parsedDerivedProductList];
    const position = listWithLoader.length - 12;
    if (position >= 0 && listWithLoader[position]) {
      listWithLoader[position].loader = true;
    }
    return listWithLoader;
  }, [parsedDerivedProductList]);

  return (
    <div
      id="component-product-grid"
      className="relative"
      style={{
        ...(infiniteScrollEnabled || isNextPageLoading
          ? {
              paddingBottom:
                productList?.length < totalNumRecs ? skeletonHeight : 0
            }
          : {})
      }}
    >
      {infiniteScrollEnabled ? (
        <div>
          {FEATURE_INFINITE_SCROLL_REFACTOR ? (
            <div
              className={classNames(
                `grid gap-x-8 md:gap-x-10 gap-y-8 sm:gap-y-9 md:gap-y-12 lg:gap-y-[60px] mb-8 md:mb-9 lg:mb-[60px] ![overflow:unset] !items-end`,
                {
                  "grid-cols-3": gridColumns === 4,
                  "grid-cols-2": gridColumns === 6,
                  "grid-cols-1": gridColumns === 12
                }
              )}
            >
              {parsedDerivedProductListWithLoader?.map((item, index) => {
                return (
                  <>
                    {productTitle?.toLowerCase() !==
                    item?.product?.anchor?.toLowerCase() ? (
                      <>
                        {item?.product?.anchor &&
                          parsedDerivedProductList[index - 1]?.product
                            ?.anchor !== item?.product?.anchor &&
                          !isSort && (
                            <div className="col-span-full">
                              {index > 0 && (
                                <RHDivider className="!mb-[60px]" />
                              )}{" "}
                              <div className="uppercase font-primary-ultra-thin !text-[24px]">
                                {item?.product?.anchor}
                              </div>
                            </div>
                          )}
                      </>
                    ) : null}
                    <div
                      key={`innerGrid_item_${index}`}
                      id={`${brand}__${item?.sku?.fullSkuId}__${index}`}
                      className="flex justify-center productVisible mb-3"
                      ref={
                        `${item?.sku?.fullSkuId}__${index}` ===
                        selectedProductId
                          ? selectedProductRef
                          : null
                      }
                    >
                      <PC
                        item={item}
                        isSale={isSale}
                        isSaleFilterEnabled={isSaleFilterEnabled}
                        totalNumRecs={totalNumRecs}
                        isStockedFilterActive={isStockedFilterActive}
                        isRefinementFilterActive={isRefinementFilterActive}
                        gridColumns={productGridColumns}
                        filterQueries={filterQueries}
                        pageContent={pageContent}
                        productTitle={productTitle}
                        onProductClick={() => {
                          productClickHandler?.(
                            `${item?.sku?.fullSkuId}__${index}`
                          );
                        }}
                        inStockFlow={inStockFlow}
                      />
                      {item.loader && <div ref={loaderRef} key="loader"></div>}
                    </div>
                  </>
                );
              })}
            </div>
          ) : (
            <InfiniteScroll
              dataLength={productList?.length}
              next={noLazy ? () => {} : loadMoreData}
              hasMore={noLazy ? false : productList?.length < totalNumRecs}
              loader={skeletonUi}
              scrollThreshold={0.5}
              className={classNames(
                `grid gap-x-8 md:gap-x-10 gap-y-8 sm:gap-y-9 md:gap-y-12 lg:gap-y-[60px] mb-8 md:mb-9 lg:mb-[60px] ![overflow:unset] !items-end`,
                {
                  "grid-cols-3": gridColumns === 4,
                  "grid-cols-2": gridColumns === 6,
                  "grid-cols-1": gridColumns === 12
                }
              )}
            >
              {parsedDerivedProductList?.map((item, index) => {
                return (
                  <>
                    {productTitle?.toLowerCase() !==
                    item?.product?.anchor?.toLowerCase() ? (
                      <>
                        {item?.product?.anchor &&
                          parsedDerivedProductList[index - 1]?.product
                            ?.anchor !== item?.product?.anchor &&
                          !isSort && (
                            <div className="col-span-full">
                              {index > 0 && (
                                <RHDivider className="!mb-[60px]" />
                              )}{" "}
                              <div className="uppercase font-primary-ultra-thin !text-[24px]">
                                {item?.product?.anchor}
                              </div>
                            </div>
                          )}
                      </>
                    ) : null}
                    <div
                      key={`innerGrid_item_${index}`}
                      id={`${brand}__${item?.sku?.fullSkuId}__${index}`}
                      className="flex justify-center productVisible mb-3"
                      ref={
                        `${item?.sku?.fullSkuId}__${index}` ===
                        selectedProductId
                          ? selectedProductRef
                          : null
                      }
                    >
                      <PC
                        item={item}
                        isSale={isSale}
                        isSaleFilterEnabled={isSaleFilterEnabled}
                        totalNumRecs={totalNumRecs}
                        isStockedFilterActive={isStockedFilterActive}
                        isRefinementFilterActive={isRefinementFilterActive}
                        gridColumns={productGridColumns}
                        filterQueries={filterQueries}
                        pageContent={pageContent}
                        productTitle={productTitle}
                        onProductClick={() => {
                          productClickHandler?.(
                            `${item?.sku?.fullSkuId}__${index}`
                          );
                        }}
                        inStockFlow={inStockFlow}
                      />
                    </div>
                  </>
                );
              })}
            </InfiniteScroll>
          )}

          {FEATURE_INFINITE_SCROLL_REFACTOR && isNextPageLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "60px"
              }}
            >
              {skeletonUi}
            </div>
          )}
        </div>
      ) : (
        <>{innerGrid}</>
      )}
      {!isNextPageLoading && isPaginationFeatureEnabled ? (
        <>
          {ItemsPerPageOptions.includes(+Number(params.maxnrpp)) &&
            params?.loadAll !== "true" && (
              <RHPagination
                recsPerPage={recsPerPage}
                lastRecNum={productList?.length - 1}
                totalNumRecs={totalNumRecs}
                loadMoreData={loadMoreData}
              />
            )}
          <ItemsPerPage
            recsPerPage={recsPerPage}
            lastRecNum={productList?.length - 1}
            totalNumRecs={totalNumRecs}
            loadMoreData={loadMoreData}
          />
        </>
      ) : null}
    </div>
  );
};

ProductGrid.defaultProps = {
  productList: [],
  isStockedFilterActive: false,
  isRefinementFilterActive: false,
  gridColumns: DEFAULT_GRID_COLUMNS,
  view: DEFAULT_VIEW,
  totalNumRecs: ZERO_RESULTS
};

export default ProductGrid;
