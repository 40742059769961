import React, { FC } from "react";
import { useTheme, makeStyles, createStyles, Theme } from "@material-ui/core";
import BrandLink, { BrandLinkProps } from "component-brand-link";
import { Brand } from "types";
import memoize from "utils/memoize";
import { getGlobal } from "utils-globals/getGlobal";

const env = getGlobal("_env");

const PUBLIC_URL = env.PUBLIC_URL || "";

const logoWidthsLarge = {
  RH: 40,
  MO: 115,
  BC: 175,
  TN: 105,
  SH: 135,
  BH: 175,
  OD: 150,
  IN: 150
};

const logoMarginLeft: { [key: string]: string } = {
  OD: "-5px"
};

const useStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      img: {
        display: "block",
        boxSizing: "initial",
        width: logoWidthsLarge[props.brand as Brand],
        height: 20,
        marginLeft: logoMarginLeft[props.brand as Brand] ?? 0
      }
    })
  );

export interface RHLogoProps extends BrandLinkProps {
  primary: boolean;
  customColor?: string;
  imgStyle?: React.CSSProperties;
  logoDirName?: string;
}

export const RHLogo: FC<RHLogoProps> = ({
  primary,
  customColor,
  logoDirName,
  imgStyle,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles({
    primary,
    customColor,
    ...rest
  })();
  const color = customColor
    ? customColor
    : primary && theme.palette.type === "light"
    ? "White"
    : "Black";

  const imgPublicUrl = PUBLIC_URL.replace(new RegExp(/\/?$/gm), "");
  return (
    <BrandLink
      id="nav-logo-img"
      underline="none"
      data-rh-logo-brand-link="true"
      {...rest}
    >
      <img
        className={classes.img}
        src={`${imgPublicUrl}/img/${logoDirName || "logos"}/${
          rest.brand
        }_${color}.svg`}
        alt={rest.brand}
        style={imgStyle}
      />
    </BrandLink>
  );
};

export default memoize(RHLogo);
