import { Theme, useMediaQuery } from "@material-ui/core";
import { usePinch } from "@use-gesture/react";
import { useEnv } from "hooks/useEnv";
import { useImgUrl } from "hooks/useImageUrl";
import { processEnvServer } from "hooks/useSsrHooks";
import React, { FC, useCallback, useMemo, useState } from "react";
import "react-magic-slider-dots/dist/magic-dots.css";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import yn from "yn";
import { BigScreenPGPImageCard, SmallScreenPGPImageCard } from "./components";
import { getPresetMap } from "./helper";
import { CardImagesProps } from "./types";
import { useParams2 } from "hooks/useParams";
import { useAppId } from "hooks/useAppId";

/**
 * why bindPinch as any ?
 * R:\ Typescript: this expression is not callable. Type 'void' has no call signatures
 * https://github.com/pmndrs/use-gesture/issues/362
 */

const CardImages: FC<CardImagesProps> = ({
  gridColumns,
  images = [],
  imageFlip,
  isRHR,
  productDetails,
  productUrl,
  swatchHandlerProps
}) => {
  const env = useEnv();
  const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
  const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));
  const { params } = useParams2(
    {
      version: ""
    },
    { toLowerCase: true }
  );
  const [zoomedImages, setZoomedImages] = useState(new Set());
  const [errorOnImageURL, setErrorOnImageURL] = useState<boolean>(false);
  const [imageUrlsWithError, setImageUrlsWithError] = useState<Set<string>>(
    new Set([""])
  );

  const isFlipping: boolean = yn(env?.FEATURE_PG_CAROUSEL) || false;

  const { isConcierge } = useAppId();
  const isRHRImage = yn(env?.FEATURE_RHR_IMAGE) && isRHR;
  const isConciergeRHRImage =
    isConcierge && yn(env?.FEATURE_RHR_IMAGE) && isRHR;
  const PRESET_MAP = getPresetMap(isConciergeRHRImage, isRHRImage);

  const normalizedImages = useMemo(() => {
    return [
      ...new Set(images?.map(imageUrl => imageUrl?.replace(/\$.*\$/, "")))
    ];
  }, [images]);

  const bindPinch = usePinch(
    ({ args: [imgUrl, zoomedImages, setZoomedImages] }) => {
      if (imgUrl && !zoomedImages?.has(imgUrl)) {
        setZoomedImages(new Set(zoomedImages?.add(imgUrl)));
      }
    }
  ) as any;

  const cols = 12 / gridColumns;

  const presetImage = useCallback(
    (rawURL: string | undefined, zoom?) => {
      try {
        if (!rawURL) return "";
        let preset = "";
        if (zoom) {
          preset = rawURL?.toUpperCase()?.includes("_RHR")
            ? PRESET_MAP.rhrThreeK
            : PRESET_MAP.threeK;
        } else if (xlUp) {
          preset = PRESET_MAP.xlUp[`col-${cols}`];
        } else if (lgUp) {
          preset = PRESET_MAP.lgUp[`col-${cols}`];
        } else if (mdUp) {
          preset = PRESET_MAP.mdUp[`col-${cols}`];
        } else if (smUp) {
          preset = PRESET_MAP.smUp[`col-${cols}`];
        } else {
          preset = PRESET_MAP.xsUp[`col-${cols}`];
        }

        const url = new URL(`https:${rawURL}`);
        if (url?.search) {
          return `${rawURL}&${preset}`;
        } else {
          return `${rawURL}?${preset}`;
        }
      } catch (error) {
        return rawURL;
      }
    },
    [
      xlUp,
      lgUp,
      mdUp,
      smUp,
      PRESET_MAP.rhrThreeK,
      PRESET_MAP.threeK,
      PRESET_MAP.xlUp,
      PRESET_MAP.lgUp,
      PRESET_MAP.mdUp,
      PRESET_MAP.smUp,
      PRESET_MAP.xsUp,
      cols
    ]
  );

  /* flag off */
  const [imageUrl, ...altImages] = normalizedImages;
  const { handleImageLoadErr } = useImgUrl(
    imageUrl,
    altImages,
    params?.version
  );
  const isZoomedMainImage = zoomedImages.has(imageUrl);
  const loadingbehaviorMainImage = "eager";
  const disabledShapeMainImage = isZoomedMainImage ? true : false;
  const fullMainImageURL = presetImage(imageUrl, isZoomedMainImage);

  const isZoomedAltImage = zoomedImages.has(altImages?.[0]);
  const fullAltImageURL = presetImage(altImages?.[0], isZoomedAltImage);

  const isSSRToggled = isSSRToggledWithClientRender();

  const onErrorImageURL = useCallback(
    (_, urlValue: string) => {
      setErrorOnImageURL(true);
      const key = new URL(urlValue).origin.toString();

      setImageUrlsWithError(prevImageUrls => {
        const newImageUrls = new Set(prevImageUrls);
        newImageUrls.add(key);
        return newImageUrls;
      });
      handleImageLoadErr();
    },
    [handleImageLoadErr]
  );

  const bigScreenComponent = useMemo(
    () => (
      <BigScreenPGPImageCard
        showCarousel={isFlipping}
        normalizedImages={normalizedImages}
        zoomedImages={zoomedImages}
        presetImage={presetImage}
        productUrl={productUrl}
        productDetails={productDetails}
        bindPinch={bindPinch}
        setZoomedImages={setZoomedImages}
        noCarouselProps={{
          isSSRToggled,
          errorOnImageURL,
          disabledShapeMainImage,
          loadingbehaviorMainImage,
          fullMainImageURL,
          fullAltImageURL,
          imageFlip,
          onErrorImageURL,
          imageUrlsWithError,
          imageUrl,
          swatchHandlerProps
        }}
      />
    ),
    [
      bindPinch,
      disabledShapeMainImage,
      errorOnImageURL,
      fullAltImageURL,
      fullMainImageURL,
      imageFlip,
      imageUrl,
      imageUrlsWithError,
      isFlipping,
      isSSRToggled,
      normalizedImages,
      onErrorImageURL,
      presetImage,
      productDetails,
      productUrl,
      swatchHandlerProps,
      zoomedImages
    ]
  );

  return (
    <div
      style={{
        ...productDetails?.imageContainerStyle,
        height: isFlipping
          ? "auto"
          : productDetails?.imageContainerStyle?.height,
        justifyContent: "flex-end"
      }}
    >
      {processEnvServer ? (
        bigScreenComponent
      ) : (
        <>
          {bigScreenComponent}
          <SmallScreenPGPImageCard
            showCarousel={isFlipping}
            allowImageFlip={imageFlip}
            normalizedImages={normalizedImages}
            presetImage={presetImage}
            productDetails={productDetails}
            bindPinch={bindPinch}
            productUrl={productUrl}
            noCarouselProps={{
              zoomedImages,
              setZoomedImages,
              fullMainImageURL,
              onErrorImageURL,
              imageUrlsWithError,
              setImageUrlsWithError,
              disabledShapeMainImage,
              loadingbehaviorMainImage,
              imageUrl,
              swatchHandlerProps
            }}
          />
        </>
      )}
    </div>
  );
};

export default CardImages;
