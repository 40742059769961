import React, { FC, useMemo } from "react";
import {
  GridList,
  GridListTile,
  GridListProps,
  makeStyles,
  createStyles,
  Typography,
  Theme
} from "@material-ui/core";
import classNames from "classnames";
import { useQuery } from "@apollo/client";
import { PromoProps } from "component-promo";
import useAppData from "hooks/useAppData";
import ProductCard from "component-product-card";
import { queryProducts } from "graphql-client/queries/products";
import { cssStringToObj } from "utils/cssStringToObj";
import useDidMountEffect from "hooks/useDidMountEffect";
import RHDivider from "component-rh-divider";
import {
  IMAGE_ASPECT_RATIO,
  INITIAL_PG_IMAGE_CONTAINER_DIMENSION,
  PG_IMAGE_CONTAINER_DIMENSION
} from "utils/constants";
import getCountryFromUrl from "utils/getCountryFromUrl";
import useTypographyStyles from "hooks/useTypographyStyles";
import { PAGE_BG_GREY } from "utils/constants";
import getCurrencyCode from "utils/getCurrencyCode";
import useLocale from "hooks-use-locale/useLocale";
import useMeasurementType from "hooks/useMeasurementType";
import useSite from "hooks/useSite";
import useMediaString from "hooks/useMediaString";
import _chunk from "lodash/chunk";
import prasePGCropRules from "utils/prasePGCropRules";
import { useRhUserAtomValue } from "hooks/atoms";

interface PromoProductGridProps extends PromoProps {
  GridListProps?: GridListProps;
  gridColumns?: any;
}

interface ProductSubItemStart {
  subItemTitle: string;
}

export const PromoProductGrid: FC<PromoProductGridProps> = ({
  GridListProps,
  data,
  gridColumns
}) => {
  const {
    subItems,
    products: productsByCatId,
    dynamicText: { productIds, gridSize, style, categoryId } = {}
  } = data;
  const productIdList = productIds ? productIds.split(",") : [];
  const { userType } = useRhUserAtomValue();
  const {
    app: { postalCode }
  } = useAppData();

  const urlCountry = getCountryFromUrl();
  const siteId = useSite();
  const locale = useLocale();
  const currencyCode = getCurrencyCode({
    country: urlCountry,
    postalCode: postalCode,
    userType
  });
  const measureSystem = useMeasurementType();
  const { data: { products } = {} as Query, refetch: refetchProducts } =
    useQuery<Query>(queryProducts, {
      variables: {
        productIds: productIdList,
        userType,
        siteId,
        measureSystem,
        locale,
        currencyCode
      },
      fetchPolicy: "no-cache",
      skip: !productIdList?.length || !!categoryId
    });

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseH3"]
  });
  useDidMountEffect(() => {
    !productsByCatId && refetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        margin: theme.spacing(2, 0),
        backgroundColor: PAGE_BG_GREY,
        ...cssStringToObj(style || "")
      },
      subTitle: {
        textAlign: "center",
        marginBottom: theme.spacing(2)
      },
      divider: {
        width: "100%"
      },
      reveal: {
        opacity: 0
      },

      fadeInUp: {
        animationDuration: "0s",
        animationTimingFunction: "easeIn",
        animationDelay: "0s",
        animationIterationCount: 1,
        animationDirection: "normal",
        animationFillMode: "none",
        animationPlayState: "running",
        animationName: "fadeInUp"
      }
    })
  )();

  const displaySingleProducts = () =>
    products?.filter(product => product?.isActive) as Product[];

  const displayCategoryProducts = (
    promoDisplay: PromoDisplay,
    totalProducts: any[] = []
  ) => {
    if (!promoDisplay.subItems) {
      return [
        ...totalProducts,
        ...(promoDisplay?.products?.productGallery || [])
      ];
    }

    for (let item of promoDisplay.subItems) {
      let currentProducts = [
        ...totalProducts,
        ...(item?.title ? [{ subItemTitle: item?.title }] : []),
        ...(item?.products?.productGallery || [])
      ];
      totalProducts = displayCategoryProducts(item, currentProducts) as any[];
    }

    return totalProducts;
  };

  const displayProducts = (
    productsByCatId
      ? displayCategoryProducts(data, productsByCatId?.productGallery)
      : displaySingleProducts()
  ) as any[];

  const mediaString = useMediaString();

  const parsedDerivedProductList = useMemo(() => {
    return [displayProducts]?.flatMap(derivedProductList =>
      _chunk(derivedProductList, 12 / gridColumns)?.flatMap(
        (groupedDerivedProduct: any) => {
          const imgContainerHeight = groupedDerivedProduct?.map(
            derivedProduct =>
              prasePGCropRules(derivedProduct?.pgCropRules)?.height
          );

          const [MAX_IMG_CONTAINER_HEIGHT] =
            PG_IMAGE_CONTAINER_DIMENSION?.[gridColumns]?.[mediaString] ??
            INITIAL_PG_IMAGE_CONTAINER_DIMENSION;

          return groupedDerivedProduct?.map(derivedProduct => {
            const imgHeight = `${
              (prasePGCropRules(derivedProduct?.pgCropRules)?.height / 100) *
              MAX_IMG_CONTAINER_HEIGHT
            }px`;
            return {
              ...derivedProduct,
              imageStyle: {
                objectFit: "fill",
                alignSelf: "flex-end",
                maxWidth: "100%",
                maxHeight: derivedProduct?.rhr ? imgHeight : "auto",
                width: "auto",
                height: "auto",
                transitionProperty: "opacity"
              },
              imageContainerStyle: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                aspectRatio: derivedProduct?.rhr
                  ? null
                  : IMAGE_ASPECT_RATIO["verticalProductTile"]?.toString(),
                height: MAX_IMG_CONTAINER_HEIGHT
              },
              isShopByRoom: true
            };
          });
        }
      )
    );
  }, [displayProducts, mediaString, gridColumns]);

  if (!parsedDerivedProductList || !parsedDerivedProductList.length) {
    return null;
  }

  return (
    <GridList
      cols={gridSize || 2}
      cellHeight="auto"
      spacing={10}
      {...GridListProps}
      className={classNames(classes.root, GridListProps?.className)}
    >
      {parsedDerivedProductList?.map(
        (
          product: Product | CategoryProduct | ProductSubItemStart,
          index: number
        ) =>
          "subItemTitle" in product ? (
            <GridListTile
              key="`subitems-${product.subItemTitle}`"
              cols={gridSize || 2}
            >
              <RHDivider
                style={{ backgroundColor: "white", marginBottom: "60px" }}
              />
              <Typography
                style={{ color: "white" }}
                className={classNames([
                  "promo-product-grid__subtitle",
                  classes.subTitle,
                  typographyStyles.rhBaseH3
                ])}
              >
                {product.subItemTitle}
              </Typography>
            </GridListTile>
          ) : (
            <GridListTile key={`product-${product.id}-${index}`}>
              <div
                key={`reveal-${index}`}
                className={classNames([classes.reveal, classes.fadeInUp])}
                style={{
                  animationDuration: "500ms",
                  animationDelay: "0ms",
                  animationIterationCount: 1,
                  opacity: 1
                }}
              >
                <ProductCard
                  data={product}
                  objectFit={"contain"}
                  showPriceRange={!product?.customProduct}
                />
              </div>
            </GridListTile>
          )
      )}
    </GridList>
  );
};

PromoProductGrid.defaultProps = {};

export default PromoProductGrid;
