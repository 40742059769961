import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography
} from "utils/material-ui-core";
import RHTextField from "component-rh-text-field";
import React, { FC, useState } from "react";

import classNames from "classnames";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import { handleKeyboardPressWithEvent } from "utils/accessibility";

export interface InnerMenuProps {
  handler: (id: number) => void;
  selectedId: number;
  items: Array<{ title: string; id: number }>;
}

const useStyles = ({ country }: { country: string }) => {
  const isNA = !checkCountryIsEu({ country });

  return makeStyles((theme: Theme) =>
    createStyles({
      textCenter: { textAlign: "center" },
      menu: {
        display: "flex",
        justifyContent: isNA ? "space-between" : "flex-start",
        borderTop: "1px solid #e6e6e6",
        borderBottom: "1px solid #e6e6e6",
        boxSizing: "border-box",
        padding: "7px 0"
      },
      menuItem: {
        margin: `${theme.typography.pxToRem(5)} ${
          isNA ? "0" : theme.typography.pxToRem(20)
        } ${theme.typography.pxToRem(5)} 0`,
        letterSpacing: theme.typography.pxToRem(0.5),
        textTransform: "uppercase",
        cursor: "pointer"
      },
      menuItemSelected: {
        color: theme.palette.common.black,
        fontWeight: "bold"
      },
      mobileMenu: {
        width: "100%"
      }
    })
  );
};

const InnerMenu: FC<InnerMenuProps> = ({ items, selectedId, handler }) => {
  const country = getCountryFromUrl();
  const classes = useStyles({ country })();

  const [selectedMenu, setSelectedMenu] = useState(selectedId);

  const firstId = items[0].id;
  const lastId = items[items.length - 1].id;

  const handleMenuChange = (id: number) => {
    handler(id);
    setSelectedMenu(id);
  };

  return (
    <Grid
      item
      xs={12}
      className={classNames(classes.textCenter)}
      id={"component-inner-menu"}
    >
      <Hidden smDown>
        <Grid className={classNames(classes.menu)}>
          {items?.map(item => (
            <Typography
              className={classNames([
                classes.menuItem,
                item.id === selectedMenu && classes.menuItemSelected
              ])}
              key={`menu-${item.id}`}
              onClick={() => {
                handleMenuChange(item.id);
              }}
              tabIndex={0}
              onKeyDown={handleKeyboardPressWithEvent(() =>
                handleMenuChange(item.id)
              )}
            >
              {item.title}
            </Typography>
          ))}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <RHTextField
          value={selectedMenu}
          className={classNames(classes.mobileMenu)}
          select
          onChange={event => handleMenuChange(parseInt(event.target.value))}
        >
          {items?.map(item => (
            <option key={`menu-${item.title}`} value={item.id}>
              {item.title}
            </option>
          ))}
        </RHTextField>
      </Hidden>
    </Grid>
  );
};

export default InnerMenu;
